
import { UserRole } from '../../../shared/models/user-roles.model';
import { UserInformation } from '../../../shared/models/user-informations.model';
import { ProjetCompletDto } from '../../../core/api/client/models';
import { StatutProjet } from '../models/statut-projet.enum';

export function getAssigneProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canAssigneProjetStatutList: string[] =
        [
            StatutProjet.aqAssigne,
            StatutProjet.aqEnCours,
            StatutProjet.aqCompletee,
            StatutProjet.inspectionAssigne,
            StatutProjet.inspectionEnCours,
            StatutProjet.inspectionCompletee,
            StatutProjet.nouveau
        ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
        case UserRole.ADMIN_TIC:
        case UserRole.ADMIN_GCSP:
        case UserRole.PILOTE:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme
                && projet.creePar?.toLowerCase() === currentUserInfos.courriel!.toLowerCase();
        default:
            return false;
    }
}

export function getAnnuleProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canAnnuleProjetStatutList: string[] =
        [
            StatutProjet.inspectionAssigne,
            StatutProjet.nouveau,
            StatutProjet.enCreation,
            StatutProjet.erreur
        ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return canAnnuleProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAnnuleProjetStatutList.includes(projet.statut!) &&
                projet.creePar?.toLowerCase() === currentUserInfos.courriel!.toLowerCase();
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return canAnnuleProjetStatutList.includes(projet.statut!);
        default:
            return false;
    }
}

export function getModifierProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return projet.creePar?.toLowerCase() === currentUserInfos.courriel!.toLowerCase();
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return true;
        default:
            return false;
    }
}

export function getValiderProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canValiderProjetStatutList: string[] = [StatutProjet.inspectionCompletee, StatutProjet.aqCompletee, StatutProjet.correctionCompletee];
    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canValiderProjetStatutList.includes(projet.statut!)
            && projet.firme === currentUserInfos.firme;
    } else {
        return canValiderProjetStatutList.includes(projet.statut!);
    }
}

export function getApprouveProjetPermission(projet: ProjetCompletDto): boolean {
    const canApprouveProjetStatutList: string[] = [StatutProjet.attenteApprobationHQ, StatutProjet.conforme, StatutProjet.audite];
    return canApprouveProjetStatutList.includes(projet.statut);
}

export function getRejeterProjetPermission(projet: ProjetCompletDto): boolean {
    const canRejeterProjetStatutList: string[] = [StatutProjet.attenteApprobationHQ, StatutProjet.audite];
    return canRejeterProjetStatutList.includes(projet.statut);
}

export function getEnvoyerAvisPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canEnvoyerAvisStatutList: string[] =
        [
            StatutProjet.inspectionAssigne,
            StatutProjet.inspectionEnCours,
            StatutProjet.inspectionCompletee,
            StatutProjet.aqAssigne,
            StatutProjet.aqEnCours,
            StatutProjet.aqCompletee
        ];
    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canEnvoyerAvisStatutList.includes(projet.statut!)
            && projet.firme === currentUserInfos.firme;
    } else {
        return projet.statut !== StatutProjet.approbationFinaleHQ;
    }
}

export function getCreateProjetAuditPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjet.attenteApprobationHQ;
}

export function getAssigneProjetAQPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjet.inspectionCompletee ||
        projet.statut === StatutProjet.aqAssigne ||
        projet.statut === StatutProjet.aqEnCours ||
        projet.statut === StatutProjet.aqCompletee;
}

export function getCompleterProjetPermission(projet: ProjetCompletDto): boolean {
    const validStatus: string[] = [StatutProjet.inspectionEnCours, StatutProjet.correctionEnCours];
    return validStatus.includes(projet.statut);
}

export function getCompleterProjetAQPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjet.aqEnCours;
}

export function getRapportValidationPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjet.inspectionEnCours ||
        projet.statut === StatutProjet.aqEnCours;
}

export function getRapportCreationAvisPermission(projet: ProjetCompletDto): boolean {
    return (projet.resultatTransferAvis || []).length > 0;
}

export function getActiverProjetPermission(
    currentActiveProjet: ProjetCompletDto,
    currentActiveProjetAQ: ProjetCompletDto,
    projet: ProjetCompletDto,
    currentUserRole: UserRole
): boolean {
    const canActiverProjetStatutList: string[] =
        [
            StatutProjet.inspectionAssigne,
            StatutProjet.correctionAssignee
        ];
    if (UserRole.CONTROLEUR_QUALITE_EXTERNE.includes(currentUserRole)) {
        return !currentActiveProjetAQ && projet.statut === StatutProjet.correctionAssignee;
    } else {
        return !currentActiveProjet && canActiverProjetStatutList.includes(projet.statut);
    }
}

export function getActiverProjetPermissionAQ(currentActiveProjetAQ: ProjetCompletDto, projet: ProjetCompletDto): boolean {
    return !currentActiveProjetAQ && (projet.statut === StatutProjet.aqAssigne || currentActiveProjetAQ?.id === projet.id);
}

export function getAssigneFirmeProjetPermission(projet: ProjetCompletDto): boolean {
    return projet.firme === undefined || projet.firme === '';
}

export function getAssigneProjetPourCorrectionPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjet.nonConforme ||
        projet.statut === StatutProjet.correctionAssignee ||
        projet.statut === StatutProjet.correctionEnCours ||
        projet.statut === StatutProjet.correctionCompletee;
}

export function getProjetHistoriquePermission(projet: ProjetCompletDto): boolean {
    return projet.statut !== StatutProjet.erreur;
}
