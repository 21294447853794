export enum StatutProjetsInspection {
    enCreation = 'enCreation',
    nouveau = 'nouveau',
    inspectionAssigne = 'inspectionAssigne',
    inspectionEnCours = 'inspectionEnCours',
    inspectionCompletee = 'inspectionCompletee',
    attenteApprobationHQ = 'attenteApprobationHQ',
    approbationFinaleHQ = 'approbationFinaleHQ',
    pause = 'pause',
    annule = 'annule',
    erreur = 'erreur',
    auditEnCours = 'auditEnCours',
    aqAssigne = 'aqAssigne',
    aqEnCours = 'aqEnCours',
    aqCompletee = 'aqCompletee',
    nonConforme = 'nonConforme',
    correctionAssignee = 'correctionAssignee',
    correctionEnCours = 'correctionEnCours',
    correctionCompletee = 'correctionCompletee',
    conforme = 'conforme',
    audite = 'audite'
}

export enum StatutProjetsInspectionValue {
    enCreation = 'En création',
    nouveau = 'Nouveau',
    inspectionAssigne = 'Inspection assignée',
    inspectionEnCours = 'Inspection en cours',
    inspectionCompletee = 'Inspection complétée',
    attenteApprobationHQ = 'Attente approbation HQ',
    approbationFinaleHQ = 'Approbation finale HQ',
    pause = 'Pause',
    annule = 'Annulé',
    erreur = 'Erreur',
    auditEnCours = 'Audit en cours',
    aqAssigne = 'AQ assigné',
    aqEnCours = 'AQ en cours',
    aqCompletee = 'AQ completée',
    nonConforme = 'Inspection non conforme',
    correctionAssignee = 'Correction assignée',
    correctionEnCours = 'Correction en cours',
    correctionCompletee = 'Correction complétée',
    conforme = 'Inspection conforme',
    audite = 'Audité'
}

export const STATUT_PROJETS_INSPECTION_NAME = new Map<StatutProjetsInspection, string>([
    [StatutProjetsInspection.enCreation, StatutProjetsInspectionValue.enCreation],
    [StatutProjetsInspection.nouveau, StatutProjetsInspectionValue.nouveau],
    [StatutProjetsInspection.inspectionAssigne, StatutProjetsInspectionValue.inspectionAssigne],
    [StatutProjetsInspection.inspectionEnCours, StatutProjetsInspectionValue.inspectionEnCours],
    [StatutProjetsInspection.inspectionCompletee, StatutProjetsInspectionValue.inspectionCompletee],
    [StatutProjetsInspection.attenteApprobationHQ, StatutProjetsInspectionValue.attenteApprobationHQ],
    [StatutProjetsInspection.approbationFinaleHQ, StatutProjetsInspectionValue.approbationFinaleHQ],
    [StatutProjetsInspection.pause, StatutProjetsInspectionValue.pause],
    [StatutProjetsInspection.annule, StatutProjetsInspectionValue.annule],
    [StatutProjetsInspection.erreur, StatutProjetsInspectionValue.erreur],
    [StatutProjetsInspection.auditEnCours, StatutProjetsInspectionValue.auditEnCours],
    [StatutProjetsInspection.aqAssigne, StatutProjetsInspectionValue.aqAssigne],
    [StatutProjetsInspection.aqEnCours, StatutProjetsInspectionValue.aqEnCours],
    [StatutProjetsInspection.aqCompletee, StatutProjetsInspectionValue.aqCompletee],
    [StatutProjetsInspection.nonConforme, StatutProjetsInspectionValue.nonConforme],
    [StatutProjetsInspection.correctionAssignee, StatutProjetsInspectionValue.correctionAssignee],
    [StatutProjetsInspection.correctionEnCours, StatutProjetsInspectionValue.correctionEnCours],
    [StatutProjetsInspection.correctionCompletee, StatutProjetsInspectionValue.correctionCompletee],
    [StatutProjetsInspection.conforme, StatutProjetsInspectionValue.conforme],
    [StatutProjetsInspection.audite, StatutProjetsInspectionValue.audite],
]);
