export enum AnomalieProperties {
    ANOMALIE_ID = 'Id',
    ELEMENT = 'Élément',
    TYPE = 'Type',
    CAUSE = 'Cause',
    INDICATEUR_CREATION_AVIS = `Indicateur de création d'avis`,
    PRIORITE = 'Priorité',
    DATE_TRANSFERT_SAP = 'Date de transfert à SAP',
    REMARQUE = 'Remarque',
    URGENT = 'Urgent',
    PHOTOS = 'photos',
    STATUT = 'statut',
    modifieLe = 'Modifié le',
    modifiePar = 'Modifié par',
}
