
export const mapStyleConfig: { [name: string]: any } = {
    colors: {
        deepAqua: '#008080',
        blanc: '#ffffff',
        indigo: '#3f48cc',
        lineColor: '#31736e',
        lineColorHover: '#FAF205',
        rechercheColor: '#5757ff',
        frostedMint: '#E0FFFF',
        balsamique: 'cyan',
        outlineBalsamique: 'cyan',
        poteauSelected: 'rgba(0, 0, 0, 0.6)',
        nonInspecte: 'rgba(255, 255, 255, 1)',
        brun: 'rgba(185, 122, 86, 1)',
        rouge: 'rgba(236, 28, 36, 1)',
        jaune: 'rgba(255, 242, 0, 1)',
        noir: 'rgba(0, 0, 0, 1)',
        vert: 'rgba(14, 209, 69, 1)',
        orange: 'rgba(255, 127, 39, 1)',
        bleu: 'rgba(140, 255, 251, 1)',
        shadedOfGrey: 'rgb(140, 140, 140)',
        gris: 'rgba(195, 195, 195, 1)',
        ignore: 'rgba(195, 195, 195, 1)',
        urgence: 'black',
        anomaliePrioriteC: 'red',
        anomaliePrioriteE: 'orange',
        anomaliePrioriteG: 'orange',
        anomaliePrioriteK: 'yellow',
        anomaliePrioriteM: 'yellow',
        anomaliePrioriteN: 'yellow',
        sansAnomalie: '#00C851',
        exclu: '#8A2BE2',
        private: '#f5009f',
        vegetation: 'green',
        cyan: 'cyan',
        enCreation: 'rgba(173, 165, 171, 0.2)',
        nouveau: 'rgba(200, 65, 200, 0.2)',
        inspectionAssigne: 'rgba(0, 255, 255, 0.2)',
        inspectionEnCours: 'rgba(255, 255, 0, 0.2)',
        pause: 'rgba(255, 0, 0, 0.2)',
        inspectionCompletee: 'rgba(63, 164, 41, 0.2)',
        aqAssigne: 'rgba(33, 147, 244, 0.2)',
        aqEnCours: 'rgba(236, 204, 27, 0.2)',
        aqCompletee: 'rgba(27, 157, 44, 0.2)',
        attenteApprobationHQ: 'rgba(51, 102, 153, 0.2)', //bleu HQ
        approbationFinaleHQ: 'rgba(51, 102, 153, 0.5)', //bleu HQ
        annule: 'rgba(243, 156, 18, 0.5)',
        erreur: 'rgba(255, 0, 0, 0.2)',
        transparent: 'rgba(0, 0, 0, 0)',
        auditCree: 'rgba(234, 207, 234, 0.5)',
        auditAssigne: 'rgba(194, 245, 245, 0.5)',
        auditEnCours: 'rgba(237, 250, 92, 0.5)',
        auditAnnule: 'rgba(255, 179, 60, 0.5)',
        auditComplete: 'rgba(164, 164, 164, 0.5)',
        auditNonApprouve: 'rgba(252, 66, 66, 0.5)',
        auditApprouve: 'rgba(5, 232, 58, 0.5)',
        nonConforme: 'rgba(252, 66, 66, 0.5)',
        correctionAssignee: 'rgba(148, 202, 253, 0.5)',
        correctionEnCours: 'rgba(238, 221, 126, 0.5)',
        correctionCompletee: 'rgba(138, 209, 149, 0.5)',
        conforme: 'rgba(5, 232, 58, 0.5)',
        audite: 'rgba(158, 189, 225, 0.5)'
    },
    codeABarres: {
        minZoom: 18,
    },
    pointAudit: {
        minZoom: 13,
    },
    poteau: {
        minZoom: 13,
        circleWidhtClick: 6,
        circleStrokeClick: 1,
        circleWidth: 7,
        circleStroke: 1,
        privateCircleWidth: 6,
        privateCircleStroke: 3,
        excluCircleWidth: 5,
    },
    projet: {
        fillOpacity: 0.4
    },
    projetHighlight: {
        opacity: 0.2
    },
    projetOutline: {
        lineWidth: 3,
    },
    projetZoomHighlight: {
        opacity: 0.2,
    },
    projetZoomHighlightOutline: {
        lineWidth: 3,
    },
    ligne: {
        minZoom: 6,
        lineWidth: 4,
        lineWidtHover: 7,
        opacityHover: 0.5,
        opacity: 1,
    },
    rechercheLigne: {
        minZoom: 6,
        lineWidth: 4,
        opacity: 0.9,
    },
    rechercheLclcl: {
        minZoom: 6,
        circleRadius: 16,
        opacity: 0.9,
    },
    rechercheCodeBarres: {
        minZoom: 6,
        circleRadius: 16,
        opacity: 0.9,
    },
};
