import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { ApiKey } from '@esri/arcgis-rest-auth';
import { geocode } from '@esri/arcgis-rest-geocoding';
import {
    FeatureCollection as FeatureCollectionPointAudit,
    FeatureCollection as FeatureCollectionPointInspection,
    Feature as FeatureHelper,
} from '@turf/helpers';
import { bbox, BBox, Geometry } from '@turf/turf';
import { Feature, FeatureCollection } from 'geojson';
import { Guid } from 'guid-typescript';
import { isUndefined } from 'lodash';
import mapboxgl from 'mapbox-gl';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Projet } from '../features/projet/models/projet.model';
import { UiService } from '../services/ui.service';

import { MapboxButtonControl } from './components/mapbox-button-control';
import { GeoJson } from './models/geojson.model';
import { InfoPoint } from './models/info-point.model';
import { MapLayersSources } from './models/map-layers-sources.enum';
import { StyleLayer } from './models/style-layer.model';
import { MapService } from './services/map.service';

import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { StatutPointInspection } from '../features/inspection/models/statut-point-inspection.enum';
import * as AuditActions from '../features/audit/state/audit.actions';
import * as InspectionActions from '../features/inspection/state/inspection.actions';
import * as OfflineActions from '../features/offline/state/offline.actions';
import * as SyncActions from '../features/synchronisation/state/synchronisation.actions';
import * as SharedActions from '../state/shared/shared.actions';
import { IndexedDbService } from '../features/offline/services/indexed-db.service';
import { SynchronisationService } from '../features/synchronisation/services/synchronisation.service';

import { AnomalieDialogComponent } from '../features/anomalie/components/anomalie-dialog/anomalie-dialog.component';
import { AuditComponent } from '../features/audit/audit.component';
import { PilotageDialogComponent } from '../features/pilotage/pilotage-dialog/pilotage-dialog.component';
import { PoteauDetailsComponent } from './components/poteau/poteau-details/poteau-details.component';
import { VersionComponent } from '../version/version.component';
import { getAllTaxonomies } from '../core/store/actions/taxonomie.action';
import { selectUserInformation } from '../core/store/selectors/userInformation.selector';
import { PointInspectionProperties } from '../enums/point-inspection-properties.enum';
import { Severite } from '../enums/severite';
import { PointAuditProperties } from '../features/audit/models/point-audit-properties.enum';
import { StatutPointAudit } from '../features/audit/models/statut-point-audit.enum';
import { PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../core/api/client/models';
import { PoteauDetails } from './models/poteau-details.enum';
import {
    getAddPointAuditSuccess,
    getCompleteProjetAuditSuccess,
    getCreatePointAuditSuccess,
    getCurrentActiveProjetAudit,
    getPointAuditById,
    getUpdatePointAuditSuccess
} from '../features/audit/state/audit.selectors';
import { OfflineService } from '../features/offline/services/offline.service';
import {
    getProjetAddedToIndexedDb,
    getProjetAddedToIndexedDbLoading,
    getProjetAddedToIndexedDbSuccess,
    getProjetAuditAddedToIndexedDb,
    getProjetsAuditDownloaded,
    getProjetsDownloaded
} from '../features/offline/state/offline.selectors';
import { BaseComponent } from '../shared/components/abstract-base-component';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { UserInformation } from '../shared/models/user-informations.model';
import {
    convertNumberToLatitude,
    convertNumberToLongitude,
    generatePointsAuditFeatures,
    generatePointsInspectionFeatures
} from '../shared/utils';
import { getDataExtraction, getDataExtractionError, getIsAppOnline, getStartDataExtraction, getStartDataExtractionError } from '../state/shared/shared.selectors';
import { SelectProjetDialogComponent } from './components/poteau/create-move-poteau-panel/select-projet-dialog/select-projet-dialog.component';
import { InspectionComponent } from '../features/inspection/inspection.component';
import {
    getPointInspectionById,
    getCompleteProjetInspectionSuccess,
    getCreateAnomalieInspectionSuccess,
    getCreatePointInspectionSuccess,
    getCurrentActiveProjetInspection,
    getDeleteAnomalieInspectionSuccess,
    getUpdateAnomalieInspectionSuccess,
    getUpdatePointInspectionSuccess,
    getPointsActiveProjetInspectionSuccess
} from '../features/inspection/state/inspection.selectors';
import { State } from '../state/app.state';
import { SelectProjetAuditDialogComponent } from './components/poteau/create-move-poteau-panel/select-projet-audit-dialog/select-projet-audit-dialog.component';
import { PopUpInfoCloseEvent } from './models/pop-up-info-close-event.model';
import {
    canLoadProjetAuditList,
    getIsAuditeur,
    getIsControleurQualite,
    getIsInspecteur,
    getIsUserMobile
} from '../core/store/selectors/user.selectors';
import { MapPermissionsService } from './services/map-permissions.service';
import { FeatureSource } from './models/feature-source.enum';
import { GpsComponent } from '../shared/gps/gps.component';
import { GpsService } from '../shared/gps/service/gps.service';
import { Coordinates, GpsPortConnectionState, GpsState } from '../shared/gps/models';
import { generateAnomalieAudit } from '../features/audit/utils/audit.utils';
import { NetworkService } from '../services/network.service';
import { StoreName } from '../features/offline/models/indexed-db-store-name.enum';
import { ProjetsComponent } from '../features/projets/projets.component';
import { getCreateProjetInspection } from '../features/projets/state/projets.selectors';
import { LegendComponent } from './components/legend/legend.component';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class MapComponent extends BaseComponent implements OnInit, OnDestroy {
    public topLeftIcon: boolean = false;
    public topLeftDisabled: boolean = false;
    public topRightLabel: string = '';
    public topRightDisabled: boolean = false;
    public bottomLeftIcon: boolean = false;
    public bottomLeftDisabled: boolean = false;
    public bottomRightDisabled: boolean = false;
    public centerDisable: boolean = false;
    public isInitMapFinish: boolean = false;
    public selectedPointInspectionCodeBar: string = '';
    gpsIcon: any;

    public get codeABarres(): string {
        return this.infoPointInspection.feature!.properties![PointInspectionProperties.codeABarres] ?
            this.infoPointInspection.feature!.properties![PointInspectionProperties.codeABarres] :
            'Code à barres inconnu';
    }

    public get map(): mapboxgl.Map {
        this.isInitMapFinish = this.mapService.map !== undefined;
        this.subscribeToGPSStates();
        return this.mapService.map;
    }

    public get requestStackSize(): number {
        return this.indexedDbService.requestStackSize;
    }

    public ouvrirLegende: boolean = false;
    public createPoteauInfo: PointInspectionDto | null;
    public geoCodetext: string;
    public filtreEsriCodage: Partial<GeoJson>;
    public infoPointInspection: InfoPoint = { openSideBar: false, feature: undefined };
    public infoPointAudit: InfoPoint = { openSideBar: false, feature: undefined };
    public selectedPort: SerialPort;

    public ignorePointPanelVisible: boolean = false;
    public createMovePoteauPanelVisible: boolean = false;
    public createPoteauSansAnomalie: boolean = false;
    public pointAuditNonConformeDialogVisible: boolean = false;
    public createPointAuditData: PointAuditDto;
    public currentActiveProjetAudit: ProjetAuditDto | null;
    public currentActiveProjetInspection: ProjetCompletDto | null;
    private currentPointInspection$: Observable<PointInspectionDto>;
    public selectedPointInspection: PointInspectionDto | null;
    public selectedPointAudit: PointAuditDto;
    public selectedPopUpFeature: mapboxgl.MapboxGeoJSONFeature[];
    private legendeLayers$: Observable<StyleLayer[]> = this.mapService.legendeStyles$.asObservable();
    private listeLayersInit: StyleLayer[];
    private geocoderMarker: mapboxgl.Marker;
    private subscriptions: Subscription[] = [];
    private rapideSubscriptions: Subscription[] = [];
    public projetsInspectionDownloaded: ProjetCompletDto[];
    private projetsAuditDownloaded: ProjetAuditDto[];
    private currentImage$: Observable<HTMLImageElement> = this.mapService.currentImage.asObservable();
    private currentImage: HTMLImageElement;
    public statutPointAudit = StatutPointAudit;
    public pointInspectionProperties = PointInspectionProperties;

    private imageIndex$: Observable<number> = this.mapService.imageIndex.asObservable();
    public imageIndex: number;

    private nombresImages$: Observable<number> = this.mapService.nombrePhoto.asObservable();
    public nombresImages: number = 0;

    public isAppOnline: boolean;
    public userGroups: string[] = [];
    public currentUserInfo: UserInformation;
    private isInspecteur$: Observable<boolean> = this.store.select(getIsInspecteur);
    private isInspecteur: boolean = false;
    private isUserMobile$: Observable<boolean> = this.store.select(getIsUserMobile);
    public isUserMobile: boolean = false;
    private isControleurQualite$: Observable<boolean> = this.store.select(getIsControleurQualite);
    private isControleurQualite: boolean = false;
    private isAuditeur$: Observable<boolean> = this.store.select(getIsAuditeur);
    public isAuditeur: boolean = false;
    public markAsAuditeur = false;

    public isAnomalieDisabled: boolean = true;
    public isAnomalieAuditButtonDisabled: boolean = true;
    public isAucuneAnomalieDisabled: boolean = true;
    public isIgnorePointDisabled: boolean = true;
    public isDetailHaveAudit: boolean = false;
    public isAnomalieHaveAudit: boolean = false;
    public isAnomalieHaveNonConformite: boolean = false;
    public isDetailHaveNonConformite: boolean = false;
    public isPoteauDetailsDialogOpened: boolean = false;
    public isPointInspectionHavePointsAudit: boolean = false;
    public isCreatePoteauPointAudit: boolean = false;
    public warningMinimized = false;
    public isInspecteurPanel: boolean = true;
    private isPointAuditSelected: boolean = false;

    private dataExtractionTimer: NodeJS.Timer;
    private counterIntervalForDataExtraction: number = 0;
    private maxIterationForDataExtraction: number = 1;
    private intervalBetweenCallCheck: number = 20000; // 20 sec.

    private marker: mapboxgl.Marker;

    public projetAddedToIndexedDbLoading$: Observable<boolean>;

    public currentGpsPortConnectionState = GpsPortConnectionState.DISCONNECTED;
    public currentGpsState = GpsState.OFFLINE;
    private _coordinates: Coordinates | null = null;
    private pointsInspections$: Observable<PointInspectionDto[]> = this.store.select(getPointsActiveProjetInspectionSuccess);

    @ViewChild('popUpInfo') popupContainer: any;

    get isNetworkOnline() {
        return this.networkService.isOnline;
    }

    constructor(
        public dialogService: DialogService,
        private messageService: MessageService,
        private mapService: MapService,
        public readonly uiService: UiService,
        public ref: DynamicDialogRef,
        private store: Store<State>,
        private readonly indexedDbService: IndexedDbService,
        private readonly syncService: SynchronisationService,
        public readonly offlineService: OfflineService,
        public readonly offlineModeService: OfflineService,
        private mapPermissionsService: MapPermissionsService,
        private el: ElementRef,
        private gpsService: GpsService,
        private networkService: NetworkService,
    ) {
        super();
        this.store.dispatch(getAllTaxonomies());
        this.store.dispatch(SyncActions.getRequests());
        this.store.dispatch(SyncActions.getSyncErrors());

        this.evaluateActionSheet();
    }

    @HostListener('window:beforeunload')
    beforeUnloadHandler() {
        void this.ngOnDestroy();
    }

    ngOnInit(): void {
        this.marker = new mapboxgl.Marker({
            draggable: false
        });

        this.projetAddedToIndexedDbLoading$ = this.store.select(getProjetAddedToIndexedDbLoading);

        this.store.dispatch(InspectionActions.loadProjetInspectionList());
        this.store.dispatch(OfflineActions.getProjetsDownloaded());
        this.store.dispatch(OfflineActions.getProjetsAuditDownloaded());

        this.subscriptions.push(
            this.mapService.mapLoaded$.pipe(
                tap(() => this.init()),
                take(1)
            ).subscribe(),

            this.uiService.mapPopUpClicked$.pipe(
                tap((event) => {
                    if (event) {
                        this.showMapPopUp(event);
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.projetsList$.pipe(
                tap((openClose) => {
                    if (openClose) {
                        this.mapService.closeActionSheet();
                        this.openProjetsListDialog();
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.projetInspectionList$.pipe(
                tap((openClose) => {
                    if (openClose) {
                        this.mapService.closeActionSheet();
                        this.openProjetInspectionListDialog();
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.projetAuditList$.pipe(
                tap((openClose) => {
                    if (openClose) {
                        this.mapService.closeActionSheet();
                        this.openProjetAuditListDialog();
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.projetListRowSelected$.pipe(
                tap((projetListRowSelected) => {
                    if (projetListRowSelected) {
                        this.mapService.closeActionSheet();
                    }
                })
            ).subscribe(),

            this.uiService.version$.pipe(
                tap((openClose) => {
                    if (openClose) {
                        this.openVersionDialog();
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.createPoteau$.pipe(
                tap((createPoteau) => {
                    if (createPoteau) {
                        if (this.isAuditeur) {
                            this.createPoteauPointAudit();
                        } else {
                            this.initCreatePoteau(true);
                        }
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.createPoteauSansAnomalie$.pipe(
                tap((createPoteauSansAnomalie) => {
                    if (createPoteauSansAnomalie) {
                        this.initCreatePoteau(false, true);
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.uiService.isPoteauDetailsDialogOpened$.pipe(
                tap((isOpen: boolean) => {
                    this.isPoteauDetailsDialogOpened = isOpen;
                    this.evaluateActionSheet();
                })
            ).subscribe(),

            this.uiService.pilotage$.pipe(
                tap((openClose) => {
                    if (openClose) {
                        this.openPilotageDialog();
                    } else {
                        this.ref.close();
                    }
                })
            ).subscribe(),

            this.legendeLayers$.subscribe(
                (listeLayers) => {
                    this.listeLayersInit = listeLayers;
                },
            ),

            this.currentImage$.pipe(
                tap((image) => this.currentImage = image)
            ).subscribe(),

            this.nombresImages$.pipe(
                tap((nombreImages) => this.nombresImages = nombreImages)
            ).subscribe(),

            this.imageIndex$.pipe(
                tap((imageIndex) => this.imageIndex = imageIndex)
            ).subscribe()
        );

        this.subscriptions.push(
            this.uiService.pointInspectionInfo$.subscribe((infoInspection: InfoPoint) => {
                this.destroyRapideSubscriptions();
                this.resetSideBarPanel();
                if (infoInspection?.feature && (infoInspection?.feature as any).hasOwnProperty('source')) {
                    if ((infoInspection.feature as any).source === FeatureSource.POINTS_INSPECTION) {
                        if (this.isAuditeur || (this.mapPermissionsService.roleIsMarkAsAuditeur() && this.isPointAuditSelected)) {
                            this.isPointInProjetsAuditDownloaded(infoInspection);
                        } else {
                            this.isPointInProjetsInspectionDownloaded(infoInspection);
                        }
                    }
                    if ((infoInspection.feature as any).source === FeatureSource.POINTS_AUDIT) {
                        this.setupPointAuditInfo(infoInspection);
                    }
                } else {
                    this.setupPointInspectionInfo(infoInspection);
                    this.setupPointAuditInfo(infoInspection);
                }
                this.evaluateActionSheet();
            })
        );

        this.subscribeToIsAppOnline();
        this.subscribeToSelectUserInformation();
        this.subscribeToCanOpenProjetAuditList();
        this.subscribeToCurrentActiveProjetAudit();
        this.subscribeToCurrentActiveProjetInspection();
        this.subscribeToCreatePointAuditSuccess();
        this.subscribeToUpdatePointAuditSuccess();
        this.subscribeToCreateProjetInspection();
        this.subscribeToCreatePointInspectionSuccess();
        this.subscribeToUpdatePointInspectionSuccess();
        this.subscribeToCreateAnomalieInspectionSuccess();
        this.subscribeToUpdateAnomalieInspectionSuccess();
        this.subscribeToDeleteAnomalieInspectionSuccess();
        this.subscribeToCompleteProjetInspectionSuccess();
        this.subscribeToCompleteProjetAuditSuccess();
        this.subscribeToAddPointAuditSuccess();
        this.subscribeToProjetsAddedToIndexedDb();
        this.subscribeToProjetAddedToIndexedDbSuccess();
        this.subscribeToProjetsDownloaded();
        this.subscribeToGpsCoordinates();
        this.subscribeToUpdateInfoPointsInspections();
        this.subscribeToStartDataExtraction();
        this.subscribeToStartDataExtractionError();
        this.subscribeToDataExtraction();
        this.subscribeToDataExtractionError();
    }

    private isPointInProjetsInspectionDownloaded(infoInspection: InfoPoint) {
        if (this.mapPermissionsService.canEditPointInspection() ||
            !!this.projetsInspectionDownloaded?.find(projet => projet.id === infoInspection.feature.properties.projetId)) {
            this.setupPointInspectionInfo(infoInspection);
        } else {
            this.messageService.add({
                severity: Severite.erreur,
                closable: true,
                life: 5000,
                summary: `Erreur`,
                detail: `Vous ne pouvez pas inspecter un point qui n’est
                    pas inclus dans vos projets téléchargés. Veuillez d’abord activer et
                    télécharger le projet correspondant. Notez que la limite des
                    projets que vous pouvez télécharger est de 3.`
            });
        }
    }

    private isPointInProjetsAuditDownloaded(infoInspection: InfoPoint) {
        if (this.mapPermissionsService.canEditPointAudit() ||
            !!this.projetsAuditDownloaded?.find(projet => projet.projetId === infoInspection.feature.properties.projetId)) {
            this.setupPointInspectionInfo(infoInspection);
        } else {
            this.messageService.add({
                severity: Severite.erreur,
                closable: true,
                life: 5000,
                summary: `Erreur`,
                detail: `Vous ne pouvez pas auditer un point d'inspection qui n’est
                    pas inclus dans vos projets téléchargés. Veuillez d’abord activer et
                    télécharger le projet d’audit correspondant. Notez que la limite des
                    projets que vous pouvez télécharger est de 3.`
            });
        }
    }

    // public closeActionSheet() {
    //     this.infoPointInspection = { openSideBar: false, feature: undefined };
    // }

    public evaluateActionSheet() {
        this.isInspecteurPanel = !this.ignorePointPanelVisible && !this.createMovePoteauPanelVisible &&
            !(this.isAuditeur || (this.mapPermissionsService.roleIsMarkAsAuditeur() && this.isPointAuditSelected));
        this.isInspecteurPanel ? this.setupInspecteurPanel() : this.setupAuditeurPanel();
    }

    public ignoreVisibleChanged(isVisible: boolean) {
        this.ignorePointPanelVisible = isVisible;
        this.evaluateActionSheet();
    }

    public createMovePoteauPanelVisibleChanged(isVisible: any) {
        this.createMovePoteauPanelVisible = isVisible;
        this.evaluateActionSheet();
    }

    private setupInspecteurPanel() {
        this.topLeftIcon = this.isDetailHaveAudit;
        this.topLeftDisabled = this.isPoteauDetailsDialogOpened;
        this.topRightLabel = 'Déplacer';
        this.topRightDisabled = this.isPoteauDetailsDialogOpened;
        this.bottomLeftIcon = this.isAnomalieHaveAudit;
        this.bottomLeftDisabled = this.isAnomalieDisabled || this.isPoteauDetailsDialogOpened;
        this.bottomRightDisabled = this.isIgnorePointDisabled || this.isPoteauDetailsDialogOpened;
        this.centerDisable = this.isAucuneAnomalieDisabled || this.isPoteauDetailsDialogOpened;
    }

    private setupAuditeurPanel() {
        this.topLeftIcon = this.isDetailHaveNonConformite;
        this.topLeftDisabled = this.isPoteauDetailsDialogOpened || !this.isPointInspectionHavePointsAudit;
        this.topRightLabel = `Ajouter à l'audit`;
        this.topRightDisabled = this.isPointInspectionHavePointsAudit;
        this.bottomLeftIcon = this.isAnomalieHaveNonConformite;
        this.bottomLeftDisabled = this.isPoteauDetailsDialogOpened || this.isAnomalieAuditButtonDisabled || !this.isPointInspectionHavePointsAudit;
        this.bottomRightDisabled = this.isPoteauDetailsDialogOpened || this.selectedPointAudit?.statutGlobal === this.statutPointAudit.nonConforme ||
            !this.isPointInspectionHavePointsAudit;
        this.centerDisable = true; // On le met a true (disable) car il ne devrait jamais apparaitre pour un auditeur.
    }

    public actionSheetTopRightButton() {
        if (this.isInspecteurPanel || this.mapPermissionsService.canEditPointInspection()) {
            this.movePoteau();
        } else if (this.isAuditeur || this.mapPermissionsService.canEditPointAudit()) {
            this.addPointToProjetAudit();
        }
    }

    private subscribeToIsAppOnline() {
        this.store.select(getIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(isAppOnline => {
            this.isAppOnline = isAppOnline;
        });
    }

    private subscribeToSelectUserInformation() {
        this.store.select(selectUserInformation).pipe(
            filter(userInfos => !!userInfos),
            takeUntil(this.destroyed)
        ).subscribe(currentUserInfo => {
            this.currentUserInfo = currentUserInfo;
        });
    }

    private subscribeToCanOpenProjetAuditList() {
        this.store.select(canLoadProjetAuditList).pipe(
            filter(openProjetAuditList => !!openProjetAuditList),
            takeUntil(this.destroyed)
        ).subscribe(() => {
            this.store.dispatch(AuditActions.loadProjetAuditList());
        });
    }

    private subscribeToCurrentActiveProjetAudit() {
        this.store.select(getCurrentActiveProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(currentActiveProjetAudit => {
            this.currentActiveProjetAudit = currentActiveProjetAudit;
        });
    }

    private subscribeToCurrentActiveProjetInspection() {
        this.store.select(getCurrentActiveProjetInspection).pipe(
            filter(currentActiveProjetInspection => !!currentActiveProjetInspection),
            takeUntil(this.destroyed)
        ).subscribe(currentActiveProjetInspection => {
            this.currentActiveProjetInspection = currentActiveProjetInspection;
        });
    }

    private subscribeToProjetsAddedToIndexedDb() {
        const projetAddedToIndexedDb$ = this.store.select(getProjetAddedToIndexedDb);
        const projetAuditAddedToIndexedDb$ = this.store.select(getProjetAuditAddedToIndexedDb);

        combineLatest([projetAddedToIndexedDb$, projetAuditAddedToIndexedDb$])
            .pipe(
                filter(response => !!response[0] || !!response[1]),
                takeUntil(this.destroyed)
            ).subscribe(() => {
                this.store.dispatch(OfflineActions.getProjetsDownloaded());
                this.store.dispatch(OfflineActions.getProjetsAuditDownloaded());
            });
    }

    private subscribeToProjetsDownloaded() {
        const projetsDownloaded$ = this.store.select(getProjetsDownloaded);
        const projetsAuditDownloaded$ = this.store.select(getProjetsAuditDownloaded);

        combineLatest([projetsDownloaded$, projetsAuditDownloaded$])
            .pipe(
                filter(response => !!response[0] || !!response[1]),
                takeUntil(this.destroyed)
            ).subscribe(([projetsDownloaded, projetsAuditDownloaded]) => {
                this.projetsInspectionDownloaded = projetsDownloaded;
                this.projetsAuditDownloaded = projetsAuditDownloaded;
            });
    }

    private subscribeToProjetAddedToIndexedDbSuccess() {
        this.store.select(getProjetAddedToIndexedDbSuccess)
            .pipe(
                filter(response => !!response),
                takeUntil(this.destroyed),
            ).subscribe(success => {
                if (success) {
                    this.messageService.add({
                        severity: Severite.success,
                        closable: true,
                        summary: `Téléchargement du projet`,
                        detail: `Le téléchargement du projet est terminé`
                    });
                }
            });
    }

    private subscribeToCreateProjetInspection() {
        this.store.select(getCreateProjetInspection)
            .pipe(
                filter(createdProjet => !!createdProjet),
                takeUntil(this.destroyed)
            )
            .subscribe(createdProjet => {
                if (createdProjet) {
                    this.messageService.add(
                        {
                            severity: 'success',
                            closable: true,
                            summary: `Création de projet`,
                            detail: `Le projet ${createdProjet?.nom} a bien été créé.`,
                            life: 15000
                        });
                }
            });
    }

    private subscribeToCompleteProjetInspectionSuccess() {
        this.store.select(getCompleteProjetInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(projet => {
            if (projet) {
                this.store.dispatch(InspectionActions.setCurrentActiveProjetInspection({ projetInspection: null }));
                this.store.dispatch(OfflineActions.deleteProjetFromIndexedDb({ storeName: StoreName.PROJETS, projetId: projet.id }));
                this.store.dispatch(OfflineActions.deleteInspectionPhotosFromIndexedDb({ projetId: projet.id }));
                const clearFeatures: FeatureCollection = { features: [], type: 'FeatureCollection' };
                (this.mapService.map.getSource(MapLayersSources.POTEAU_SELECTED) as mapboxgl.GeoJSONSource).setData(clearFeatures);
                this.uiService.triggerPointInspection({ openSideBar: false, feature: undefined });

                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Compléter un projet`,
                    detail: `Le projet ${projet.nom} a été complété avec succès`
                });
            }
        });
    }

    private subscribeToCompleteProjetAuditSuccess() {
        this.store.select(getCompleteProjetAuditSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe((projetAudit) => {
                if (projetAudit) {
                    this.store.dispatch(InspectionActions.clearPointsInspection());
                    this.store.dispatch(AuditActions.setCurrentActiveProjetAudit({ projetAudit: null }));
                    this.store.dispatch(OfflineActions.deleteProjetFromIndexedDb({ storeName: StoreName.PROJETS, projetId: projetAudit.projetId }));
                    this.store.dispatch(OfflineActions.deleteProjetFromIndexedDb({ storeName: StoreName.PROJETS_AUDIT, projetId: projetAudit.id }));
                    this.store.dispatch(OfflineActions.deleteInspectionPhotosFromIndexedDb({ projetId: projetAudit.projetId }));
                    this.store.dispatch(OfflineActions.deleteAuditPhotosFromIndexedDb({ projetAuditId: projetAudit.id }));
                    const clearFeatures: FeatureCollection = { features: [], type: 'FeatureCollection' };
                    (this.mapService.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as mapboxgl.GeoJSONSource).setData(clearFeatures);
                    this.uiService.triggerPointInspection({ openSideBar: false, feature: undefined });

                    this.messageService.add({
                        severity: Severite.success,
                        closable: true,
                        summary: `Compléter un projet d'audit`,
                        detail: `Le projet ${projetAudit.nom} a été complété avec succès`
                    });
                }
            });
    }

    private subscribeToCreatePointInspectionSuccess() {
        this.store.select(getCreatePointInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Création de poteau`,
                    detail: `Le poteau a été créé`
                });
            }
        });
    }

    private subscribeToCreatePointAuditSuccess() {
        this.store.select(getCreatePointAuditSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Création d'un point d'audit`,
                    detail: `Le point d'audit a bien été ajouté`
                });
            }
        });
    }

    private subscribeToUpdatePointInspectionSuccess() {
        this.store.select(getUpdatePointInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Mise à jour de détails poteau`,
                    detail: `Les informations du poteau ont bien été modifiées`
                });
            }
        });
    }

    private subscribeToCreateAnomalieInspectionSuccess() {
        this.store.select(getCreateAnomalieInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(anomalie => {
            if (anomalie) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Création d'anomalie`,
                    detail: `L'anomalie ` + anomalie.element + ` a été créé avec succès`
                });
            }
        });
    }

    private subscribeToUpdateAnomalieInspectionSuccess() {
        this.store.select(getUpdateAnomalieInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(anomalie => {
            if (anomalie) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Modification d'anomalie`,
                    detail: `L'anomalie ` + anomalie.element + ` a bien été modifiée`
                });
            }
        });
    }

    private subscribeToUpdatePointAuditSuccess() {
        this.store.select(getUpdatePointAuditSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Mise à jour du point d'audit`,
                    detail: `Les informations du point d'audit ont bien été modifiées`
                });
            }
        });
    }

    private subscribeToDeleteAnomalieInspectionSuccess() {
        this.store.select(getDeleteAnomalieInspectionSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed),
        ).subscribe(success => {
            if (success) {
                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Suppression d'anomalie`,
                    detail: `L'anomalie a bien été supprimée`
                });
            }
        });
    }

    private subscribeToAddPointAuditSuccess() {
        this.store.select(getAddPointAuditSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed)
        ).subscribe(pointAudit => {
            if (pointAudit) {
                this.selectPointInspectionByPointAudit(pointAudit);

                const clearFeatures: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { features: [], type: 'FeatureCollection' };
                (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as mapboxgl.GeoJSONSource).setData(clearFeatures);

                const pointAuditFeatures: FeatureCollectionPointAudit = generatePointsAuditFeatures([pointAudit]);
                const feature = pointAuditFeatures.features[0] as Feature;
                const pointAuditFeatureCollection: FeatureCollection = { features: [feature as Feature], type: 'FeatureCollection' };
                (this.mapService.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as mapboxgl.GeoJSONSource).setData(pointAuditFeatureCollection);
                this.selectedPointAudit = pointAudit;

                this.messageService.add({
                    severity: Severite.success,
                    closable: true,
                    summary: `Ajout d'un point d'audit`,
                    detail: `Le point d'audit a bien été ajouté`
                });
            }
        });
    }

    private selectPointInspectionByPointAudit(pointAudit: PointAuditDto) {
        this.store.select(getPointInspectionById(pointAudit.pointInspectionId)).pipe(
            take(1)
        ).subscribe(pointInspection => {
            if (pointInspection) {
                const pointInpectionFeatures: FeatureCollectionPointInspection = generatePointsInspectionFeatures([pointInspection]);
                const foundPointInspectionfeature = pointInpectionFeatures.features.find((pif: any) =>
                    pif?.properties?.inspectionId === pointAudit.pointInspectionId
                );
                if (foundPointInspectionfeature) {
                    const infoPointSelected: InfoPoint = { openSideBar: true, feature: foundPointInspectionfeature };
                    this.uiService.triggerPointInspection(infoPointSelected);
                }
            }
        });
    }

    private subscribeToStartDataExtraction() {
        this.store.select(getStartDataExtraction)
            .pipe(
                filter(startDataExtraction => !!startDataExtraction),
                takeUntil(this.destroyed)
            ).subscribe((startDataExtraction) => {
                if (startDataExtraction) {
                    this.store.dispatch(SharedActions.getDataExtraction({ key: startDataExtraction }));

                    this.initIntervalForDataExtraction(5);

                    this.dataExtractionTimer = setInterval(() => {
                        if (this.counterIntervalForDataExtraction < this.maxIterationForDataExtraction) {
                            this.counterIntervalForDataExtraction++;
                            this.store.dispatch(SharedActions.getDataExtraction({ key: startDataExtraction }));
                        } else {
                            this.deepCancelInterval(this.dataExtractionTimer);
                        }
                    }, this.intervalBetweenCallCheck);
                }
            });
    }

    private subscribeToDataExtraction() {
        this.store.select(getDataExtraction).pipe(
            filter(dataExtraction => !!dataExtraction),
            takeUntil(this.destroyed)
        ).subscribe((dataExtraction) => {
            if (dataExtraction) {
                this.cancelInterval(this.dataExtractionTimer);

                saveAs(dataExtraction.lien);

                this.messageService.add({
                    severity: 'success',
                    closable: true,
                    summary: 'Extraction de données',
                    detail: `Les données extraites ont été téléchargées avec succès.`,
                    sticky: true
                });
            }
        });
    }

    private subscribeToStartDataExtractionError() {
        this.store.select(getStartDataExtractionError)
            .pipe(
                filter(error => !!error),
                takeUntil(this.destroyed)
            ).subscribe((error) => {
                if (error) {
                    this.cancelInterval(this.dataExtractionTimer);

                    this.messageService.add(
                        {
                            severity: 'error',
                            closable: true,
                            summary: 'Extraction de données',
                            detail: `Une erreur est survenue lors de la récupération d'un numéro pour le téléchargement`,
                            sticky: true
                        });
                }
            });
    }

    private subscribeToDataExtractionError() {
        this.store.select(getDataExtractionError).pipe(
            filter(error => !!error),
            takeUntil(this.destroyed)
        ).subscribe((error) => {
            if (error) {
                this.cancelInterval(this.dataExtractionTimer);

                this.messageService.add(
                    {
                        severity: 'error',
                        closable: true,
                        summary: 'Extraction de données',
                        detail: `Une erreur est survenue lors de l'extraction des données.`,
                        sticky: true
                    });
            }
        });
    }

    private disableAucuneAnomalieButton(pointInspection: PointInspectionDto) {
        this.isAucuneAnomalieDisabled = pointInspection && pointInspection.anomalies && pointInspection.anomalies.length > 0
            || this.isAlreadySansAnomalie(pointInspection) || false;
    }

    private isAlreadySansAnomalie(pointInspection: PointInspectionDto): boolean {
        return pointInspection?.statut === StatutPointInspection.sansAnomalie;
    }

    private disableIgnorePointButton(pointInspection: PointInspectionDto) {
        this.isIgnorePointDisabled = pointInspection && pointInspection.anomalies && pointInspection.anomalies.length > 0 || false;
    }

    private disableAddToAuditButton(pointInspection: PointInspectionDto) {
        this.isPointInspectionHavePointsAudit = pointInspection
            && pointInspection.pointsAudit
            && pointInspection.pointsAudit.length > 0;
    }

    private disableAnomalieAuditButton(pointInspection: PointInspectionDto) {
        this.isAnomalieAuditButtonDisabled = !pointInspection?.anomalies?.length;
    }

    private detailHaveAudit(pointInspection: PointInspectionDto) {
        this.isDetailHaveAudit = pointInspection
            && pointInspection.pointsAudit
            && pointInspection.pointsAudit.length > 0
            && pointInspection.pointsAudit[0].remarque
            && pointInspection.pointsAudit[0].remarque !== '';
    }

    private anomalieHaveAudit(pointInspection: PointInspectionDto) {
        this.isAnomalieHaveAudit = pointInspection
            && pointInspection.pointsAudit
            && pointInspection.pointsAudit.length > 0
            && pointInspection.pointsAudit[0].anomaliesAudit
            && pointInspection.pointsAudit[0].anomaliesAudit.length > 0;
    }

    public detailHaveNonConformite(pointAudit: PointAuditDto): boolean {
        return pointAudit?.statut === StatutPointAudit.nonConforme;
    }

    public anomalieHaveNonConformite(pointAudit: PointAuditDto): boolean {
        return !!pointAudit?.anomaliesAudit.find(anomalie => anomalie.statut === StatutPointAudit.nonConforme);
    }

    private disableAnomalieButton(pointInspection: PointInspectionDto): void {
        if (pointInspection && pointInspection.poteau) {
            const nbOfFalseOfPass = 3;
            let nbOfFalse = 0;

            if (pointInspection.poteau[PoteauDetails.CODEABARRES] &&
                pointInspection.poteau[PoteauDetails.CODEABARRES] !== '' &&
                pointInspection.poteau[PoteauDetails.CODEABARRES].length > 0) {
                nbOfFalse++;
            }

            if (pointInspection[PoteauDetails.ACCESSIBLE_CAMION] &&
                (pointInspection[PoteauDetails.ACCESSIBLE_CAMION] === 'oui' ||
                    pointInspection[PoteauDetails.ACCESSIBLE_CAMION] === 'non')) {
                nbOfFalse++;
            }

            if (pointInspection.poteau[PoteauDetails.ADRESSE_TRAVAUX] &&
                pointInspection.poteau[PoteauDetails.ADRESSE_TRAVAUX] !== '' &&
                [PoteauDetails.ADRESSE_TRAVAUX].length > 0) {
                nbOfFalse++;
            }
            this.isAnomalieDisabled = nbOfFalse !== nbOfFalseOfPass;
        } else {
            this.isAnomalieDisabled = true;
        }
    }

    private setupPointInspectionInfo(infoInspection: InfoPoint) {
        if (infoInspection && infoInspection.feature && infoInspection.feature.properties) {
            let inspectionId: string | null = null;
            if (infoInspection.feature?.properties.hasOwnProperty(PointInspectionProperties.inspectionId)) {
                inspectionId = infoInspection.feature?.properties[PointInspectionProperties.inspectionId];
            } else if (infoInspection.feature?.properties.hasOwnProperty(PointAuditProperties.pointInspectionId)) {
                inspectionId = infoInspection.feature?.properties[PointAuditProperties.pointInspectionId];
            }

            if (inspectionId !== null) {
                this.currentPointInspection$ = this.store.select(getPointInspectionById(inspectionId));
                this.setupCurrentPointInspection();
            }
        }
        this.infoPointInspection = { openSideBar: infoInspection.openSideBar, feature: infoInspection.feature };
        this.selectedPointInspectionCodeBar = this.infoPointInspection.feature ?
            this.infoPointInspection.feature!.properties![PointInspectionProperties.codeABarres] : '';
    }

    private setupCurrentPointInspection() {
        this.rapideSubscriptions.push(
            this.currentPointInspection$.pipe(
                tap((pointInspection: PointInspectionDto) => {
                    this.selectedPointInspection = pointInspection;
                    this.detailHaveAudit(pointInspection);
                    this.anomalieHaveAudit(pointInspection);
                    this.disableAnomalieButton(pointInspection);
                    this.disableAucuneAnomalieButton(pointInspection);
                    this.disableIgnorePointButton(pointInspection);
                    this.disableAddToAuditButton(pointInspection);
                    this.disableAnomalieAuditButton(pointInspection);
                    if (pointInspection && pointInspection?.pointsAudit && pointInspection[0]?.pointsAudit.length > 0) {
                        this.isDetailHaveNonConformite = this.detailHaveNonConformite(pointInspection?.pointsAudit[0]);
                    } else {
                        this.isDetailHaveNonConformite = false;
                    }

                    if (this.infoPointInspection.openSideBar || this.createMovePoteauPanelVisible) {
                        this.evaluateActionSheet();
                    }
                })
            ).subscribe()
        );
    }

    private setupPointAuditInfo(infoAudit: InfoPoint) {
        if (infoAudit && infoAudit.feature && infoAudit.feature.properties) {
            this.setupCurrentPointAudit(infoAudit);
        }
        this.infoPointAudit = { openSideBar: infoAudit.openSideBar, feature: infoAudit.feature };
    }

    private setupCurrentPointAudit(infoAudit: InfoPoint) {
        if (infoAudit.feature?.properties!.hasOwnProperty(PointAuditProperties.pointInspectionId) ||
            infoAudit.feature?.properties!.hasOwnProperty('pointsAudit')
        ) {
            // TODO : Trouver une meilleur mécanique pour tout le système selélection de points (inspection et audit)
            let pointAuditId = '';
            let pointInspectionId = '';
            if (infoAudit.feature?.properties!.hasOwnProperty(PointAuditProperties.pointInspectionId)) {
                pointInspectionId = infoAudit.feature?.properties![PointAuditProperties.pointInspectionId];
                pointAuditId = infoAudit.feature?.properties![PointAuditProperties.id];
            } else {
                if (typeof infoAudit.feature?.properties!['pointsAudit'] === 'string' &&
                    infoAudit.feature?.properties!['pointsAudit'].length > 0 &&
                    infoAudit.feature?.properties!['pointsAudit'] !== '[]'
                ) {
                    const parsePointsAudit = JSON.parse(infoAudit.feature.properties.pointsAudit);
                    pointAuditId = parsePointsAudit[0][PointAuditProperties.id];
                    pointInspectionId = parsePointsAudit[0][PointAuditProperties.pointInspectionId];
                } else if (typeof infoAudit.feature?.properties!['pointsAudit'] === 'object' &&
                    infoAudit.feature?.properties!['pointsAudit'].length > 0 &&
                    infoAudit.feature?.properties!['pointsAudit'][0].hasOwnProperty(PointAuditProperties.pointInspectionId)
                ) {
                    pointAuditId = infoAudit.feature?.properties!['pointsAudit'][0][PointAuditProperties.id];
                    pointInspectionId = infoAudit.feature?.properties!['pointsAudit'][0][PointAuditProperties.pointInspectionId];
                }
            }

            if (pointAuditId !== '' && pointInspectionId !== '') {
                this.store.select(getPointAuditById(pointAuditId)).pipe(
                    filter(pointsAudit => !!(pointsAudit?.length)),
                    takeUntil(this.destroyed)
                ).subscribe(pointAudit => {
                    this.selectedPointAudit = pointAudit[0];
                    if (this.isAuditeur || this.mapPermissionsService.roleIsMarkAsAuditeur()) {
                        this.isDetailHaveNonConformite = this.detailHaveNonConformite(pointAudit[0]);
                        this.isAnomalieHaveNonConformite = this.anomalieHaveNonConformite(pointAudit[0]);
                        this.isPointInspectionHavePointsAudit = true;
                    }
                });

                // TODO ANALYSE THIS CODE
                this.store.select(getPointInspectionById(pointInspectionId)).pipe(
                    filter(success => !!success),
                    takeUntil(this.destroyed)
                ).subscribe(pointInspection => {
                    if (!this.infoPointInspection.feature) {
                        this.initInfoPointInspection();
                    }
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.anomalies] = pointInspection.anomalies;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.codeABarres] = pointInspection.poteau.codeABarres;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.accessibleCamion] = pointInspection.accessibleCamion;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.indEquipementMajeur] = pointInspection.indEquipementMajeur;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.adresseTravaux] = pointInspection.poteau.adresseTravaux;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.lclcl] = pointInspection.poteau.lclcl;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.localisation] = pointInspection.poteau.localisation;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.proprietaire] = pointInspection.poteau.proprietaire;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.usage] = pointInspection.poteau.usage;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.materiau] = pointInspection.poteau.materiau;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.montage] = pointInspection.poteau.montage;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.classe] = pointInspection.poteau.classe;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.longueur] = pointInspection.poteau.longueur;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.hauteurHorsSol] = pointInspection.poteau.hauteurHorsSol;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.inclinaison] = pointInspection.poteau.inclinaison;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.essence] = pointInspection.poteau.essence;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.fabricant] = pointInspection.poteau.fabricant;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.anneeFabrication] = pointInspection.poteau.anneeFabrication;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.anneeInstallation] = pointInspection.poteau.anneeInstallation;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.traitementInitial] = pointInspection.poteau.traitementInitial;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.remarquePoteau] = pointInspection.remarquePoteau;
                    this.infoPointInspection.feature!.properties![PointInspectionProperties.photos] = pointInspection.photos;
                });
            }
        }
    }

    public addPointToProjetAudit() {
        const pointAuditId = Guid.create().toString();

        const pointAudit: PointAuditDto = {
            id: pointAuditId,
            projetAuditId: this.currentActiveProjetAudit.id,
            pointInspectionId: this.selectedPointInspection.id,
            geometrie: this.selectedPointInspection.geometrie,
            statut: StatutPointAudit.aAuditer,
            statutGlobal: StatutPointAudit.aAuditer,
            auditeLe: 0,
            anomaliesAudit: !this.isAppOnline ? generateAnomalieAudit(this.selectedPointInspection, pointAuditId) : [],
            photos: [],
        };

        this.store.dispatch(AuditActions.addPointAudit({ pointAudit }));
    }

    public initSync() {
        this.syncService.openSynchronisationDialog();
    }

    public toggleWarning() {
        this.warningMinimized = !this.warningMinimized;
    }

    private init() {
        const actionBar = new MapboxButtonControl(
            {
                buttons: [this.mapService.createActionButton('Accueil', 'fas fa-home', () => this.mapService.recentrerMap())]
            });
        const layerActionBar = new MapboxButtonControl(
            {
                buttons: [this.mapService.createActionButton('Liste des couches', 'fas fa-layer-group', () => this.getlegende())]
            });
        const basemapBar = new MapboxButtonControl(
            {
                buttons: [
                    this.mapService.createActionButton('Position GPS', 'fas fa-satellite', () => this.openGPSDialog(), ['gps-icon']),
                    this.mapService.createActionButton('Centrer sur la position actuelle du GPS', 'fas fa-location-dot', () => this.centerToGPSCoordinates()),
                    this.mapService.createActionButton('Effacer marqueurs', 'fas fa-stop', () => this.effacerMarqueur())
                ]
            });
        const drawBar = new MapboxButtonControl({
            draw: this.mapService.draw,
            buttons: []
        });
        this.mapService.map.addControl(layerActionBar, 'top-right');
        this.mapService.map.addControl(basemapBar, 'top-right');
        this.mapService.map.addControl(actionBar, 'top-left');
        this.mapService.map.addControl(drawBar, 'top-left');
        this.map.addSource('basemap', {
            type: 'vector',
            // fonctionne en mode hors ligne, les calls https sont abstraits par le service worker et orientés vers la cache pour toute donnée 'préfetchée'
            tiles: ['https://basemaps-api.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf']
        });
        this.mapService.map.boxZoom.enable();

        this.subscriptions.push(
            this.isInspecteur$.pipe(
                tap((inspecteur) => this.isInspecteur = inspecteur)
            ).subscribe(),

            this.isUserMobile$.pipe(
                tap((userMobile) => this.isUserMobile = userMobile)
            ).subscribe(),

            this.isControleurQualite$.pipe(
                tap((controleurQualite) => this.isControleurQualite = controleurQualite)
            ).subscribe(),

            this.isAuditeur$.subscribe((auditeur) => {
                this.isAuditeur = auditeur;
                this.evaluateActionSheet();
            }),
        );
    }

    private openProjetsListDialog() {
        this.ref = this.dialogService.open(ProjetsComponent,
            {
                header: `Tableau des projets`,
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: 'desktop-dialog',
            });
    }

    private openProjetInspectionListDialog() {
        this.ref = this.dialogService.open(InspectionComponent,
            {
                header: `Tableau des projets d'inspection`,
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: this.isInspecteur || this.isControleurQualite ? 'mobile-dialog' : 'desktop-dialog',
            });
    }

    private openProjetAuditListDialog() {
        this.ref = this.dialogService.open(AuditComponent,
            {
                header: `Tableau des projets d'audit`,
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: this.isAuditeur ? 'mobile-dialog' : 'desktop-dialog',
            });
    }

    private openPilotageDialog() {
        this.ref = this.dialogService.open(PilotageDialogComponent,
            {
                header: 'Pilotage',
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: 'desktop-dialog',
            });
    }

    private openVersionDialog(): void {
        this.ref = this.dialogService.open(VersionComponent,
            {
                header: 'À propos de Capture',
                width: '25%',
                modal: true,
                styleClass: this.isInspecteur || this.isControleurQualite || this.isAuditeur ? 'mobile-dialog' : 'desktop-dialog',
            });
    }

    private openSelectProjetDialog() {
        this.ref = this.dialogService.open(SelectProjetDialogComponent,
            {
                width: '60%',
                height: '18%',
                showHeader: false,
                closable: false,
                styleClass: 'select-projet-dialog',
            });
    }

    private openSelectProjetAuditDialog() {
        this.ref = this.dialogService.open(SelectProjetAuditDialogComponent,
            {
                width: '60%',
                height: '18%',
                showHeader: false,
                closable: false
            });
    }

    public openAnomalieDialog() {
        if (this.verifyProjetAndProjetAuditNotDownloaded()) {
            if (!this.mapPermissionsService.canEditPointInspection()) {
                return this.popupErreurProjetDownloaded(`Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
            }
        }

        this.ref = this.dialogService.open(AnomalieDialogComponent,
            {
                header: `Code à barres: ${this.codeABarres}`,
                width: '100%',
                height: '100%',
                styleClass: 'mobile-dialog',
                data: {
                    projetInspectionId: this.selectedPointInspection.projetId,
                    inspectionId: this.isAuditeur ? this.selectedPointAudit?.pointInspectionId : this.selectedPointInspection.id,
                    currentActiveProjetAudit: this.currentActiveProjetAudit,
                    currentActiveProjetInspection: this.currentActiveProjetInspection,
                    projetsInspectionDownloaded: this.projetsInspectionDownloaded,
                    selectedPointAudit: this.selectedPointAudit,
                    currentUserInfo: this.currentUserInfo,
                    isAuditeur: this.isAuditeur,
                    isAuditSelected: this.isPointAuditSelected,
                }
            });
    }

    private initCreatePoteau(isCreatePoteau: boolean, isCreatePoteauSansAnomalie?: boolean) {
        if (!this.projetsInspectionDownloaded?.length || (!this.offlineService.isOfflineMode && !navigator.onLine)) {
            return this.popupErreurProjetDownloaded(`Veuillez sélectionner un projet de votre liste
            de projets et le télécharger avant de pouvoir ajouter un poteau.`);
        } else {
            if (!this.currentActiveProjetInspection) {
                this.openSelectProjetDialog();
                this.ref.onClose.subscribe((selectedProjet) => {
                    this.store.dispatch(InspectionActions.setCurrentActiveProjetInspection({ projetInspection: selectedProjet }));
                    this.mapService.zoomProjetInspection(selectedProjet);
                    if (isCreatePoteau) {
                        this.openPoteauDetailsDialog(isCreatePoteau);
                    }
                    if (isCreatePoteauSansAnomalie) {
                        this.initCreatePoteauSansAnomalie();
                    }
                });
            } else {
                if (!this.isProjetInspectionDownloaded(this.currentActiveProjetInspection)) {
                    return this.popupErreurProjetDownloaded(`Pour ajouter un poteau au projet ` + this.currentActiveProjetInspection?.nom +
                        `, veuillez d’abord activer ce projet. Une fois le projet activé, vous pourrez ajouter le poteau souhaité.`);
                }
                if (isCreatePoteauSansAnomalie) {
                    this.initCreatePoteauSansAnomalie();
                    return;
                }
                this.openPoteauDetailsDialog(isCreatePoteau);
            }
        }
    }

    public initPoteauDetailsDialog() {
        this.mapPermissionsService.canEditPointInspection() ? this.openPoteauDetailsDialog(false) : this.initCreatePoteau(false);
    }

    private openPoteauDetailsDialog(isCreatePoteau: boolean) {
        if (this.verifyProjetAndProjetAuditNotDownloaded()) {
            // On vérifie seulement si l'utilisateur à le droit d'ouvrir la fenêtre de détail même s'il n'a pas de projet téléchargé
            if (!this.mapPermissionsService.canEditPointInspection()) {
                return this.popupErreurProjetDownloaded(`Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
            }
        }

        if (isCreatePoteau && this.infoPointInspection.feature === undefined) {
            this.initInfoPointInspection();
        }

        this.uiService.setDetailPoteauDialogOpened(true);
        const isUserAuditeur = this.isAuditeur || (this.mapPermissionsService.roleIsMarkAsAuditeur() && this.isPointAuditSelected);

        this.ref = this.dialogService.open(PoteauDetailsComponent,
            {
                width: '100%',
                height: '100%',
                modal: false,
                showHeader: false,
                styleClass: 'detail-dialog',
                data: {
                    isAuditSelected: this.isPointAuditSelected,
                    pointInspection: this.infoPointInspection,
                    selectedPointAudit: this.selectedPointAudit,
                    currentActiveProjetAudit: this.currentActiveProjetAudit,
                    currentActiveProjetInspection: this.currentActiveProjetInspection,
                    projetsInspectionDownloaded: this.projetsInspectionDownloaded,
                    currentUserInfo: this.currentUserInfo,
                    isCreatePoteau: isCreatePoteau,
                    isAuditeur: isUserAuditeur,
                }
            });

        this.ref.onClose.pipe(
            tap(pointInspection => {
                if (pointInspection) {
                    this.createPoteauInfo = pointInspection;
                    this.movePoteau();
                }
            }),
            takeUntil(this.destroyed),
        ).subscribe(() => {
            this.uiService.setDetailPoteauDialogOpened(false);
            this.ref.destroy();
        });
    }

    public openGPSDialog() {
        this.dialogService.open(GpsComponent, {
            header: 'GPS',
            width: '600px',
            height: '550px',
            modal: true,
            styleClass: 'gps-dialog',
        });
    }

    private showMapPopUp(e: {
        value: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } & mapboxgl.EventData,
        avecMasqueButton: boolean
    }): void {
        this.selectedPopUpFeature = this.map.queryRenderedFeatures(e.value.point);
        this.userGroups = this.currentUserInfo.groupes;
        const geometrie = (e.value.features![0] as Feature).geometry as Geometry;
        this.popupContainer.nativeElement.style.display = 'block';
        this.mapService.setupPopUpWithContent(this.popupContainer.nativeElement, geometrie);
    }

    public closePopUpInfo(popUpInfoCloseEvent: PopUpInfoCloseEvent) {
        this.popupContainer.nativeElement.style.display = 'none';
        this.mapService.closePopUpInfo(popUpInfoCloseEvent.closed);

        // On vérifie le type de point qui est modifier afin d'ouvrir l'action sheet avec ce point sélectionné
        if (popUpInfoCloseEvent.pointInspectionId) {
            this.isPointAuditSelected = false;
            if (popUpInfoCloseEvent.pointInspectionFeature) {
                this.mapService.setupPointInspection(popUpInfoCloseEvent.pointInspectionFeature);
            }
        } else if (popUpInfoCloseEvent.pointAuditId) {
            this.isPointAuditSelected = true;
            if (popUpInfoCloseEvent.pointInspectionFeature && popUpInfoCloseEvent.pointAuditFeature) {
                this.mapService.setupPointInspection(popUpInfoCloseEvent.pointInspectionFeature);
                this.mapService.setupPointInspection(popUpInfoCloseEvent.pointAuditFeature);
            }
        }
        this.evaluateActionSheet();
    }

    public closeRechercheDialog(): void {
        this.uiService.openRechercheModal(false);
    }

    public geoCodeSelectFromArray(event: Partial<GeoJson>): void {
        if (event) {
            this.geoCodeSelect(event[0]);
        }
    }

    public geoCodeSelect(event: any): void {
        if (!isUndefined(this.geocoderMarker)) {
            this.geocoderMarker.remove();
        }
        this.geocoderMarker = new mapboxgl.Marker();
        this.geocoderMarker.setLngLat(event.geometry.coordinates).setPopup(new mapboxgl.Popup().setHTML(event.properties.address)).addTo(this.map);
        this.geocoderMarker.togglePopup();
        this.map.setZoom(14);
        this.map.setCenter(event.geometry.coordinates);
    }

    public getGeocodeService(): void {
        const accessToken = JSON.parse(window.localStorage.getItem(LocalStorageIndex.ESRI_ACCESS_TOKEN));
        const authentication = new ApiKey({ key: `${accessToken.token}` });
        geocode({
            address: this.geoCodetext, authentication: authentication, countryCode: 'CA', region: 'QC',
            params: {
                f: 'json',
                location: this.map.getCenter().toArray().join(','),
                localtion: { 'xmin': -79.7741350219999, 'ymin': 44.9911431680001, 'xmax': -57.1054863999999, 'ymax': 62.5935914040001 },
                outFields: '*',
            }
        }).then((response) => {
            this.filtreEsriCodage = response.geoJson?.features as Partial<GeoJson>;
        }).catch((error) => { throw new Error(error); });
    }

    private getlegende() {
        this.ref = this.dialogService.open(LegendComponent, {
            header: 'Couches',
            modal: false,
            position: 'right',
            styleClass: 'legendeContent',
            data: {
                listeLayers: this.listeLayersInit
            }
        });
    }

    public onZoomProjet(projet: Projet): void {
        const pointsInspection: FeatureCollection = { features: [], type: 'FeatureCollection', };
        const pointsAudit: FeatureCollection = { features: [], type: 'FeatureCollection', };
        const anomalies: FeatureCollection = { features: [], type: 'FeatureCollection', };
        projet.pointInspections?.forEach((feature) => {
            const pointInspection: Feature = { type: 'Feature', geometry: JSON.parse(feature.geometrie), properties: [], };
            pointsInspection.features.push(pointInspection);
            feature.anomalies?.forEach(() => {
                const anomalie: Feature = { type: 'Feature', geometry: JSON.parse(feature.geometrie), properties: [], };
                anomalies.features.push(anomalie);
            });
        });
        const polygoneFeatures: FeatureCollection = JSON.parse(projet.geometrie as string);
        const bounds: BBox = bbox(polygoneFeatures);
        (this.mapService.map.getSource(MapLayersSources.POLYGONE_PROJET_HIGHLIGHTED) as mapboxgl.GeoJSONSource).setData(polygoneFeatures);
        (this.mapService.map.getSource(MapLayersSources.POINT_INSPECTION) as mapboxgl.GeoJSONSource).setData(pointsInspection);
        (this.mapService.map.getSource(MapLayersSources.POINT_AUDIT) as mapboxgl.GeoJSONSource).setData(pointsAudit);
        (this.mapService.map.getSource(MapLayersSources.ANOMALIE) as mapboxgl.GeoJSONSource).setData(anomalies);
        this.mapService.map.fitBounds(bounds as mapboxgl.LngLatBoundsLike, { padding: 3 });
        this.messageService.clear();
    }

    private isProjetInspectionDownloaded(projetInspection: ProjetCompletDto): boolean {
        return !!this.projetsInspectionDownloaded?.find(projet => projet.id === projetInspection?.id);
    }

    private isProjetAuditDownloaded(projetAudit: ProjetAuditDto): boolean {
        return !!this.projetsAuditDownloaded?.find(projet => projet.id === projetAudit?.id)
            && !!this.projetsInspectionDownloaded?.find(projet => projet.id === projetAudit.projetId);
    }

    private verifyProjetAndProjetAuditNotDownloaded(): boolean {
        return (!this.isProjetInspectionDownloaded(this.currentActiveProjetInspection)
            || (this.isAuditeur && !this.isProjetAuditDownloaded(this.currentActiveProjetAudit))
            || (!this.offlineService.isOfflineMode && !navigator.onLine)); // TODO NEEDED ??

    }

    private popupErreurProjetDownloaded(message: string) {
        return this.messageService.add(
            {
                severity: Severite.erreur,
                closable: true,
                summary: `Erreur`,
                detail: message,
                life: 5000
            });
    }

    private createPoteauPointAudit() {
        if (!this.projetsAuditDownloaded?.length || (!this.offlineService.isOfflineMode && !navigator.onLine)) {
            return this.popupErreurProjetDownloaded(`Veuillez sélectionner un projet de votre liste
            de projets et le télécharger avant de pouvoir ajouter un poteau.`);
        } else {
            if (!this.currentActiveProjetAudit) {
                this.openSelectProjetAuditDialog();
                this.ref.onClose.subscribe((selectedProjet: ProjetAuditDto) => {
                    this.store.dispatch(AuditActions.setCurrentActiveProjetAudit({ projetAudit: selectedProjet }));
                    this.store.dispatch(InspectionActions.setCurrentActiveProjetInspectionById({ projetInspectionId: selectedProjet.projetId }));
                    this.mapService.zoomProjetAudit(selectedProjet);
                });
            } else {
                if (!this.isProjetAuditDownloaded(this.currentActiveProjetAudit)) {
                    return this.popupErreurProjetDownloaded(`Pour ajouter un poteau au projet ` + this.currentActiveProjetAudit?.nom +
                        `, veuillez d’abord activer ce projet. Une fois le projet activé, vous pourrez ajouter le poteau souhaité.`);
                }
            }

            const clearFeatures: FeatureCollection = { features: [], type: 'FeatureCollection' };
            (this.mapService.map.getSource(MapLayersSources.POTEAU_SELECTED) as mapboxgl.GeoJSONSource).setData(clearFeatures);
            this.isCreatePoteauPointAudit = true;
            this.createMovePoteauPanelVisible = true;
            this.evaluateActionSheet();
        }
    }

    public createPointAudit(createPointAudit: PointAuditDto) {
        this.uiService.setDetailPoteauDialogOpened(false);
        this.createPointAuditData = createPointAudit;
        this.pointAuditNonConformeDialogVisible = true;
    }

    private initInfoPointInspection() {
        this.infoPointInspection.feature = {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': []
            },
            'properties': {},
        };
    }

    public movePoteau() {
        if (this.verifyProjetAndProjetAuditNotDownloaded()) {
            if (!this.mapPermissionsService.canEditPointInspection()) {
                return this.popupErreurProjetDownloaded(`Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
            }
        }

        if (!this.createPoteauInfo) {
            const lngLatPoint: mapboxgl.LngLatLike = {
                lng: (this.infoPointInspection.feature?.geometry as any).coordinates[0],
                lat: (this.infoPointInspection.feature?.geometry as any).coordinates[1],
            };
            this.map.setCenter(lngLatPoint);
        }
        this.createMovePoteauPanelVisible = true;
        this.evaluateActionSheet();
    }

    private initCreatePoteauSansAnomalie() {
        const featuresreset: FeatureCollection = { features: [], type: 'FeatureCollection' };
        (this.mapService.map.getSource(MapLayersSources.POTEAU_SELECTED) as mapboxgl.GeoJSONSource).setData(featuresreset);
        this.createPoteauSansAnomalie = true;
        this.createMovePoteauPanelVisible = true;
        this.evaluateActionSheet();
    }

    public resetSideBarPanel(): void {
        this.ignorePointPanelVisible = false;
        this.evaluateActionSheet();
    }

    public openIgnorePointPanel() {
        if (this.verifyProjetAndProjetAuditNotDownloaded()) {
            if (!this.mapPermissionsService.canEditPointInspection()) {
                return this.popupErreurProjetDownloaded(`Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
            }
        }

        this.markAsAuditeur = this.isAuditeur || (this.mapPermissionsService.roleIsMarkAsAuditeur() && this.isPointAuditSelected);
        this.ignorePointPanelVisible = true;
        this.evaluateActionSheet();
    }

    public onUpdateSansAnomalie(feature: any) {
        if (this.verifyProjetAndProjetAuditNotDownloaded()) {
            if (!this.mapPermissionsService.canEditPointInspection()) {
                return this.popupErreurProjetDownloaded(`Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
            }
        }

        if (feature.properties![PointInspectionProperties.statut].indexOf('anomalie') !== -1) {
            return this.messageService.add(
                {
                    severity: Severite.erreur,
                    closable: true,
                    summary: `Erreur`,
                    detail: `Impossible de faire 'OK', car l'inspection contient au moins une anomalie. Veuillez préalablement supprimer toutes ses anomalies.`,
                    life: 15000
                });
        }

        const updatedPointInspection: PointInspectionDto = {
            ...this.selectedPointInspection,
            statut: StatutPointInspection.sansAnomalie,
            justification: '',
            remarque: '',
            inspecteLe: feature.properties![PointInspectionProperties.inspecteLe] === 0 ? new Date().getTime() : feature.properties!['inspecteLe']
        };

        this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection: updatedPointInspection }));
    }

    public fermerImageViewer(): void {
        const modal = document.getElementById('capture-image_viewer');
        modal!.style.display = 'none';
    }

    public ouvrirImage(n: number): void {
        this.imageIndex += n;
        if (this.imageIndex < 0) {
            this.imageIndex = 0;
        };
        if (this.imageIndex > this.nombresImages) {
            this.imageIndex = this.nombresImages - 1;
        };
        this.mapService.ouvrirImage(this.mapService.photoListe[this.imageIndex].photo, this.mapService.photoListe[this.imageIndex].index);
    }

    public telechargerImage(): void {
        saveAs(this.currentImage.src, this.currentImage.title + `.jpeg`);
    }

    private initIntervalForDataExtraction(maxMinutes: number = 5) {
        this.counterIntervalForDataExtraction = 0;
        this.maxIterationForDataExtraction = Math.ceil((maxMinutes * 60 * 1000) / this.intervalBetweenCallCheck);
    }

    private cancelInterval(intervatToClear: NodeJS.Timer) {
        this, this.counterIntervalForDataExtraction = 0;
        this.maxIterationForDataExtraction = 1;
        clearInterval(intervatToClear);
    }

    private deepCancelInterval(intervalToClear: NodeJS.Timer) {
        this.cancelInterval(intervalToClear);
    }

    private effacerMarqueur() {
        try {
            if (this.marker) {
                this.marker.remove();
            }

            if (this.geocoderMarker) {
                this.geocoderMarker.remove();
            }
        } catch (error) {
            console.error(error);
        }
    }

    private destroyRapideSubscriptions() {
        this.rapideSubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async ngOnDestroy() {
        if (this.selectedPort) {
            await this.selectedPort.forget();
        }
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.destroyRapideSubscriptions();
    }

    public subscribeToGPSStates() {
        this.gpsIcon = this.el.nativeElement.querySelector('.gps-icon');

        combineLatest([
            this.gpsService.portconnectionState$,
            this.gpsService.state$
        ])
            .pipe(
                filter(([gpsConnectionState, gpsState]) =>
                    this.currentGpsPortConnectionState !== gpsConnectionState || this.currentGpsState !== gpsState
                ),
                takeUntil(this.destroyed)
            )
            .subscribe(([gpsConnectionState, gpsState]) => {
                this.currentGpsPortConnectionState = gpsConnectionState;
                this.currentGpsState = gpsState;

                if (gpsConnectionState === GpsPortConnectionState.CONNECTED) {
                    if (gpsState === GpsState.ONLINE) {
                        this.gpsIcon.classList.add('online');
                        this.gpsIcon.classList.remove('offline');

                    } else {
                        this.gpsIcon.classList.add('offline');
                        this.gpsIcon.classList.remove('online');
                    }
                } else {
                    this.gpsIcon.classList.remove('offline');
                    this.gpsIcon.classList.remove('online');
                    this.marker.remove();
                }
            });
    }

    private subscribeToGpsCoordinates() {
        this.gpsService.coordinates$
            .pipe(takeUntil(this.destroyed))
            .subscribe(coordinates => {
                if (coordinates) {
                    const mapBoxCoordinates = new mapboxgl.LngLat(
                        convertNumberToLongitude(coordinates.longitude, coordinates.directionEO),
                        convertNumberToLatitude(coordinates.latitude, coordinates.directionNS));

                    // Si il n'y a pas de coordonnées à la base, on zoom à la position de l'utilisateur
                    if (!this._coordinates) {
                        this.setMarker(mapBoxCoordinates, true);
                    } else {
                        this.setMarker(mapBoxCoordinates);
                    }

                    this._coordinates = coordinates;
                } else {
                    this._coordinates = null;
                    this.marker.remove();
                }
            });
    }

    private setMarker(coordonnees: mapboxgl.LngLatLike, zoom = false) {
        this.marker
            .setLngLat(coordonnees)
            .addTo(this.map);

        if (zoom) {
            this.map.setZoom(18);
            this.map.setCenter(coordonnees);
        }
    }

    private centerToGPSCoordinates() {
        if (this.currentGpsPortConnectionState === GpsPortConnectionState.CONNECTED && this._coordinates) {
            const mapBoxCoordinates = new mapboxgl.LngLat(
                convertNumberToLongitude(this._coordinates.longitude, this._coordinates.directionEO),
                convertNumberToLatitude(this._coordinates.latitude, this._coordinates.directionNS));
            this.map.setZoom(18);
            this.map.setCenter(mapBoxCoordinates);
        }
    }

    public subscribeToUpdateInfoPointsInspections() {
        this.pointsInspections$.subscribe((pointsInspection) => {
            if (this.infoPointInspection.feature?.properties.hasOwnProperty('inspectionId')) {
                const infoPointInspectionId = this.infoPointInspection.feature.properties[PointInspectionProperties.inspectionId];
                const foundRawPointsInspection = pointsInspection.find(pointInspection => pointInspection.id === infoPointInspectionId);
                if (foundRawPointsInspection) {
                    const parseFeatures = generatePointsInspectionFeatures([foundRawPointsInspection]);
                    this.mapService.setupPointInspection((parseFeatures.features[0]) as mapboxgl.MapboxGeoJSONFeature);
                    this.setupPointInspectionInfo({ openSideBar: this.infoPointInspection.openSideBar, feature: parseFeatures.features[0] as FeatureHelper });

                    if (this.isPointAuditSelected) {
                        this.mapService.closeActionSheet();
                    }
                }
            }
        });
    }
}
