<div *ngIf="form" class="audit-create-dialog">
    <form [formGroup]="form">
        <div class="audit-create-dialog__content">
            <div class="audit-create-dialog__content__seuil">
                <app-form-field class="app-form-field app-form-field--error-absolute"
                    [control]="form.controls.seuilPourcentage" label="Taux d'échantillonage :">
                    <input pInputText type="text" formControlName="seuilPourcentage"
                        [readOnly]="form.controls.seuilEntier.value">
                    <p>% ou</p>
                </app-form-field>
                <app-form-field class="app-form-field app-form-field--error-absolute"
                    [control]="form.controls.seuilEntier">
                    <input pInputText type="text" formControlName="seuilEntier"
                        [readOnly]="form.controls.seuilPourcentage.value">
                    <p>poteaux</p>
                </app-form-field>
            </div>
            <app-form-field class="app-form-field" [control]="form.controls.nom" label="Nom du projet d'audit :">
                <input pInputText readonly type="text" formControlName="nom">
            </app-form-field>
        </div>
    </form>
</div>
<div class="audit-create-dialog__footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text projet-button"
        (click)="close()"></button>
    <button pButton pRipple label="Sauvegarder le projet" icon="pi pi-save" class="p-button-text" (click)="onSave()"
        [disabled]="form.invalid || form.pristine || saveLoading"></button>
</div>
<div *ngIf="saveLoading" class="audit-create-dialog__footer__loading">
    <app-loading-spinner [size]="20"></app-loading-spinner>
</div>
