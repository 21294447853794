import { TableColumn } from '../../../shared/models/table-column.model';

export const fullInspectionsColumn: TableColumn[] = [
    { field: 'nom', header: 'Nom du projet', group: 'inspection', toggleable: true, showByDefault: true },
    { field: 'type', header: 'Type de projet', group: 'inspection', toggleable: true, showByDefault: true, filterType: 'dropdown' },
    { field: 'statutProjetName', header: 'Statut', group: 'inspection', toggleable: true, showByDefault: true, filterType: 'multiSelect', isStaticFilter: true },
    { field: 'statutModifieLe', header: 'Statut modifié le', group: 'inspection', width: '115px', toggleable: true, isDate: true, showByDefault: true },
    { field: 'statutModifiePar', header: 'Statut modifié par', group: 'inspection', toggleable: true },
    { field: 'numeroOrdreDeTravail', header: `Numéro d'ordre de travail`, group: 'inspection', toggleable: true, showByDefault: true },
    { field: 'creeLe', header: 'Créé le', group: 'inspection', width: '115px', toggleable: true, isDate: true, showByDefault: true },
    { field: 'creePar', header: 'Créé par', group: 'inspection', toggleable: true },
    { field: 'firmeName', header: 'Firme', group: 'inspection', toggleable: true, showByDefault: true, filterType: 'multiSelect' },
    { field: 'territoire', header: 'Territoire', group: 'inspection', width: '125px', toggleable: true, showByDefault: true, filterType: 'dropdown' },
    { field: 'inspectionAnnee', header: 'Année', group: 'inspection', width: '125px', toggleable: true, showByDefault: true, filterType: 'dropdown' },
    { field: 'inspectionDateDeDebut', header: 'Début inspection', width: '115px', group: 'inspection', toggleable: true, isDate: true },
    { field: 'inspectionDateDeFin', header: 'Fin inspection', width: '115px', group: 'inspection', toggleable: true, isDate: true },
    { field: 'assigneA', header: 'Assigné insp.', group: 'inspection', toggleable: true, showByDefault: true },
    { field: 'assigneLe', header: 'Assigné insp. le', group: 'inspection', width: '115px', toggleable: true, isDate: true, showByDefault: true },
    { field: 'assignePar', header: 'Assigné insp. par', group: 'inspection', toggleable: true },
    { field: 'assigneAQA', header: 'Assigné AQ', group: 'inspection', toggleable: true, showByDefault: true },
    { field: 'assigneAQLe', header: 'Assigné AQ le', group: 'inspection', width: '115px', toggleable: true, isDate: true },
    { field: 'assigneAQPar', header: 'Assigné AQ par', group: 'inspection', toggleable: true },
    { field: 'correctionA', header: 'Asssigné Corect.  ', group: 'inspection', toggleable: true },
    { field: 'correctionLe', header: 'Assigné correct. le', group: 'inspection', width: '115px', toggleable: true, isDate: true },
    { field: 'correctionPar', header: 'Assigné correct. par', group: 'inspection', toggleable: true },
    { field: 'justificatif', header: 'Justificatif', group: 'inspection', toggleable: true },
    { field: 'nombreTotalPoteaux', header: 'Nb p. total', group: 'inspection', toggleable: true, showByDefault: true, inputFilterDataType: 'number' },
    { field: 'nombrePoteauxInspectes', header: 'Nb p. / inspecté', group: 'inspection', toggleable: true, showByDefault: true, inputFilterDataType: 'number' },
    { field: 'nombrePoteauxNonInspectes', header: 'Nb p. / non inspecté', group: 'inspection', toggleable: true, inputFilterDataType: 'number' },
    { field: 'nombrePoteauxIgnores', header: 'Nb p. / ignoré', group: 'inspection', toggleable: true, inputFilterDataType: 'number' },
    { field: 'nombrePoteauxAvecAnomalie', header: 'Nb p. / anomalie', group: 'inspection', toggleable: true, inputFilterDataType: 'number' },
    { field: 'nombreAnomalies', header: 'Nb anomalie total', group: 'inspection', toggleable: true, inputFilterDataType: 'number' },
    { field: 'typeReseau', header: 'Type réseau', group: 'inspection', toggleable: true, filterType: 'dropdown' },
    { field: 'dateTransfertSap', header: 'Date Transfert SAP', group: 'inspection', width: '115px', toggleable: true, isDate: true },
    { field: 'remarque', header: 'Remarque', group: 'inspection', toggleable: true },
];

