import { NgModule } from '@angular/core';
import { RechercheModule } from '../features/recherche/recherche.module';
import { SharedModule } from '../shared/shared.module';
import { SharedPrimeNgModule } from '../shared/shared-primeng.module';
import { IgnorePointPanelComponent } from './components/ignore-point-panel/ignore-point-panel.component';
import { CreateMovePoteauPanelComponent } from './components/poteau/create-move-poteau-panel/create-move-poteau-panel.component';
import { PoteauDetailsComponent } from './components/poteau/poteau-details/poteau-details.component';
import { MapComponent } from './map.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { SidebarModule } from 'primeng/sidebar';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogService } from 'primeng/dynamicdialog';
import { InfoPointInspectionComponent } from './components/pop-up-info/info-point-inspection/info-point-inspection.component';
import { PopUpInfoComponent } from './components/pop-up-info/pop-up-info.component';
import { PopUpInfoAccordionComponent } from './components/pop-up-info/components/pop-up-info-accordion/pop-up-info-accordion.component';
import { PopUpInfoAnomalieComponent } from './components/pop-up-info/components/pop-up-info-anomalie/pop-up-info-anomalie.component';
import { PopUpInfoEquipementsMineursComponent } from './components/pop-up-info/components/pop-up-info-equipements-mineurs/pop-up-info-equipements-mineurs.component';
import { PopUpInfoHeaderComponent } from './components/pop-up-info/components/pop-up-info-header/pop-up-info-header.component';
import { InfoProjetComponent } from './components/pop-up-info/info-projet/info-projet.component';
import { InfoProjetAuditComponent } from './components/pop-up-info/info-projet-audit/info-projet-audit.component';
import { InfoPointAuditComponent } from './components/pop-up-info/info-point-audit/info-point-audit.component';
import { PopUpInfoPropertiesComponent } from './components/pop-up-info/components/pop-up-info-properties/pop-up-info-properties.component';
import { PopUpInfoAnomalieAuditComponent } from './components/pop-up-info/components/pop-up-info-anomalie-audit/pop-up-info-anomalie-audit.component';
import { AuditModule } from '../features/audit/audit.module';
import { ProjetsModule } from '../features/projets/projets.module';
import { ActionSheetComponent } from './components/action-sheet/action-sheet.component';
import { SelectProjetDialogComponent } from './components/poteau/create-move-poteau-panel/select-projet-dialog/select-projet-dialog.component';
import { SelectProjetAuditDialogComponent } from './components/poteau/create-move-poteau-panel/select-projet-audit-dialog/select-projet-audit-dialog.component';
import { LegendComponent } from './components/legend/legend.component';

@NgModule({
    declarations: [
        MapComponent,
        LegendComponent,
        PoteauDetailsComponent,
        IgnorePointPanelComponent,
        CreateMovePoteauPanelComponent,
        InfoProjetComponent,
        InfoProjetAuditComponent,
        InfoPointAuditComponent,
        InfoPointInspectionComponent,
        PopUpInfoComponent,
        PopUpInfoAccordionComponent,
        PopUpInfoAnomalieComponent,
        PopUpInfoEquipementsMineursComponent,
        PopUpInfoHeaderComponent,
        PopUpInfoPropertiesComponent,
        PopUpInfoAnomalieAuditComponent,
        ActionSheetComponent,
        SelectProjetDialogComponent,
        SelectProjetAuditDialogComponent
    ],
    imports: [
        AuditModule,
        ProjetsModule,
        RechercheModule,
        SharedModule,
        SharedPrimeNgModule,
        ReactiveFormsModule,
        CommonModule,
        AutoCompleteModule,
        CheckboxModule,
        ConfirmDialogModule,
        FileUploadModule,
        DialogModule,
        DropdownModule,
        InputMaskModule,
        SidebarModule,
        ToastModule,
        TreeModule,
        TableModule
    ],
    providers: [
        DialogService
    ]
})
export class MapModule { }
