import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { TreeNode } from 'primeng/api';
import { LegendeGenerator } from './legende-generator';
import { StyleLayer } from '../../models/style-layer.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MapService } from '../../services/map.service';


@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent extends BaseComponent implements OnInit {
    public legendeOptions: TreeNode[];
    private legende: LegendeGenerator;
    private listeLayers: StyleLayer[] = this.config.data.listeLayers;

    constructor(
        private mapService: MapService,
        private config: DynamicDialogConfig,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getLegendeInit();
    }

    public getLegendeInit() {
        this.legende = new LegendeGenerator(this.listeLayers);
        this.legendeOptions = this.legende.getLegende();
        this.legendeOptions.forEach(element => {
            element.children!.forEach(elementChildren => {
                this.changeLayerVisibilite(elementChildren);
            });
        });
    }

    public changeLayerVisibilite(nomLayer: TreeNode): void {
        if (nomLayer.data.visibilite) {
            this.map.setLayoutProperty(nomLayer.label!, 'visibility', 'visible');
        } else {
            this.map.setLayoutProperty(nomLayer.label!, 'visibility', 'none');
        }
    }

    private get map(): mapboxgl.Map {
        return this.mapService.map;
    }
}
