import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { Observable, filter, map, takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { rapportErreurSyncDialogColumn } from '../../models/rapport-erreur-synchronisation-dialog.column';
import { SyncErrors } from '../../models/sync-errors.model';
import { BBox, bbox, Feature } from '@turf/turf';
import { GeoJSONSource, LngLatBoundsLike } from 'mapbox-gl';
import { saveFile } from '../../../../shared/utils';
import { MapLayersSources } from '../../../../map/models/map-layers-sources.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import * as SyncActions from '../../../../features/synchronisation/state/synchronisation.actions';
import * as AuditActions from '../../../../features/audit/state/audit.actions';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { SynchronisationService } from '../../services/synchronisation.service';
import { MapService } from '../../../../map/services/map.service';
import { ProjetAuditDto } from '../../../../core/api/client/models';
import { Requests } from '../../models/requests.model';
import {
    getSyncErrors,
    getSyncErrorsLoading,
    getUploadRequestsSuccess
} from '../../state/synchronisation.selectors';

@Component({
    selector: 'app-rapport-erreur-synchronisation-dialog',
    templateUrl: './rapport-erreur-synchronisation-dialog.component.html',
    styleUrls: ['./rapport-erreur-synchronisation-dialog.component.scss']
})

export class RapportErreurSynchronisationDialogComponent extends BaseComponent implements OnInit {

    public columns = rapportErreurSyncDialogColumn;
    public syncErrors$: Observable<SyncErrors[] | null> = this.store.select(getSyncErrors);
    public syncErrorsLoading$: Observable<boolean> = this.store.select(getSyncErrorsLoading);
    public failedRequestUploadDialogVisible = false;
    public isFailedRequestsUploaded = false;
    public disableClearCacheButton = false;
    public requests: Requests[] = this.config.data.requests;
    private isAuditeur: boolean = this.config.data.isAuditeur;
    private projetsAudit: ProjetAuditDto[] = this.config.data.projetsAudit;
    private projetId: string = this.config.data.projetId;

    constructor(
        public ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private store: Store<State>,
        private mapService: MapService,
        private messageService: MessageService,
        private syncService: SynchronisationService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.projetId) {
            this.subscribeToSyncErrors();
        }
        this.subscribeToUploadFailedRequestsSuccess();
    }

    private subscribeToSyncErrors() {
        this.syncErrors$ = this.store.select(getSyncErrors)
            .pipe(
                filter(syncErrors => !!syncErrors),
                map(syncErrors => syncErrors.filter(syncError =>
                    this.isAuditeur ? syncError.pointAudit.projetAuditId === this.projetId
                        : syncError.pointInspection.projetId === this.projetId
                )),
                takeUntil(this.destroyed)
            );
    }

    private subscribeToUploadFailedRequestsSuccess() {
        this.store.select(getUploadRequestsSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            ).subscribe((success) => {
                this.isFailedRequestsUploaded = success;
                this.store.dispatch(SyncActions.deleteSyncErrors());
                this.clearCache();
            });
    }

    public saveFileAndUploadFailedRequestsToS3() {

        saveFile(JSON.stringify(this.requests), 'Requêtes de synchronisation échouées', 'application/json', '.json');

        const dateSauvegarde = new Date().getTime();

        this.requests.forEach((request, index) => {
            this.store.dispatch(SyncActions.uploadRequests(
                {
                    request: [request],
                    numeroRequete: index + 1,
                    totalRequete: this.requests.length,
                    dateSauvegarde: dateSauvegarde
                }
            ));
        });

        this.failedRequestUploadDialogVisible = true;
    }

    public clearCacheConfirm() {
        this.clearCache();
        this.messageService.add({
            severity: 'success',
            closable: true,
            summary: `Gestion de la cache`,
            detail: `La cache a été vidée avec succès.`
        });
    }

    private clearCache() {
        this.store.dispatch(SyncActions.deleteRequests());
        this.updateProjetsToIndexedDb();
        this.disableClearCacheButton = true;
    }

    private updateProjetsToIndexedDb() {
        this.syncService.updateProjetsInspectionToIndexedDb();
        if (this.isAuditeur) {
            this.syncService.updateProjetsAuditToIndexedDb();
        }
    }

    public onCloseFailedRequestUploadDialog() {
        this.failedRequestUploadDialogVisible = false;
        this.ref.close();
    }

    public zoomPoint(syncError: SyncErrors) {
        let projetInspectionId;

        if (this.isAuditeur) {
            projetInspectionId = this.projetsAudit.find(projet => projet.id === syncError.pointAudit?.projetAuditId)?.projetId;
            this.store.dispatch(AuditActions.setCurrentActiveProjetAuditById({ projetAuditId: syncError.pointAudit?.projetAuditId }));
        } else {
            projetInspectionId = syncError.pointInspection?.projetId;
        }

        this.store.dispatch(InspectionActions.setCurrentActiveProjetInspectionById({ projetInspectionId }));

        const feature: Feature = { type: 'Feature', properties: {}, geometry: JSON.parse(syncError.geometrie) };
        const bounds: BBox = bbox(feature);
        const featureCollection = { features: [feature], type: 'FeatureCollection', bbox: bounds };
        if (this.isAuditeur) {
            (this.mapService.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(featureCollection as any);
        } else {
            (this.mapService.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(featureCollection as any);
        }
        this.mapService.map.fitBounds(bounds as LngLatBoundsLike, { zoom: 18, padding: 3 });
        this.ref.close();
    }
}
