import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as ProjetsActions from '../../../state/projets.actions';
import { ProjetBaseDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { ConfirmationService } from 'primeng/api';
import { ProjetsActionBaseDialog } from '../projets-action-base-dialog';

@Component({
    selector: 'app-modify-projet-inspection-dialog',
    templateUrl: './modify-projet-inspection-dialog.component.html',
    styleUrls: ['./modify-projet-inspection-dialog.component.scss']
})
export class ModifyProjetInspectionDialogComponent extends ProjetsActionBaseDialog {

    public form: FormGroup;
    private _projet: ProjetCompletDto;

    private set projet(value: ProjetCompletDto) {
        if (value) {
            this._projet = value;
            this.form.reset(this.projet);
        }
    }
    get projet(): ProjetCompletDto {
        return this._projet;
    }

    constructor(
        private store: Store,
        private fb: FormBuilder,
        protected ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        protected confirmationService: ConfirmationService,
    ) {
        super(ref, confirmationService);
        this.initForm();
        this.projet = this.config.data?.selectedProjet ?? undefined;
    }

    private initForm() {
        this.form = this.fb.group({
            numeroOrdreDeTravail: ['', Validators.maxLength(8)],
            inspectionAnnee: [''],
        });
    }

    public onSave() {
        if (this.form.valid) {
            const updateData: ProjetBaseDto = this.form.getRawValue();
            this.store.dispatch(ProjetsActions.updateProjetInspection({ projetInspectionId: this.projet?.id, updateData }));
            this.closeDialog();
        }
    }
}
