import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ProjectsActions from '../../../state/projets.actions';
import { State } from '../../../state/projets.state';
import { getTaxonomiePourGroupe } from '../../../../../core/store/selectors/taxonomie.selectors';
import { TaxonomieGroupeValue } from '../../../../pilotage/models/taxonomie-groupe.enum';
import { ProjetAuditDto, PatchProjetAuditDto, ActionProjetAudit } from '../../../../../core/api/client/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjetsActionBaseDialog } from '../projets-action-base-dialog';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-assign-projet-audit-dialog',
    templateUrl: './assign-projet-audit-dialog.component.html',
    styleUrls: ['./assign-projet-audit-dialog.component.scss']
})
export class AssignProjetAuditDialogComponent extends ProjetsActionBaseDialog implements OnInit {
    public form: FormGroup;
    public auditeursList$: Observable<string[]>;

    private selectedProjetAudit: ProjetAuditDto;

    constructor(
        private store: Store<State>,
        private config: DynamicDialogConfig,
        private fb: FormBuilder,
        protected ref: DynamicDialogRef,
        protected confirmationService: ConfirmationService,
    ) {
        super(ref, confirmationService);
        this.initForm();
        this.selectedProjetAudit = this.config.data?.projetAudit ?? null;
    }

    ngOnInit() {
        this.auditeursList$ = this.store.select(getTaxonomiePourGroupe(TaxonomieGroupeValue.AUDITEURS));
    }

    private initForm(): void {
        this.form = this.fb.group({
            auditeurSelected: [null, Validators.required]
        });
    }

    public onSave() {
        if (this.selectedProjetAudit && this.form.valid) {
            const data: PatchProjetAuditDto = { action: ActionProjetAudit.assigner, assignea: this.form.value.auditeurSelected };
            this.store.dispatch(ProjectsActions.assignProjetAudit({ projetAuditId: this.selectedProjetAudit.id!, assignData: data }));

            this.closeDialog();
        }
    }
}
