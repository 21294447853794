import { Injectable/*, OnDestroy*/ } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { /*Subject,*/ takeUntil } from 'rxjs';

import { ActionProjetAudit, PatchProjetAuditDto, ProjetAuditDto } from '../../../core/api/client/models';
import { getIsAppOnline } from '../../../state/shared/shared.selectors';
import { MapService } from '../../../map/services/map.service';
import {
    canApproveProjetAudit,
    canAssignProjetAudit,
    canCancelProjetAudit,
    canExtractAuditHistory,
    canRejectProjetAudit,
    canZoomProjetAudit
} from '../../../core/store/selectors/user.selectors';
import {
    getApproveProjetAuditPermission,
    getAssignProjetAuditPermission,
    getCancelProjetAuditPermission,
    getProjetHistoriqueAuditPermission,
    getRejectProjetAuditPermission
} from '../../../features/audit/utils/audit-permissions.utils';
import * as ProjetsActions from '../state/projets.actions';
import { AssignProjetAuditDialogComponent } from '../components/dialog/assign-projet-audit-dialog/assign-projet-audit-dialog.component';
import { BaseComponent } from '../../../shared/components/abstract-base-component';

@Injectable()
export class ProjetAuditContextualMenu extends BaseComponent {
    private isAppOffline = true;

    private canZoomProjetsAudit = false;
    private canApproveProjetAudit = false;
    private canAssignProjetAudit = false;
    private canCancelProjetAudit = false;
    private canRejectProjetAudit = false;
    private canExtractProjetAuditHistory = false;

    private disableApproveProjetAudit = false;
    private disableAssignProjetAudit = false;
    private disableCancelProjetAudit = false;
    private disableRejectProjetAudit = false;
    private disableHistoriqueAudit = false;

    constructor(
        private store: Store,
        private confirmationService: ConfirmationService,
        private ref: DynamicDialogRef,
        private mapService: MapService,
        private dialogService: DialogService,
    ) { super(); }

    public isAuditHistoriqueDisabled(): boolean {
        return this.disableHistoriqueAudit;
    }

    public subscribeToPermissions() {
        this.subscribeToIsAppOffline();
        this.subscribeToCanZoomProjetAudit();
        this.subscribeToCanApproveProjetAudit();
        this.subscribeToCanAssignProjetAudit();
        this.subscribeToCanCancelProjetAudit();
        this.subscribeToCanRejectProjetAudit();
        this.subscribeToCanExtractProjetAuditHistory();
    }

    public setMenuItemStatusForAudit(selectedProjetAudit: ProjetAuditDto) {
        this.setDisableAssignProjetAudit(selectedProjetAudit);
        this.setDisableCancelProjetAudit(selectedProjetAudit);
        this.setDisableRejectProjetAudit(selectedProjetAudit);
        this.setDisableApproveProjetAudit(selectedProjetAudit);
        this.setDisableHistoriqueAudit(selectedProjetAudit);
    }

    public getZoomSurAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? 'Zoom sur',
            icon: 'pi pi-fw pi-search-plus',
            visible: this.canZoomProjetsAudit,
            disabled: false,
            command: () => {
                this.ref.close(true);
                this.mapService.zoomProjetAudit(selectedProjetAudit);
            }
        };
    }

    public getApprouverAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? 'Approuver',
            icon: 'fas fa-flag-checkered',
            visible: this.canApproveProjetAudit,
            disabled: this.disableApproveProjetAudit,
            command: () => this.approveProjetAuditConfirm(selectedProjetAudit)
        };
    }

    public getAssignerAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? 'Assigner',
            icon: 'pi pi-fw pi-check-square',
            visible: this.canAssignProjetAudit,
            disabled: this.disableAssignProjetAudit,
            command: () => this.assignProjetAuditConfirm(selectedProjetAudit)
        };
    }

    public getAnnulerAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? 'Annuler',
            icon: 'pi pi-times',
            visible: this.canCancelProjetAudit,
            disabled: this.disableCancelProjetAudit,
            command: () => this.cancelProjetAuditConfirm(selectedProjetAudit)
        };
    }

    public getRejeterAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? 'Rejeter',
            icon: 'pi pi-ban',
            visible: this.canRejectProjetAudit,
            disabled: this.disableRejectProjetAudit,
            command: () => this.rejectProjetAuditConfirm(selectedProjetAudit)
        };
    }

    public getExtraireHistoriqueAuditMenuItem(selectedProjetAudit: ProjetAuditDto, title?: string): MenuItem {
        return {
            label: title ?? `Extraire l'historique`,
            icon: 'pi pi-fw pi-file',
            visible: this.canExtractProjetAuditHistory,
            disabled: this.disableHistoriqueAudit,
            command: () => this.store.dispatch(ProjetsActions.loadProjetAuditHistory({
                projetAuditId: selectedProjetAudit.id!
            }))
        };
    }

    public getFullAuditMenu(selectedProjetAudit: ProjetAuditDto): MenuItem[] {
        return [
            this.getZoomSurAuditMenuItem(selectedProjetAudit),
            this.getApprouverAuditMenuItem(selectedProjetAudit),
            this.getAssignerAuditMenuItem(selectedProjetAudit),
            this.getAnnulerAuditMenuItem(selectedProjetAudit),
            this.getRejeterAuditMenuItem(selectedProjetAudit),
            this.getExtraireHistoriqueAuditMenuItem(selectedProjetAudit)
        ];
    }

    public getAuditMenuAsSubmenu(selectedProjetAudit: ProjetAuditDto): MenuItem[] {
        return [
            this.getApprouverAuditMenuItem(selectedProjetAudit),
            this.getAssignerAuditMenuItem(selectedProjetAudit),
            this.getAnnulerAuditMenuItem(selectedProjetAudit),
            this.getRejeterAuditMenuItem(selectedProjetAudit),
        ];
    }

    /////////////////////////////////////
    //          Subscriptions          //
    /////////////////////////////////////

    private subscribeToIsAppOffline() {
        this.store.select(getIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isAppOffline = !_isAppOnline;
        });
    }

    private subscribeToCanZoomProjetAudit() {
        this.store.select(canZoomProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canZoomAudit => {
            this.canZoomProjetsAudit = _canZoomAudit;
        });
    }

    private subscribeToCanApproveProjetAudit() {
        this.store.select(canApproveProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canApproveProjetAudit => {
            this.canApproveProjetAudit = _canApproveProjetAudit;
        });
    }

    private subscribeToCanAssignProjetAudit() {
        this.store.select(canAssignProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canAssignProjetAudit => {
            this.canAssignProjetAudit = _canAssignProjetAudit;
        });
    }

    private subscribeToCanCancelProjetAudit() {
        this.store.select(canCancelProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCancelProjetAudit => {
            this.canCancelProjetAudit = _canCancelProjetAudit;
        });
    }

    private subscribeToCanRejectProjetAudit() {
        this.store.select(canRejectProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canRejectProjetAudit => {
            this.canRejectProjetAudit = _canRejectProjetAudit;
        });
    }

    private subscribeToCanExtractProjetAuditHistory() {
        this.store.select(canExtractAuditHistory).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canExtractProjetAuditHistory => {
            this.canExtractProjetAuditHistory = _canExtractProjetAuditHistory;
        });
    }

    //////////////////////////////////////////
    //          Disabled functions          //
    //////////////////////////////////////////
    private setDisableAssignProjetAudit(selectedProjetAudit: ProjetAuditDto) {
        if (selectedProjetAudit) {
            this.disableAssignProjetAudit = !getAssignProjetAuditPermission(selectedProjetAudit);
        } else {
            this.disableAssignProjetAudit = true;
        }
    }

    private setDisableCancelProjetAudit(selectedProjetAudit: ProjetAuditDto) {
        if (selectedProjetAudit) {
            this.disableCancelProjetAudit = !getCancelProjetAuditPermission(selectedProjetAudit);
        } else {
            this.disableCancelProjetAudit = true;
        }
    }

    private setDisableRejectProjetAudit(selectedProjetAudit: ProjetAuditDto) {
        if (selectedProjetAudit) {
            this.disableRejectProjetAudit = !getRejectProjetAuditPermission(selectedProjetAudit);
        } else {
            this.disableRejectProjetAudit = true;
        }
    }

    private setDisableApproveProjetAudit(selectedProjetAudit: ProjetAuditDto) {
        if (selectedProjetAudit) {
            this.disableApproveProjetAudit = !getApproveProjetAuditPermission(selectedProjetAudit, this.isAppOffline);
        } else {
            this.disableApproveProjetAudit = true;
        }
    }

    private setDisableHistoriqueAudit(selectedProjetAudit?: ProjetAuditDto | undefined) {
        if (selectedProjetAudit) {
            this.disableHistoriqueAudit = !getProjetHistoriqueAuditPermission(selectedProjetAudit);
        } else {
            this.disableHistoriqueAudit = true;
        }
    }

    /////////////////////////////////////////
    //          Actions functions          //
    /////////////////////////////////////////
    private approveProjetAuditConfirm(projetAudit: ProjetAuditDto | undefined) {
        if (projetAudit) {
            this.confirmationService.confirm({
                header: `Confirmer l'approbation`,
                message: `Voulez-vous vraiment approuver le projet d'audit ?`,
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Non',
                acceptLabel: 'Oui',
                accept: () => {
                    const approveData: PatchProjetAuditDto = { action: ActionProjetAudit.approuver };
                    this.store.dispatch(ProjetsActions.approveProjetAudit({ projetAuditId: projetAudit.id!, approveData }));
                },
                key: 'global',
            });
        }
    }

    private assignProjetAuditConfirm(projetAudit: ProjetAuditDto | undefined) {
        if (projetAudit) {
            if (projetAudit.assigneA) {
                this.confirmationService.confirm({
                    header: `Confirmer l'assignation`,
                    message: `Voulez-vous vraiment assigner le projet de nouveau ?<br><br>
                    La réassignation entrainera la perte des données non synchronisées.`,
                    icon: 'pi pi-exclamation-triangle',
                    rejectLabel: 'Non',
                    acceptLabel: 'Oui',
                    accept: () => {
                        this.openAssignProjetAuditDialog(projetAudit);
                    },
                    key: 'global',
                });
            } else {
                this.openAssignProjetAuditDialog(projetAudit);
            }
        }
    }

    private cancelProjetAuditConfirm(projetAudit: ProjetAuditDto | undefined) {
        if (projetAudit) {
            this.confirmationService.confirm({
                header: `Confirmer l'annulation`,
                message: `Voulez-vous vraiment annuler le projet d'audit ?`,
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Non',
                acceptLabel: 'Oui',
                accept: () => {
                    const cancelData: PatchProjetAuditDto = { action: ActionProjetAudit.annuler };
                    this.store.dispatch(ProjetsActions.cancelProjetAudit({ projetAuditId: projetAudit.id!, cancelData }));
                },
                key: 'global',
            });
        }
    }

    private rejectProjetAuditConfirm(projetAudit: ProjetAuditDto | undefined) {
        if (projetAudit) {
            this.confirmationService.confirm({
                header: `Confirmer le rejet`,
                message: `Voulez-vous vraiment rejeter le projet d'audit ?`,
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Non',
                acceptLabel: 'Oui',
                accept: () => {
                    const rejectData: PatchProjetAuditDto = { action: ActionProjetAudit.rejeter };
                    this.store.dispatch(ProjetsActions.rejectProjetAudit({ projetAuditId: projetAudit.id!, rejectData }));
                },
                key: 'global',
            });
        }
    }

    private openAssignProjetAuditDialog(projetAudit: ProjetAuditDto) {
        this.ref = this.dialogService.open(AssignProjetAuditDialogComponent, {
            header: `Assigner un auditeur`,
            modal: true,
            draggable: false,
            data: {
                projetAudit: projetAudit
            }
        });
    }
}
