import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PointInspectionProperties } from '../../../../enums/point-inspection-properties.enum';
import { Observable, takeUntil, tap } from 'rxjs';
import { State } from '../../../../state/app.state';
import { getProjetInspectionById } from '../../../../features/inspection/state/inspection.selectors';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { omit, flatMap } from 'lodash';
import { EquipementMineur } from '../../../../features/anomalie/models/equipement-mineur.model';
import { Photo } from '../../../../shared/models/photo.model';
import { StatutPointInspection, statutPointInspectionMap } from '../../../../features/inspection/models/statut-point-inspection.enum';
import { proprietaireMap } from '../../../../enums/proprietaire';
import { usageMap } from '../../../../enums/usage';
import { classeMap } from '../../../../enums/classe';
import { inclinaisonMap } from '../../../../enums/inclinaison';
import { motifIgnoreMap } from '../../../../shared/enums/motif-ignore.enum';
import { PhotoService } from '../../../../services/photo.service';
import { AnomalieBaseDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { getIsAppOnline } from '../../../../state/shared/shared.selectors';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { MapPermissionsService } from '../../../services/map-permissions.service';
import { dateTimeChange } from '../../../../shared/utils';

@Component({
    selector: 'app-info-point-inspection',
    templateUrl: './info-point-inspection.component.html',
    styleUrls: ['./info-point-inspection.component.scss']
})
export class InfoPointInspectionComponent extends BaseComponent implements OnChanges {
    public get pointInspectionProperties() {
        return PointInspectionProperties;
    }

    public subTitle: string = `Point d'inspection`;
    public projet$: Observable<ProjetCompletDto | null>;
    public codeBarres: string = '';
    public projetName: string = '';
    public verticalProperties: string[] = [];

    public currentFeature: mapboxgl.MapboxGeoJSONFeature;
    public data: { [name: string]: any } = {};
    public currentProjet: ProjetCompletDto | null = null;
    public anomalies: AnomalieBaseDto[] = [];
    public photosPointInspection: Photo[] = [];
    public numberOfPhotos: number = 0;
    public equipementsMineurs: EquipementMineur;

    public imagesPointInspection: HTMLImageElement[] = [];
    public userGroups: string[] = [];
    public isOffline = true;
    public offlineMessage = 'Connectez-vous à internet pour modifier';
    public showEditInspection = false;

    @Input() feature: mapboxgl.MapboxGeoJSONFeature;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>,
        private photoService: PhotoService,
        private mapPermissionsService: MapPermissionsService,
    ) {
        super();
        this.subscribeToIsAppOffline();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.feature.currentValue) {
            this.currentFeature = changes.feature.currentValue;
            this.setupComponent();
        }
    }

    public close() {
        this.closed.emit({ closed: true });
        this.ngOnDestroy();
    }

    public editInspection() {
        this.closed.emit({
            closed: true,
            pointInspectionId: this.currentFeature.properties[PointInspectionProperties.inspectionId],
            pointInspectionFeature: this.currentFeature
        });
        this.ngOnDestroy();
    }

    private mappingData(property: any, value: any): string {
        switch (property) {
            case PointInspectionProperties.statut: return statutPointInspectionMap.get(value);
            case PointInspectionProperties.proprietaire: return proprietaireMap.get(value);
            case PointInspectionProperties.usage: return usageMap.get(value);
            case PointInspectionProperties.montage: return (value as string).toUpperCase();
            case PointInspectionProperties.classe: return classeMap.get(value);
            case PointInspectionProperties.inclinaison: return inclinaisonMap.get(value);
            case PointInspectionProperties.justification: return motifIgnoreMap.get(value);
            case PointInspectionProperties.indEquipementMajeur: return value ? 'Oui' : value === undefined || value === null ? '' : 'Non';
            case PointInspectionProperties.modifieLe: return dateTimeChange(value.toString(), 'dd/MM/YYYY');
            default: return value;
        }
    }

    private setupCodeBarres() {
        this.codeBarres = this.currentFeature.properties[PointInspectionProperties.codeABarres] ?? 'Code à barres inconnu';
    }

    private getProjet() {
        if (this.currentFeature.properties[PointInspectionProperties.projetId]) {
            this.projet$ = this.store.pipe(
                select(getProjetInspectionById(this.currentFeature.properties[PointInspectionProperties.projetId])),
                tap((projet) => {
                    this.currentProjet = projet;
                    this.showEditInspection = this.currentProjet ? this.mapPermissionsService.canEditPointInspectionByProjet(this.currentProjet) : false;
                }),
                takeUntil(this.destroyed)
            );
        }
    }

    private getEquipmentMineur() {
        if (this.currentFeature.properties[PointInspectionProperties.equipementsMineurs]) {
            const equipmentMineurArray = JSON.parse(this.currentFeature.properties[PointInspectionProperties.equipementsMineurs]);
            if (equipmentMineurArray) {
                this.equipementsMineurs = equipmentMineurArray as EquipementMineur;
            }
        }
    }

    private addToDisplay(property: any, value: any): boolean {
        const inconnu = ['Inconnu', 'inconnu'];
        switch (property) {
            case PointInspectionProperties.statut: return this.currentFeature.properties.hasOwnProperty(PointInspectionProperties.inspectionId);
            case PointInspectionProperties.inspecteLe: return value !== '' && !inconnu.includes(value);
            case PointInspectionProperties.proprietaire: return !inconnu.includes(value);
            case PointInspectionProperties.materiau: return !inconnu.includes(value);
            case PointInspectionProperties.montage: return !inconnu.includes(value);
            case PointInspectionProperties.classe: return !inconnu.includes(value);
            case PointInspectionProperties.accessibleCamion: return !inconnu.includes(value);
            case PointInspectionProperties.indEquipementMajeur: return !inconnu.includes(value);
            case PointInspectionProperties.anneeFabrication: return value !== '';
            case PointInspectionProperties.anneeInstallation: return value !== '';
            case PointInspectionProperties.justification: return this.currentFeature.properties[PointInspectionProperties.statut] === StatutPointInspection.ignore;
            case PointInspectionProperties.modifieLe: return value !== undefined && value > 0;
            case PointInspectionProperties.modifiePar: return value !== undefined && value !== '' && !inconnu.includes(value);
            default: return true;
        }
    }

    private positionPropertyFirst(array: string[], property: string): string[] {
        return array.sort((a, b) => a === property ? -1 : b === property ? 1 : 0);
    }

    private getProperties(): string[] {
        this.verticalProperties = [
            PointInspectionProperties.adresseTravaux,
            PointInspectionProperties.inspectionId
        ];
        const propertiesToExclude = [
            PointInspectionProperties.projetId,
            PointInspectionProperties.poteauId,
            PointInspectionProperties.codeABarres,
            PointInspectionProperties.statutPoteau,
            PointInspectionProperties.justification,
            PointInspectionProperties.malt,
            PointInspectionProperties.tuteur,
            PointInspectionProperties.luminaire,
            PointInspectionProperties.jambeDeForce,
            PointInspectionProperties.liaisonAerosouterraine,
            PointInspectionProperties.panneau,
            PointInspectionProperties.photos,
            PointInspectionProperties.equipementsMineurs,
            PointInspectionProperties.anomalies,
            PointInspectionProperties.pointsAudit,
        ];

        const result = omit(this.currentFeature.properties, propertiesToExclude);
        return this.positionPropertyFirst(Object.keys(result), PointInspectionProperties.statut);
    }

    private setupAnomalie() {
        if (this.currentFeature.properties[PointInspectionProperties.anomalies]) {
            const anomaliesArray = JSON.parse(this.currentFeature.properties[PointInspectionProperties.anomalies]);
            if (Array.isArray(anomaliesArray)) {
                this.anomalies = anomaliesArray as AnomalieBaseDto[];
            }
        }
    }

    private setPhotosHtml() {
        const photosPointInspection = this.photoService.convertToPhotoContainer(this.photosPointInspection);
        const photosAnomalies = this.photoService.convertToPhotoContainer(flatMap(this.anomalies, anomalie => (anomalie.photos || []) as Photo[]));

        this.numberOfPhotos = photosPointInspection.length + photosAnomalies.length;
        this.imagesPointInspection = this.photoService.convertToPhotoHtml(photosPointInspection);
        this.photoService.setCarousselPhotos([...photosPointInspection, ...photosAnomalies]);
    }

    private setupPhotos() {
        if (this.currentFeature.properties[PointInspectionProperties.photos]) {
            const photosArray = JSON.parse(this.currentFeature.properties[PointInspectionProperties.photos]);
            if (Array.isArray(photosArray)) {
                this.photosPointInspection = photosArray as Photo[];
            }
        }
    }

    private prepareData() {
        this.data = {}; // Reset des données
        this.getProperties().forEach(valueKey => {
            if (this.addToDisplay(valueKey, this.currentFeature.properties[valueKey])) {
                const value = this.mappingData(valueKey, this.currentFeature.properties[valueKey]);
                this.data[valueKey] = value;
            }
        });
    }

    private subscribeToIsAppOffline() {
        this.store.select(getIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isOffline = !_isAppOnline;
        });
    }

    private setupComponent() {
        this.prepareData();
        this.getProjet();
        this.setupCodeBarres();
        this.setupPhotos();
        this.setupAnomalie();
        this.setPhotosHtml();
        this.getEquipmentMineur();
    }
}
