/* eslint-disable @typescript-eslint/naming-convention */
import { LayerGroupe } from '../../enums/layer-groupe';
import { PointAuditProperties } from '../../features/audit/models/point-audit-properties.enum';
import { StatutPointAudit, StatutPointAuditValue } from '../../features/audit/models/statut-point-audit.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const POINTS_AUDIT_STYLE_LEGENDE: StyleLayer[] = [
    {
        ...generateBasicStyle(['a-auditer']),
        'value': {
            ...generateValueStyle(StatutPointAudit.aAuditer),
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.aAuditer],
            'layout': {
                'icon-image': 'a-auditer',
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
    {
        ...generateBasicStyle(['ignore']),
        'value': {
            ...generateValueStyle(StatutPointAudit.ignore),
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.ignore],
            'layout': {
                'icon-image': 'ignore',
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
    {
        ...generateBasicStyle(['conforme']),
        'value': {
            ...generateValueStyle(StatutPointAudit.conforme),
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.conforme],
            'layout': {
                'icon-image': 'conforme',
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
    {
        ...generateBasicStyle(['non-conforme']),
        'value': {
            ...generateValueStyle(StatutPointAudit.nonConforme),
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.nonConforme],
            'layout': {
                'icon-image': 'non-conforme',
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
    {
        ...generateBasicStyle(['a-auditer', 'non-conforme']),
        'value': {
            ...generateValueStyle(StatutPointAudit.nonConforme, '(Poteau)'),
            'filter': ['==', PointAuditProperties.statut, StatutPointAudit.nonConforme],
            'layout': {
                'icon-image': ['case',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.nonConforme], 'non-conforme',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.aAuditer], 'a-auditer',
                    ''
                ],
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
    {
        ...generateBasicStyle(['a-auditer', 'non-conforme']),
        'value': {
            ...generateValueStyle(StatutPointAudit.nonConforme, '(Anomalie)'),
            'filter': ['==', PointAuditProperties.containsAnomalieNonConforme, true],
            'layout': {
                'icon-image': ['case',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.nonConforme], 'non-conforme',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.aAuditer], 'a-auditer',
                    ''
                ],
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
        }
    },
];

function generateBasicStyle(images: string[]): Partial<StyleLayer> {
    const baseAssetPath = 'assets/images/general/audit/';
    return {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': images.map(image => `${baseAssetPath}${image}.png`),
    };
}

function generateValueStyle(identificator: StatutPointAudit, suffix: null | string = null) {
    return {
        'id': suffix ? `${StatutPointAuditValue[identificator]} ${suffix}` : StatutPointAuditValue[identificator],
        'type': LayerType.SYMBOL,
        'source': FeatureSource.POINTS_AUDIT,
        'minzoom': mapStyleConfig.pointAudit.minZoom,
        'paint': {
            'icon-color': '#FFFFFF'
        }
    };
}
