//@ts-check
/**  Third parties  **/
import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl, { AnyLayer, AnySourceData, GeoJSONSource, LngLatBoundsLike, LngLatLike, MapboxGeoJSONFeature } from 'mapbox-gl';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Feature, FeatureCollection } from '@turf/helpers';
import { centroid, multiPolygon, polygon, bbox, BBox } from '@turf/turf';
import * as turf from '@turf/turf';

/**  Store **/
import { Store } from '@ngrx/store';
import * as AuditActions from '../../features/audit/state/audit.actions';
import * as InspectionActions from '../../features/inspection/state/inspection.actions';
import * as SharedActions from '../../state/shared/shared.actions';
import { getAllAnomaliesState } from '../../features/anomalie/state/anomalie/anomalie.selectors';
import { getAnomaliesBbox } from '../../features/anomalie/state/anomalie/anomalie.action';
import {
    getCurrentActiveProjetAudit,
    getPointsActiveProjetAuditSuccess,
    getPointsAuditBBoxSuccess,
    getProjetAuditList
} from '../../features/audit/state/audit.selectors';

/**  Interfaces et enums **/
import { LayerGroupe } from '../../enums/layer-groupe';
import { LocalStorageIndex } from '../../shared/enums/local-storage-index.enum';
import { styleLegende, styles } from '../../data/styles';
import { InfoPoint } from '../models/info-point.model';
import { Photo } from '../../shared/models/photo.model';
import { PhotoContainer } from '../../shared/models/photo-container.model';
import { Projet } from '../../features/projet/models/projet.model';
import { StyleLayer } from '../models/style-layer.model';
import { mapConfig } from '../models/map-config.const';
import { anomalie } from '../models/layers/anomalie.const';
import { bufferLigneProjet } from '../models/layers/buffer-ligne-projet.const';
import { codeABarres } from '../models/layers/code-a-barres.const';
import { ligneProjet } from '../models/layers/ligne-projet.const';
import { pointInspectionLayer } from '../models/layers/point-inspection-layer.const';
import { poteau } from '../models/layers/poteau.const';
import { poteauSelected } from '../models/layers/poteau-selected.const';
import { projetLayer } from '../models/layers/projet-layer.const';
import { projetHighlight } from '../models/layers/projet-highlight.const';
import { projetHighlightOutline } from '../models/layers/projet-highlight-outline.const';
import { projetZoomHighlight } from '../models/layers/projet-zoom-highlight.const';
import { projetZoomHighlightOutline } from '../models/layers/projet-zoom-highlight-outline.const';
import { rechercheCodeBarres } from '../models/layers/recherche-code-barres.const';
import { rechercheLclcl } from '../models/layers/recherche-lclcl.const';
import { rechercheLigne } from '../models/layers/recherche-ligne.const';
import { MapLayersSources } from '../models/map-layers-sources.enum';
import { geoJsonSource } from '../models/geojson-source.const';
import { Bounds } from '../../shared/models/bounds.model';
import { AppConfiguration } from '../../shared/config/app-config.model';
import { UserRole } from '../../shared/models/user-roles.model';
import {
    generatePointsInspectionFeatures,
    generateProjetFeatures,
    generateAnomaliesFeatures,
    arrayBufferToBase64,
    generatePointsAuditFeatures,
    generateProjetAuditFeatures,
    generateProjetInspectionFeatures
} from '../../shared/utils';
import { ImageService } from '../../services/image.service';
import { StatutPointInspectionValue } from '../../features/inspection/models/statut-point-inspection.enum';
import { UtilisateurService } from '../../services/utilisateur.service';
import { AnomalieBaseDto, PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../core/api/client/models';
import { pointAuditLayer } from '../models/layers/point-audit-layer.const';
import { UiService } from '../../services/ui.service';
import { poteauCreationExclus } from '../models/layers/poteau-creation-exclus.const';
import { pointAuditSelected } from '../models/layers/point-audit-selected.const';
import { FeatureSource } from '../models/feature-source.enum';
import { getEsriAccessToken } from '../../state/shared/shared.selectors';
import { OpenIdService } from '@ngxhq/security';
import { SharedService } from '../../services/shared.service';
import { getCurrentActiveProjetInspection, getPointsActiveProjetInspectionSuccess, getProjetInspectionList } from '../../features/inspection/state/inspection.selectors';
import { State } from '../../state/app.state';
import { MapPermissionsService } from './map-permissions.service';
import { pointInspectionPriveLayer } from '../models/layers/point-inspection-prive-layer.const';

interface IMapActionButton {
    classes: string[];
    on: string;
    action: () => void;
    content: string;
}

const BASE_MAP_STYLE: mapboxgl.Style = require('../../data/basemapStyle.json');
@Injectable({
    providedIn: 'root'
})

export class MapService {
    private _map: mapboxgl.Map;
    private mapLoadedSubject = new Subject<void>();
    public mapLoaded$ = this.mapLoadedSubject.asObservable();
    public draw: MapboxDraw;
    public listeExclu: Array<number> = [];

    public viewport$: Observable<turf.BBox>;

    public get viewport(): Bounds {
        if (!this.map) { return null as any; };
        const currentBounds = this.map.getBounds();

        const se = currentBounds.getSouthEast();
        const nw = currentBounds.getNorthWest();

        const bounds: Bounds = {
            xmin: se.lng,
            xmax: nw.lng,
            ymin: se.lat,
            ymax: nw.lat
        };

        return bounds;
    }

    public get zoomLevel(): number {
        return this.map.getZoom();
    }

    public trackingGPS = false;

    public get map(): mapboxgl.Map {
        return this._map;
    }

    public set map(m: mapboxgl.Map) {
        this._map = m;
    }

    public currentImage = new Subject<HTMLImageElement>();
    private _photoListe: PhotoContainer[] = [];
    public get photoListe(): PhotoContainer[] {
        return this._photoListe;
    };
    public set photoListe(value: PhotoContainer[]) {
        this._photoListe = value;
    };
    public nombrePhoto = new Subject<number>();
    public imageIndex = new Subject<number>();
    public updateFeature$ = new Subject<void>();
    private legendeStyles: StyleLayer[] = [];
    public legendeStyles$: Subject<StyleLayer[]> = new Subject();
    private projetsInspection$: Observable<ProjetCompletDto[]> = this.store.select(getProjetInspectionList);
    private projetsAudit$: Observable<ProjetAuditDto[]> = this.store.select(getProjetAuditList);
    private pointsInspections$: Observable<PointInspectionDto[]> = this.store.select(getPointsActiveProjetInspectionSuccess);
    private pointsAudit$: Observable<PointAuditDto[]> = this.store.select(this.utilisateurService.isAuditeur ?
        getPointsActiveProjetAuditSuccess : getPointsAuditBBoxSuccess);
    private anomalies$: Observable<AnomalieBaseDto[]> = this.store.select(getAllAnomaliesState);
    private popUpInfo: mapboxgl.Popup;
    private selectedPointInspectionId: string | number | undefined = undefined;
    private isPoteauDetailsDialogOpened = false;
    public esriAccessToken: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private configs: AppConfiguration,
        private store: Store<State>,
        private imageService: ImageService,
        private readonly utilisateurService: UtilisateurService,
        private readonly uiService: UiService,
        private openIdService: OpenIdService,
        private sharedService: SharedService,
        private mapPermissionsService: MapPermissionsService,
    ) {
        setTimeout(() => {
            void this.subscribeToGetEsriToken();
        }, 2000);

        this.subscriptions.push(
            this.uiService.isPoteauDetailsDialogOpened$.pipe(
                tap((isOpen: boolean) => {
                    this.isPoteauDetailsDialogOpened = isOpen;
                })
            ).subscribe(),

            this.uiService.selectedPointInspectionId$.pipe(
                tap((piId: string | number | undefined) => {
                    this.selectedPointInspectionId = piId;
                }),
            ).subscribe(),
        );
    };

    private async subscribeToGetEsriToken() {
        const isAuthenticated = (await this.openIdService.getCurrentStatus()).isAuthenticated;
        if (isAuthenticated) {
            this.store.dispatch(SharedActions.getEsriAccessToken());

            this.store.select(getEsriAccessToken)
                .pipe(
                    filter(success => !!success),
                    take(1)
                )
                .subscribe((esriAccessToken) => {
                    const currentAccessToken = JSON.parse(window.localStorage.getItem(LocalStorageIndex.ESRI_ACCESS_TOKEN));
                    if (currentAccessToken && (Date.now() < currentAccessToken.expires)) {
                        this.esriAccessToken = currentAccessToken.token;
                    } else {
                        window.localStorage.setItem(LocalStorageIndex.ESRI_ACCESS_TOKEN, JSON.stringify(esriAccessToken));
                        this.esriAccessToken = esriAccessToken.token;
                    }

                    this.initMap();
                });
        }
    }

    public initMap(): void {
        this.map = new mapboxgl.Map({
            accessToken: this.configs.mapbox.accessToken,
            container: mapConfig.mapOptions.container,
            style: this.initBaseMapStyle(BASE_MAP_STYLE),
            zoom: mapConfig.mapOptions.zoom,
            center: mapConfig.mapOptions.center as mapboxgl.LngLatLike,
            maxZoom: mapConfig.mapOptions.maxZoom,
            minZoom: mapConfig.mapOptions.minZoom,
        });
        this.initOnMapLoaded();
    }

    private initBaseMapStyle(style: mapboxgl.Style): mapboxgl.Style {
        const tiles: string[] = [];
        const styleTiles: string[] = [...style.sources!['esri']['tiles']];
        const tiles2: string[] = style.sources!['esri2']['tiles'];
        styleTiles.forEach(tile => tiles.push(`${tile}${this.esriAccessToken}`));

        const baseMapStyle: mapboxgl.Style = {
            version: style.version,
            sprite: style.sprite?.concat(`${this.esriAccessToken}`),
            glyphs: style.glyphs?.concat(`${this.esriAccessToken}`),
            sources: {
                'esri': {
                    type: 'vector',
                    url: String(style.sources!['esri']['url'] + `${this.esriAccessToken}`),
                    minzoom: style.sources!['esri']['minzoom'],
                    maxzoom: style.sources!['esri']['maxzoom'],
                    tiles: tiles,
                    copyrightText: style.sources!['esri']['copyrightText'],
                    attribution: style.sources!['esri']['attribution'],
                } as AnySourceData,
                'esri2': {
                    type: 'vector',
                    url: String(style.sources!['esri2']['url']),
                    minzoom: style.sources!['esri2']['minzoom'],
                    maxzoom: style.sources!['esri2']['maxzoom'],
                    tiles: tiles2,
                } as AnySourceData,
            },
            layers: style.layers,
        };
        return baseMapStyle;
    }

    public recentrerMap(): void {
        this.map.setCenter(mapConfig.mapOptions.center as mapboxgl.LngLatLike);
        this.map.setZoom(mapConfig.mapOptions.zoom);
    }

    // Ajoute les controles de la carte
    private addMapControls(): void {
        const geoLocate = new mapboxgl.GeolocateControl({
            fitBoundsOptions: {
                zoom: 15,
            },
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
            },
            // When active the map will receive updates to the device's location as it changes.
            trackUserLocation: true,
            // Draw an arrow next to the location dot to indicate which direction the device is heading.
            showUserHeading: true,
            showAccuracyCircle: true
        });
        this.map.addControl(geoLocate);
        this.map.addControl(new mapboxgl.NavigationControl());
        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: false,
                combine_features: false,
                trash: false
            }
        });

        geoLocate.on('trackuserlocationstart', () => {
            this.trackingGPS = true;
        });

        geoLocate.on('trackuserlocationend', () => {
            this.trackingGPS = false;
        });
    }

    public createActionButton(title: string, icon: string, action: () => void, classes: string[] = []): IMapActionButton {
        return {
            on: 'click',
            action,
            classes,
            content: `<span><i title="${title}" class="${icon} icon-xl"></i></span>`,
        };
    }

    public async loadTuilesProjet(geometry: turf.Geometry) {
        await this.downloadTuilesProjet(geometry);
        setTimeout(() => {
            const feature: turf.Feature = { type: 'Feature', properties: {}, geometry };
            const bounds: BBox = bbox(feature);
            this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 150 });
        }, 500);
    }

    // chargement async des données de cartes pour une zone de projet
    public async downloadTuilesProjet(geometrieProjet: any): Promise<void> {
        const zooms = [13, 14, 15, 16];
        const cellSize = 600;
        const grid = turf.hexGrid((turf.bbox(turf.buffer((geometrieProjet), 1, { units: 'miles' }))), cellSize, { units: 'meters' });
        const features = grid.features;

        for (const zoom of zooms) {
            for (const feature of features) {
                setTimeout(() => {
                    this.map.jumpTo({
                        center: [
                            turf.centroid(feature.geometry).geometry.coordinates[0],
                            turf.centroid(feature.geometry).geometry.coordinates[1]
                        ],
                        zoom: zoom
                    });
                }, 100);
            }
        }
    }

    private initOnMapLoaded(): void {
        this.map.once('load', () => {
            this.addMapStyles();
            this.addLegendeLayers();
            this.addMapControls();
            this.onPoteauExclu();
            this.initViewportObservable();
            this.onUpdateDraw();
            this.mapLoadedSubject.next();
            this.initAnomaliePilotage();

            this.map.addLayer({
                id: 'satellite',
                source: {
                    'type': 'raster',
                    'url': 'mapbox://mapbox.satellite',
                    'tileSize': 256
                },
                'layout': {
                    'visibility': 'none',
                },
                type: 'raster'
            });
        });
    }

    private initAnomaliePilotage(): void {
        if (this.mapPermissionsService.canFetchAnomaliesPilotageData()) {
            this.store.dispatch(SharedActions.loadAnomaliesPilotage());
        }
    }

    private addMapStyles(): void {
        const list: string[] = [];
        // TODO un service de DEV en PROD ?
        const tilelayer = `${this.configs.baseTileServiceUrl}` + 'Installations_de_distribution_CAPTURE_DEV_Vectoriel/VectorTileServer/tile/{z}/{y}/{x}.pbf?token=' +
            `${this.esriAccessToken}`;

        for (const prop of styles) {
            // ajouter les sources sans doublon
            let styleLayer: any = {};
            styleLayer = prop.value.source;
            if (list.indexOf(styleLayer) === -1) {
                list.push(styleLayer);
                this.map.addSource(styleLayer, {
                    type: 'vector',
                    tiles: [tilelayer],
                });
            }
            // ajouter le style selon l'existance d'icone ou pas
            styleLayer = prop.value;

            (prop?.urlimage || []).forEach(image => {
                this.loadIconStyle(image, prop.value.id);
            });
        }
    }

    private addLegendeLayers() {
        const roles: string = localStorage.getItem(LocalStorageIndex.USER_ROLES);
        const isAdminTic = roles?.includes(UserRole.ADMIN_TIC);
        const isAuditeur = roles?.includes(UserRole.AUDITEUR_HQD) || roles?.includes(UserRole.AUDITEUR_EXTERNE);
        const isInspecteur = roles?.includes(UserRole.INSPECTEUR_HQD) || roles?.includes(UserRole.INSPECTEUR_EXTERNE);

        this.projetsInspection$.subscribe((projets) => {
            const projetFeatures: FeatureCollection = generateProjetInspectionFeatures(projets);
            if (this.map.getSource(FeatureSource.PROJETS_INSPECTION)) {
                const geojsonSource = this.map.getSource(FeatureSource.PROJETS_INSPECTION) as GeoJSONSource;
                geojsonSource.setData(projetFeatures as GeoJSON.FeatureCollection);
            } else {
                this.map.addSource(FeatureSource.PROJETS_INSPECTION, {
                    type: 'geojson',
                    data: projetFeatures as GeoJSON.FeatureCollection,
                });
            }
        });

        this.projetsAudit$.subscribe(projetsAudit => {
            const projetAuditFeatures: FeatureCollection = generateProjetAuditFeatures(projetsAudit);
            if (this.map.getSource(FeatureSource.PROJETS_AUDIT)) {
                const geojsonSource = this.map.getSource(FeatureSource.PROJETS_AUDIT) as GeoJSONSource;
                geojsonSource.setData(projetAuditFeatures as GeoJSON.FeatureCollection);
            } else {
                this.map.addSource(FeatureSource.PROJETS_AUDIT, {
                    type: 'geojson',
                    data: projetAuditFeatures as GeoJSON.FeatureCollection,
                });
            }
        });

        this.pointsInspections$.subscribe((pointsInspection) => {
            const pointsInspectionFeatures: FeatureCollection = generatePointsInspectionFeatures(pointsInspection);
            if (this.map.getSource(FeatureSource.POINTS_INSPECTION)) {
                const geojsonSource = this.map.getSource(FeatureSource.POINTS_INSPECTION) as GeoJSONSource;
                geojsonSource.setData(pointsInspectionFeatures as GeoJSON.FeatureCollection);

                if (this.selectedPointInspectionId) {
                    const foundPointInspectionfeature = pointsInspectionFeatures.features.find((pif: any) =>
                        pif?.properties?.inspectionId === this.selectedPointInspectionId);
                    if (foundPointInspectionfeature) {
                        this.triggerPointInspectionObservables({ openSideBar: true, feature: foundPointInspectionfeature });
                    }
                }
            } else {
                this.map.addSource(FeatureSource.POINTS_INSPECTION, {
                    type: 'geojson',
                    data: pointsInspectionFeatures as GeoJSON.FeatureCollection,
                });
            }
        });

        this.pointsAudit$.subscribe((pointsAudit) => {
            if (pointsAudit) {
                const pointsAuditFeatures: FeatureCollection = generatePointsAuditFeatures(pointsAudit);
                if (this.map.getSource(FeatureSource.POINTS_AUDIT)) {
                    const geojsonSource = this.map.getSource(FeatureSource.POINTS_AUDIT) as GeoJSONSource;
                    geojsonSource.setData(pointsAuditFeatures as GeoJSON.FeatureCollection);
                } else {
                    this.map.addSource(FeatureSource.POINTS_AUDIT, {
                        type: 'geojson',
                        data: pointsAuditFeatures as GeoJSON.FeatureCollection,
                    });
                }
            }
        });

        this.anomalies$.subscribe((anomalies) => {
            if (anomalies) {
                const anomaliesFeatures: FeatureCollection = generateAnomaliesFeatures(anomalies);
                if (this.map.getSource(FeatureSource.ANOMALIES)) {
                    const geojsonSource = this.map.getSource(FeatureSource.ANOMALIES) as GeoJSONSource;
                    geojsonSource.setData(anomaliesFeatures as GeoJSON.FeatureCollection);
                } else {
                    this.map.addSource(FeatureSource.ANOMALIES, {
                        type: 'geojson',
                        data: anomaliesFeatures as GeoJSON.FeatureCollection,
                    });
                }
            }
        });

        // TODO DELETE WHEN PDL-655 IS DONE
        // this.projetEnCours$.subscribe((activeProjet) => {
        //     if (activeProjet.length > 0) {
        //         const projetId: string | undefined = activeProjet[0].id;
        //         this.pointInspectionStore.dispatch(getPointInspectionProjetActif({ projetId }));
        //     };
        // });

        //this.projetInspectionEnCours$.subscribe((activeProjetInspection) => {
        this.store.select(getCurrentActiveProjetInspection)
            .subscribe((activeProjetInspection) => {
                if (activeProjetInspection) {
                    this.store.dispatch(InspectionActions.getPointsActiveProjetInspection());
                };
            });

        //this.projetAuditEnCours$.subscribe((activeProjetAudit) => {
        this.store.select(getCurrentActiveProjetAudit)
            .subscribe((activeProjetAudit) => {
                if (activeProjetAudit) {
                    this.store.dispatch(AuditActions.getPointsActiveProjetAudit());
                }
            });

        forEach(styleLegende, (styleLayer) => {
            switch (styleLayer.nomGroupe) {
                case LayerGroupe.PARC_DE_POTEAUX:
                    if (!!this.map.getSource(styleLayer.value.source)) {
                        this.addLegendeLayer(styleLayer);
                        this.legendeStyles.push(styleLayer);
                    } else {
                        const featureData = `https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_Vue/FeatureServer/
                        ${styleLayer.numeroService}/query?f=pgeojson&where=1=1&outFields=*&returnGeometry=true&token=` + `${this.esriAccessToken}`;
                        this.map.addSource(styleLayer.value.source, {
                            type: 'geojson',
                            data: featureData,
                        });
                        this.legendeStyles.push(styleLayer);
                        return this.addLegendeLayer(styleLayer);
                    }
                    break;
                case LayerGroupe.ENVIRONNEMENT:
                    if (!!this.map.getSource(styleLayer.value.source)) {
                        this.addLegendeLayer(styleLayer);
                        this.legendeStyles.push(styleLayer);
                    } else {
                        const featureData = `https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/Aires_protegees_vue/FeatureServer/21/query` +
                            `?f=pgeojson&where=1=1&outFields=*&returnGeometry=true&token=` + `${this.esriAccessToken}`;
                        this.map.addSource(styleLayer.value.source, {
                            type: 'geojson',
                            data: featureData,
                        });
                        this.legendeStyles.push(styleLayer);
                        return this.addLegendeLayer(styleLayer);
                    }
                    break;
                default:
                    if (!!this.map.getSource(styleLayer.value.source)) {
                        this.addLegendeLayer(styleLayer);
                        this.legendeStyles.push(styleLayer);
                    } else {
                        this.addLegendeLayer(styleLayer);
                        this.legendeStyles.push(styleLayer);
                    }

                    if (styleLayer.value.id !== StatutPointInspectionValue.exclu) {
                        if (!this.utilisateurService.isUserMobile && !isInspecteur) {
                            this.getPopUp(styleLayer.value.id, false, false);
                        }
                        if (isAuditeur && styleLayer.value.source === FeatureSource.POINTS_AUDIT) {
                            this.initPointInspectionClickEvent(styleLayer.value.id);
                        }
                        if (this.utilisateurService.isUserMobile && styleLayer.value.source === FeatureSource.POINTS_INSPECTION) {
                            if (!this.utilisateurService.isUserMobile && isAdminTic) {
                                this.getPopUp(styleLayer.value.id, false, false);
                            } else {
                                this.initPointInspectionClickEvent(styleLayer.value.id);
                            }
                        }
                        this.changeMapCusror(styleLayer.value.id);
                    }
                    break;
            }
        });
        this.legendeStyles$.next(this.legendeStyles);
        this.addMapLayers();
    }

    public getPopUp(layer: string, isMobile: boolean, avecMasqueButton: boolean): void {
        if (isMobile) {
            this.map.on('touchstart', layer, (e) => {
                this.popupTouchstart(e);
            });
        } else {
            this.map.on('click', layer, async (e) => {
                this.popupClicked(e, avecMasqueButton);
            });
        }
    }

    private popupTouchstart(e: mapboxgl.MapTouchEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } & mapboxgl.EventData) {
        let selectedFeatures = null;
        let selectedPointsInspection = null;
        let i: number = 1;
        let piNonTrouve = true;

        // Tant qu'il n'y a pas de point d'inspection de trouvé, on agrandi le bounding box de recherche
        while (piNonTrouve) {
            // Trouve les features inclusent dans le bounding box
            selectedFeatures = this.map.queryRenderedFeatures([
                [e.point.x - i, e.point.y - i],
                [e.point.x + i, e.point.y + i]
            ]);
            selectedPointsInspection = selectedFeatures.filter(x => x.source === FeatureSource.POINTS_INSPECTION);
            if (selectedPointsInspection?.length >= 1 || i > 20) {
                piNonTrouve = false;
            }
            i++;
        }

        if (selectedPointsInspection && selectedPointsInspection?.length >= 1) {
            (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(selectedPointsInspection![0]);

            this.triggerPointInspectionObservables({ openSideBar: true, feature: selectedPointsInspection![0] as Feature });
        }
    }

    public setupPopUpWithContent(content: any, geometry: turf.Geometry) {
        this.popUpInfo = new mapboxgl.Popup({
            className: 'basic-popup-info',
            closeButton: false,
            maxWidth: '300px',
        });

        this.popUpInfo.setLngLat(this.getCoordonnees(geometry))
            .setDOMContent(content)
            .addTo(this.map);
    }

    public closePopUpInfo(closed: boolean) {
        if (this.popUpInfo.isOpen() && closed) {
            this.popUpInfo.setDOMContent(document.createElement('h1')).remove();
        }
    }

    public closeActionSheet() {
        this.triggerPointInspectionObservables({ openSideBar: false, feature: undefined });
        this.resetSelection();
    }

    private resetSelection() {
        const featureReset: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { features: [], type: 'FeatureCollection' };
        (this.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(featureReset);
        (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(featureReset);
        this.uiService.setSelectedPointInspectionId(undefined);
    }

    private popupClicked(e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } & mapboxgl.EventData, avecMasqueButton: boolean) {
        if (!this.isPoteauDetailsDialogOpened) {
            if (this.popUpInfo && this.popUpInfo.isOpen()) {
                this.popUpInfo.remove();
            }
            this.closeActionSheet();
            this.uiService.setMapPopUpClicked(e, avecMasqueButton);
        }
    }

    private getCoordonnees(geometry: turf.Geometry): LngLatLike {
        const geometryType = geometry['type'];
        const coordinates: LngLatLike = geometry['coordinates'] as LngLatLike;
        if (geometryType === 'Point') {
            return coordinates;
        } else if (geometryType === 'Polygon') {
            const poly = polygon(coordinates as any);
            const centroide = centroid(poly);
            return centroide.geometry.coordinates as any;
        } else {
            const multiPolygones = multiPolygon(coordinates as any);
            const centroide = centroid(multiPolygones);
            return centroide.geometry.coordinates as any;
        }
    }

    private addMapLayers(): void {
        this.map.addSource(MapLayersSources.POLYGONE_PROJET, geoJsonSource);
        this.map.addLayer(projetLayer);

        this.map.addSource(MapLayersSources.POLYGONE_PROJET_HIGHLIGHTED, geoJsonSource);
        this.map.addLayer(projetHighlight);
        this.map.addLayer(projetHighlightOutline);

        this.map.addSource(MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED, geoJsonSource);
        this.map.addLayer(projetZoomHighlight);
        this.map.addLayer(projetZoomHighlightOutline);

        this.map.addSource(MapLayersSources.LIGNE_PROJET, geoJsonSource);
        this.map.addLayer(ligneProjet);

        this.map.addSource(MapLayersSources.BUFFER_LIGNE_PROJET, geoJsonSource);
        this.map.addLayer(bufferLigneProjet);

        this.map.addSource(MapLayersSources.RECHERCHE_LIGNE, geoJsonSource);
        this.map.addLayer(rechercheLigne);

        this.map.addSource(MapLayersSources.RECHERCHE_LCLCL, geoJsonSource);
        this.map.addLayer(rechercheLclcl);

        this.map.addSource(MapLayersSources.RECHERCHE_CODE_BARRES, geoJsonSource);
        this.map.addLayer(rechercheCodeBarres);

        this.map.addSource(MapLayersSources.ANOMALIE, geoJsonSource);
        this.map.addLayer(anomalie);

        this.map.addSource(MapLayersSources.POINT_INSPECTION, geoJsonSource);
        this.map.addLayer(pointInspectionLayer);

        this.map.addSource(MapLayersSources.POINT_INSPECTION_PRIVE, geoJsonSource);
        this.map.addLayer(pointInspectionPriveLayer);

        this.map.addSource(MapLayersSources.POINT_AUDIT_SELECTED, geoJsonSource);
        this.map.addLayer(pointAuditSelected);

        this.map.addSource(MapLayersSources.POINT_AUDIT, geoJsonSource);
        this.map.addLayer(pointAuditLayer);

        this.map.addSource(MapLayersSources.POTEAU_HIGHLIGHTED, geoJsonSource);
        this.map.addLayer(poteau);

        this.map.addSource(MapLayersSources.POTEAU_CREATION_EXCLUS, geoJsonSource);
        this.map.addLayer(poteauCreationExclus);

        this.map.addSource(MapLayersSources.POTEAU_SELECTED, geoJsonSource);
        this.map.addLayer(poteauSelected);

        this.map.addSource(MapLayersSources.CODEABARRES, geoJsonSource);
        this.map.addLayer(codeABarres);
    }

    private addLegendeLayer(stylelayer: StyleLayer): void {
        const featureLayer: AnyLayer = stylelayer.value as AnyLayer;
        (stylelayer?.urlimage || []).forEach(image => {
            this.loadIconStyle(image, stylelayer.value.id);
        });
        this.map.addLayer(featureLayer);
    }

    private loadIconStyle(imageUrl: string, id: string): void {

        this.map.loadImage(
            imageUrl,
            (error: any, image: any) => {
                if (error) { throw error; }
                if (!this.map.hasImage(id)) {
                    this.map.addImage(id, image);
                }
            }
        );
    }

    private onPoteauExclu(): void {
        this.map.on('mousedown', MapLayersSources.POTEAU_HIGHLIGHTED, (e) => {
            const state = e.features?.[0].state?.click;
            const objectId = e.features?.[0].properties?.OBJECTID;
            const poteauId = e.features?.[0].properties?.poteau_id;
            const index = this.listeExclu.findIndex(objt => objt === poteauId);
            if (e.originalEvent.shiftKey) {
                if (state) {
                    if (index > -1) {
                        this.listeExclu.splice(index, 1);
                        this.map.setFeatureState({ source: MapLayersSources.POTEAU_HIGHLIGHTED, id: objectId }, { click: false });
                    } else {

                    }
                } else {
                    this.map.setFeatureState({ source: MapLayersSources.POTEAU_HIGHLIGHTED, id: objectId }, { click: true });
                    this.listeExclu.push(poteauId);
                }

                this.uiService.setNumberOfExcludePole(this.listeExclu.length);
            }
        });
    }

    private initViewportObservable(): void {
        this.map?.on('move', () => {
            this.store.dispatch(InspectionActions.loadPointsInspectionBbox({ bounds: this.viewport, zoomLevel: this.zoomLevel }));
            this.store.dispatch(AuditActions.loadPointsAuditBbox({ bounds: this.viewport, zoomLevel: this.zoomLevel }));
            this.store.dispatch(getAnomaliesBbox({ bbox: this.viewport, zoomLevel: this.zoomLevel }));
        });
    }

    public resetFeatureExclure(): void {
        this.listeExclu = [];
        this.uiService.setNumberOfExcludePole(0);
        this.map.removeFeatureState({ source: MapLayersSources.POTEAU_HIGHLIGHTED });
    }

    private onUpdateDraw(): void {
        this.map.on('draw.update', () => {
            this.updateFeature$.next();
        });
    }

    private initPointInspectionClickEvent(layer: string): void {
        const onClickFeature = (e: { features?: MapboxGeoJSONFeature[] | undefined }) => {
            if (!this.isPoteauDetailsDialogOpened) {
                const feature = e.features![0];
                this.setupPointInspection(feature);
            }
        };

        this.map.on('touchstart', layer, e => onClickFeature(e));
        this.map.on('click', layer, e => onClickFeature(e));
    }

    private setSelectedElements(feature: MapboxGeoJSONFeature) {
        const featureReset: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { features: [], type: 'FeatureCollection' };

        if (this.utilisateurService.isAuditeur) {
            (this.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(feature);
            (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(featureReset);
        } else if (this.utilisateurService.isInspecteur || this.utilisateurService.isControleurQualite) {
            (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(feature);
            (this.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(featureReset);
        } else if (!this.utilisateurService.isUserMobile) {
            const isPointAuditSelected = feature?.properties!.hasOwnProperty('pointInspectionId');
            if (isPointAuditSelected) {
                (this.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(feature);
                (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(featureReset);
            } else {
                (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(feature);
                (this.map.getSource(MapLayersSources.POINT_AUDIT_SELECTED) as GeoJSONSource).setData(featureReset);
            }
        }
    };

    public setupPointInspection(feature: mapboxgl.MapboxGeoJSONFeature) {
        this.triggerPointInspectionObservables({ openSideBar: true, feature: feature as Feature });

        this.uiService.setSelectedPointInspectionId(feature.properties?.hasOwnProperty('inspectionId')
            ? feature.properties?.inspectionId : feature.properties?.pointInspectionId);
        this.setSelectedElements(feature);
    }

    private triggerPointInspectionObservables(point: InfoPoint): void {
        this.uiService.triggerPointInspection(point);
    }

    private changeMapCusror(layer: string): void {
        this.map.on('mouseenter', layer, () => {
            this.map.getCanvas().classList.add('capture-custom-layer-active_pointeur');
        });
        this.map.on('mouseleave', layer, () => {
            this.map.getCanvas().classList.remove('capture-custom-layer-active_pointeur');
        });
    }

    private currentIndex(titreImage: string): void {
        const index = this.photoListe.findIndex(objt => objt.visibleTitre === titreImage);
        this.imageIndex.next(index);
    }

    public creationImage(photo: Photo, numeroPhoto: number, titre: string): HTMLImageElement {
        const modal = document.getElementById('capture-image_viewer');
        const imageAnomalie = new Image();
        this.imageService.getImages(photo.thumbnailImageSrc).subscribe({
            next: (data) => {
                const base64 = arrayBufferToBase64(data);
                imageAnomalie.src = `data:image/jpeg;base64,${base64}`;
                imageAnomalie.alt = photo.alt as string;
                imageAnomalie.title = titre;
                imageAnomalie.classList.add('thumbnailImage');
                imageAnomalie.addEventListener('click', () => {
                    this.ouvrirImage(photo, numeroPhoto);
                    modal!.style.display = 'block';
                });
            },
            error: () => {
                imageAnomalie.src = './assets/images/indisponible.jpg';
            }
        });

        return imageAnomalie;
    }

    public ouvrirImage(photo: Photo, index: number): void {
        const modal = document.getElementById('capture-image_viewer');
        const modalContent = document.getElementById('image_viewer-content');
        const captionText = document.getElementById('caption');
        const slides = document.getElementsByClassName('inspectionPhotos');
        for (let i = 0; i < slides.length; i++) {
            (slides[i] as HTMLImageElement).style.display = 'none';
        };
        this.imageService.getImages(photo.previewImageSrc).subscribe((data) => {
            const imageSlider = new Image();
            const base64 = arrayBufferToBase64(data);
            imageSlider.src = `data:image/jpeg;base64,${base64}`;
            imageSlider.alt = photo.alt as string;
            imageSlider.title = photo.title! + '_' + index;
            imageSlider.className = 'inspectionPhotos';
            imageSlider.style.display = 'block';
            captionText!.innerHTML = imageSlider.title;
            this.currentImage.next(imageSlider);
            const imageExistante = modalContent!.querySelector(`[title="${imageSlider.title}"]`);
            if (imageExistante) {
                imageExistante.replaceWith(imageSlider);
            } else {
                modalContent?.appendChild(imageSlider);
            };
            this.currentIndex(imageSlider.title);
        });
        modal?.append(modalContent!);
    }

    public zoomProjet(projet: Projet) {
        this.sharedService.disconnectGPS();
        const projetFeature: FeatureCollection = generateProjetFeatures([projet]);
        const bounds: BBox = bbox(projetFeature);
        this.uiService.openProjetInspectionListModal(false);
        this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 150 });
        if (this.utilisateurService.isUserMobile) {
            this.getPopUp(MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED, true, true);
        }
    }

    public zoomProjetInspection(projetInspection: ProjetCompletDto) {
        this.sharedService.disconnectGPS();
        const projetInspectionFeature: FeatureCollection = generateProjetInspectionFeatures([projetInspection]);
        const bounds: BBox = bbox(projetInspectionFeature);

        this.uiService.openProjetsListModal(false);
        // this.uiService.openProjetInspectionListModal(false);
        this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 150 });
        if (this.utilisateurService.isUserMobile) {
            this.getPopUp(MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED, true, true);
        }
    }

    public zoomProjetAudit(projetAudit: ProjetAuditDto) {
        this.sharedService.disconnectGPS();
        const projetAuditFeature: FeatureCollection = generateProjetAuditFeatures([projetAudit]);
        const bounds: BBox = bbox(projetAuditFeature);
        this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 150 });
        this.getPopUp(MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED, true, true);
    }

    // public zoomPointInspection(pointInspection: PointInspection) {
    //     this.sharedService.disconnectGPS();
    //     const pointsInspectionFeatures: FeatureCollection = generatePointsInspectionFeatures([pointInspection]);
    //     if (pointsInspectionFeatures.features) {
    //         this.zoomByFeature(pointsInspectionFeatures.features[0] as GeoJSON.Feature, this.utilisateurService.isUserMobile ? pointInspection.id : null);
    //     }
    // }

    public zoomPointInspection(pointInspection: PointInspectionDto) {
        this.sharedService.disconnectGPS();
        const pointsInspectionFeatures: FeatureCollection = generatePointsInspectionFeatures([pointInspection]);
        if (pointsInspectionFeatures.features) {
            this.zoomByFeature(pointsInspectionFeatures.features[0] as GeoJSON.Feature, this.utilisateurService.isUserMobile ? pointInspection.id : null);
        }
    }

    public zoomByFeature(feature: GeoJSON.Feature, pointInspectionId: string | null) {
        const bounds: BBox = bbox(feature);
        this.map.fitBounds(bounds as LngLatBoundsLike, { zoom: 18, padding: 3 });
        const pointInspectionFeatures: GeoJSON.FeatureCollection = { features: [feature as GeoJSON.Feature], type: 'FeatureCollection', bbox: bounds };
        (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(pointInspectionFeatures);

        if (pointInspectionId) {
            this.uiService.setSelectedPointInspectionId(pointInspectionId);
            if (this.utilisateurService.isUserMobile) {
                this.uiService.triggerInfoPointInspectionFromFeature(feature);
            }
        }
    }

    public zoomPointAudit(pointAudit: PointAuditDto) {
        this.sharedService.disconnectGPS();
        const pointsAuditFeatures: FeatureCollection = generatePointsAuditFeatures([pointAudit]);
        if (pointsAuditFeatures.features) {
            const id = this.utilisateurService.isUserMobile ? pointAudit.pointInspectionId : null;
            const source = MapLayersSources.POINT_AUDIT_SELECTED;
            this.zoomByPointAuditFeature(pointsAuditFeatures.features[0] as GeoJSON.Feature, id, source);
        }
    }

    public zoomByPointAuditFeature(feature: GeoJSON.Feature, pointAuditId: string | null, source: MapLayersSources = MapLayersSources.POTEAU_SELECTED) {
        const bounds: BBox = bbox(feature);
        this.map.fitBounds(bounds as LngLatBoundsLike, { zoom: 18, padding: 3 });
        const pointAuditFeatures: GeoJSON.FeatureCollection = { features: [feature as GeoJSON.Feature], type: 'FeatureCollection', bbox: bounds };
        (this.map.getSource(source) as GeoJSONSource).setData(pointAuditFeatures);

        if (pointAuditId) {
            this.uiService.setSelectedPointInspectionId(pointAuditId);
            if (this.utilisateurService.isUserMobile) {
                this.uiService.triggerInfoPointInspectionFromFeature(feature);
            }
        }
    }
}
