import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';

export const pointInspectionPriveLayer: AnyLayer = {
    id: MapLayersSources.POINT_INSPECTION_PRIVE,
    type: 'circle',
    source: MapLayersSources.POINT_INSPECTION_PRIVE,
    minzoom: mapStyleConfig.poteau.minZoom,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'circle-color': mapStyleConfig.colors.blanc,
        'circle-radius': mapStyleConfig.poteau.circleWidth,
        'circle-stroke-width': mapStyleConfig.poteau.privateCircleStroke,
        /* eslint-enable @typescript-eslint/naming-convention */
    }
};
