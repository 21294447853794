import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AnomalieAuditDto, AnomalieBaseDto } from '../../../../../core/api/client/models';
//import { Anomalie } from '../../../../../features/anomalie/models/anomalie.model';
import { dateTimeChange, generatePhotosAnomalieAudit } from '../../../../../shared/utils';
import { PhotoService } from '../../../../../services/photo.service';

@Component({
    selector: 'app-pop-up-info-anomalie-audit',
    templateUrl: './pop-up-info-anomalie-audit.component.html',
    styleUrls: ['./pop-up-info-anomalie-audit.component.scss']
})
export class PopUpInfoAnomalieAuditComponent implements OnChanges {
    @Input() anomaliesAudit: AnomalieAuditDto[] = [];
    @Input() anomalies: AnomalieBaseDto[] = [];

    public anomalieData: {
        anomalieAudit: AnomalieAuditDto,
        anomalie: AnomalieBaseDto,
        photos: HTMLImageElement[],
        data: { [name: string]: any }
    }[];

    public displayedProperties = {
        cause: 'Cause',
        type: 'Type',
        noteAuditeur: 'Note auditeur',
        auditModifieLe: 'Modifié le',
        auditModifiePar: 'Modifié par'
    };

    public verticalProperties: string[] = [this.displayedProperties.noteAuditeur, this.displayedProperties.cause];

    constructor(private photoService: PhotoService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.anomalies?.currentValue || changes?.anomaliesAudit?.currentValue) {
            this.prepareData();
        }
    }

    private prepareData() {
        this.anomalieData = this.anomaliesAudit.map((anomalieAudit) => {
            const photos = this.getPhotos(anomalieAudit);
            const anomalie = this.getAnomalie(anomalieAudit);
            const data = this.getData(anomalieAudit, anomalie);

            return { photos, anomalieAudit, anomalie, data };
        });
    }

    private getAnomalie(anomalieAudit: AnomalieAuditDto) {
        const anomalie = this.anomalies.find(a => a.id === anomalieAudit.anomalieInspectionId);
        return anomalie;
    }

    private getData(anomalieAudit: AnomalieAuditDto, anomalie: AnomalieBaseDto): { [name: string]: any } {
        const data = {
            [this.displayedProperties.cause]: anomalie.cause,
            [this.displayedProperties.type]: anomalie.type,
            [this.displayedProperties.noteAuditeur]: anomalieAudit.remarque,

        };

        const dynamicData = this.getDynamicData(anomalieAudit);

        return {
            ...data,
            ...dynamicData
        };
    }

    private getDynamicData(anomalieAudit: AnomalieAuditDto) {
        const dynamicData = {};
        if (anomalieAudit.auditModifieLe && anomalieAudit.auditModifieLe > 0) {
            dynamicData[this.displayedProperties.auditModifieLe] = dateTimeChange(anomalieAudit.auditModifieLe.toString(), 'dd/MM/YYYY');
        }

        if (anomalieAudit.auditModifiePar && anomalieAudit.auditModifiePar !== '') {
            dynamicData[this.displayedProperties.auditModifiePar] = anomalieAudit.auditModifiePar;
        }

        return dynamicData;
    }

    private getPhotos(anomalieAudit: AnomalieAuditDto) {
        const convertedPhotos = generatePhotosAnomalieAudit([anomalieAudit]);
        const photoContainer = this.photoService.convertToPhotoContainer(convertedPhotos);
        return this.photoService.convertToPhotoHtml(photoContainer);
    }
}

