import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';

import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { DataExtractionFormat } from '../../../shared/enums/data-extraction-format.enum';
import { ProjetType } from '../models/projet-type.enum';
import * as SharedActions from '../../../state/shared/shared.actions';

@Injectable()
export class ExtractionMenu extends BaseComponent {
    constructor(private store: Store,) {
        super();
    }

    public getGeoJsonMenuItem(projetType: ProjetType): MenuItem {
        return {
            label: 'GeoJSON',
            icon: 'fas fa-globe',
            command: () => { this.exportZipFile(DataExtractionFormat.GEOJSON, projetType); },
        };
    }

    public getJsonMenuItem(projetType: ProjetType): MenuItem {
        return {
            label: 'JSON',
            icon: 'fas fa-code',
            command: () => { this.exportZipFile(DataExtractionFormat.JSON, projetType); },
        };
    }

    public getSqliteMenuItem(projetType: ProjetType): MenuItem {
        return {
            label: 'SQLite',
            icon: 'fas fa-database',
            disabled: true,
            command: () => { this.exportZipFile(DataExtractionFormat.SQLITE, projetType); },
        };
    }


    public getInspectionMenu() {
        return [
            this.getGeoJsonMenuItem(ProjetType.INSPECTION),
            this.getJsonMenuItem(ProjetType.INSPECTION),
            this.getSqliteMenuItem(ProjetType.INSPECTION),
        ];
    }

    public getAuditMenu() {
        return [
            this.getGeoJsonMenuItem(ProjetType.AUDIT),
            this.getJsonMenuItem(ProjetType.AUDIT),
            this.getSqliteMenuItem(ProjetType.AUDIT),
        ];
    }

    public getInspectionAsSubMenu() {
        return {
            label: 'Inspection...',
            items: this.getInspectionMenu()
        };
    }

    public getAuditAsSubMenu() {
        return {
            label: 'Audit...',
            items: this.getAuditMenu()
        };
    }

    private exportZipFile(format: DataExtractionFormat, dataType: ProjetType) {
        this.store.dispatch(SharedActions.startDataExtraction({ typeExtraction: dataType, format: format }));
    }
}
