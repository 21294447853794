import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { PatchProjetInspectionDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import * as ProjetsActions from '../../../state/projets.actions';
import { ProjetsActionBaseDialog } from '../projets-action-base-dialog';
import { ProjetInspectionActions } from '../../../models/projets-inspection-actions.enum';

@Component({
    selector: 'app-rejeter-projet-inspection-dialog',
    templateUrl: './rejeter-projet-inspection-dialog.component.html',
    styleUrls: ['./rejeter-projet-inspection-dialog.component.scss']
})
export class RejeterProjetInspectionDialogComponent extends ProjetsActionBaseDialog {

    public form: FormGroup;
    private selectedProjet: ProjetCompletDto | undefined;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private config: DynamicDialogConfig,
        protected ref: DynamicDialogRef,
        protected confirmationService: ConfirmationService,
    ) {
        super(ref, confirmationService);
        this.initForm();
        this.selectedProjet = this.config.data?.selectedProjet ?? undefined;
    }

    private initForm() {
        this.form = this.fb.group({
            justificatif: [null, Validators.required]
        });
    }

    public onSave() {
        if (this.form.valid) {
            const justificatif = this.form.controls.justificatif.value;
            const rejectData: PatchProjetInspectionDto = { action: ProjetInspectionActions.REJETER, remarque: justificatif };

            this.store.dispatch(ProjetsActions.rejectProjetInspection({ projetInspectionId: this.selectedProjet?.id, rejectData }));

            this.closeDialog();
        }
    }
}
