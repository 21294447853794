import { dateTimeChange } from '../utils';

export function customTextFilter(value: any, currentfilter: any): boolean {
    if (currentfilter === undefined || currentfilter === null || currentfilter.trim() === '') { return true; }

    if (currentfilter === '!') {
        return value === undefined || value === null || value.trim() === ''; // Caractère spécial "!" On retourne seulement si la valeur est vide
    } else if (currentfilter === '*') {
        return !(value === undefined || value === null || value.trim() === ''); // Caractère spécial "*" On retourne seulement si la valeur n'est pas vide
    } else {
        if (value === undefined || value === null) { return false; } // Si la valeur est vide on ne la retourne pas
        return value.toString().toLowerCase().includes(currentfilter.toString().toLowerCase()); // Si la valeur contient le contenu dans le filtre on la retourne
    }
}

export function customDateFilter(value: any, currentfilter: any): boolean {
    if (currentfilter === undefined || currentfilter === null || currentfilter.trim() === '') { return true; }

    if (currentfilter === '!') {
        return value === undefined || value === null || value === 0; // Si la valeur est vide
    } else if (currentfilter === '*') {
        return !(value === undefined || value === null || value === 0); // Si la valeur n'est pas vide
    } else {
        if (value === undefined || value === null) { return false; } // Si la valeur est vide on ne la retourne pas
        return dateTimeChange(value.toString(), 'YYYY-MM-dd').includes(currentfilter.toString());
    }
}

export function customNumberFilter(value: any, currentfilter: any): boolean {
    if (currentfilter === undefined || currentfilter === null || currentfilter.trim() === '') { return true; }

    if (currentfilter[0] === '!') {
        return value === undefined || value === null || value === 0; // Si la valeur est vide
    } else if (currentfilter[0] === '*') {
        return !(value === undefined || value === null || value === 0); // Si la valeur n'est pas vide
    } else if (currentfilter[0] === '<') {
        if (currentfilter.length >= 2 && currentfilter.toString().substring(1).trim() !== '') {
            // Si la valeur plus petite que le filtre
            return value !== undefined && value !== null && Number(value) < Number(currentfilter.substring(1).trim());
        } else {
            return true;
        }
    } else if (currentfilter[0] === '>') {
        if (currentfilter.length >= 2 && currentfilter.substring(1).trim() !== '') {
            // Si la valeur est plus grande que le filtre
            return value !== undefined && value !== null && Number(value) > Number(currentfilter.substring(1).trim());
        } else {
            return true;
        }
    } else if (currentfilter[0] === '=') {
        if (currentfilter.length >= 2 && currentfilter.substring(1).trim() !== '') {
            // Si la valeur egual exactement au filtre
            return value !== undefined && value !== null && Number(value) === Number(currentfilter.substring(1).trim());
        } else {
            return true;
        }
    } else {
        if (value === undefined || value === null) { return false; } // Si la valeur est vide on ne la retourne pas
        return value.toString().toLowerCase().includes(currentfilter.toString().toLowerCase());
    }
}
