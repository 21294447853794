import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';

import { map, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { BBox, bbox, } from '@turf/turf';
import { featureEach } from '@turf/meta';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FeatureCollection, Feature } from 'geojson';
import { GeoJSONSource, LngLatBoundsLike, } from 'mapbox-gl';

import { MapService } from '../../../map/services/map.service';
import { TableColumn } from '../../../shared/models/table-column.model';
import { HQValidators } from '../../../shared/validators/hq-validators';
import { RechercheConfig, RechercheSource, rechercheBackendUrlValue } from '../models/recherche.enum';
import { MapLayersSources } from '../../../map/models/map-layers-sources.enum';
import { recherche, rechercheZoom } from '../state/recherche.actions';
import { FinalRecherche, RechercheListe, GroupeFeatures } from '../models/recherche-liste.model';
import { getRechercheList, getRechercheZoom, isRechercheLoading } from '../state/recherche.selectors';
import { getIsUserMobile } from '../../../core/store/selectors/user.selectors';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Projet } from '../../projet/models/projet.model';
import { StoreName } from '../../offline/models/indexed-db-store-name.enum';
import { Severite } from '../../../enums/severite';
import { MessageService } from 'primeng/api';
import { PointInspection } from '../../projet/models/point-inspection.model';
import { EquipementDto } from '../../../core/api/client/models';
import { UiService } from '../../../services/ui.service';

@Component({
    selector: 'app-recherche-dialog',
    templateUrl: './recherche-dialog.component.html',
    styleUrls: ['./recherche-dialog.component.scss']
})
export class RechercheDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    public RECHERCHE_CONFIG = RechercheConfig;
    public dropdownRechercheList: string[] = [RechercheConfig.RECHERCHE_LCLCL, RechercheConfig.RECHERCHE_CODE_BARRES];
    public premierePage: number = 0;
    public showLine = false;
    public colonnes: TableColumn[] = [];
    public liste: any = [];
    public nombreCaracteres: number = 0;
    public inputMinMaxLength: string = 'null';
    public _totalCaracteres: number = 0;
    public rechercheForm: FormGroup;
    public showAucunResult = false;
    // public displaySig: boolean = false;
    public displayError: boolean = false;
    public displayCount: boolean = false;
    public isUserMobile: boolean = false;
    public storeName: StoreName;

    public rechercheListe$: Observable<RechercheListe | null> = this.store.pipe(
        select(getRechercheList),
        tap((liste: RechercheListe | null) => this.gererValeursRetour(liste))
    );
    public rechercheIsLoading$: Observable<boolean> = this.store.pipe(
        select(isRechercheLoading)
    );

    public rechercheZoom$: Observable<any> = this.store.pipe(
        select(getRechercheZoom),
        tap((value: any) => this.zoomToSelectedLine(value))
    );

    public set totalCaracteres(value: number) {
        this._totalCaracteres = value;
        this.inputMinMaxLength = String(value === 0 ? null : value);
    }

    public get totalCaracteres(): number {
        return this._totalCaracteres;
    }

    private subscriptions: Subscription[] = [];

    @Input() showRechercheDialog: boolean = true;
    @Output() closeRechercheDialog = new EventEmitter<boolean>();

    constructor(
        private mapService: MapService,
        private readonly store: Store,
        private readonly dbService: NgxIndexedDBService,
        private messageService: MessageService,
        private uiService: UiService,
    ) {
        super();
        this.initForm();
        this.subscriptions.push(
            this.rechercheListe$.subscribe(),
            this.rechercheZoom$.subscribe(),
        );
    }

    public ngOnInit(): void {
        this.subscribeToIsUserMobile();

        this.subscriptions.push(
            this.rechercheForm.controls.layerSelect.valueChanges.subscribe((layer: string) => this.onChangeLayer(layer)),
            this.rechercheForm.controls.queryString.valueChanges.subscribe((query: string) => this.evaluer(query)),
            this.rechercheForm.controls.includeSig.valueChanges.subscribe(() => this.onRecherche()),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private evaluateDisplaySig(shouldDisplaySig: boolean) {
        shouldDisplaySig ? this.rechercheForm.controls.includeSig.enable() : this.rechercheForm.controls.includeSig.disable();
    }

    public evaluer(query: string) {
        if (query === '' || query.length === 0) {
            this.displayError = false;
        } else {
            this.rechercheForm.controls.queryString.patchValue(query.toUpperCase(), { emitEvent: false });
            if (this.rechercheForm.controls.layerSelect.value !== RechercheConfig.RECHERCHE_LIGNE) {
                // this.displaySig = query.length === this.totalCaracteres;
                this.evaluateDisplaySig(query.length === this.totalCaracteres);
            }
        }
    }

    public onCloseDialog(event: boolean) {
        this.showRechercheDialog = event;
        this.closeRechercheDialog.emit(event);
    }

    public addQueryStringValidation(value: number, patternName: string) {
        if (patternName === RechercheConfig.RECHERCHE_LCLCL || patternName === RechercheConfig.RECHERCHE_CODE_BARRES) {
            const validations = [Validators.maxLength(value), Validators.minLength(value)];

            if (patternName === RechercheConfig.RECHERCHE_LCLCL) {
                validations.push(HQValidators.lclcl());
            }

            if (patternName === RechercheConfig.RECHERCHE_CODE_BARRES) {
                validations.push(HQValidators.codeabarres());
            }

            this.rechercheForm.controls.queryString.setValidators(validations);
        }
    }

    public onChangeLayer(layer: string) {
        switch (layer) {
            case RechercheConfig.RECHERCHE_LCLCL: this.setUpGui(false, 'LCLCL', layer, 5); break;
            case RechercheConfig.RECHERCHE_LIGNE: this.setUpGui(true, 'Poste Ligne', layer, 0); break;
            case RechercheConfig.RECHERCHE_CODE_BARRES: this.setUpGui(false, 'Code à barres', layer, 6); break;
        }
    }

    public onRecherche() {
        this.uiService.closeActionSheet(true);
        if (this.rechercheForm.valid && this.rechercheForm.controls.queryString.value.length > 0) {
            this.displayError = false;
            this.premierePage = 0;
            this.rechercheByContext();
        } else {
            this.displayError = true;
        }
    }

    public zoomFeature(event: FinalRecherche | Feature) {
        if (event['value'] && this.rechercheForm.controls.layerSelect.value === RechercheConfig.RECHERCHE_LIGNE) {
            this.store.dispatch(rechercheZoom({ value: (event as FinalRecherche) }));
        }
        if (event['properties'] && this.rechercheForm.controls.layerSelect.value === RechercheConfig.RECHERCHE_LCLCL) {
            this.zoomCabLclcl(event as Feature);
        }
        if (event['properties'] && this.rechercheForm.controls.layerSelect.value === RechercheConfig.RECHERCHE_CODE_BARRES) {
            this.zoomCabLclcl(event as Feature);
        }
    }

    public getgroupes(features: FeatureCollection | undefined, source: string) {
        const listgroupe: GroupeFeatures = { groupename: [{ value: '', territoire: '', source: '' }] };
        listgroupe.groupename.splice(0);
        if (features) {
            featureEach(features, (currentFeature: any) => {
                const groupeexistant = listgroupe.groupename.find(obj => obj.value === currentFeature.properties?.valeur);
                const territoireGroupe = listgroupe.groupename.find(obj => obj.territoire === currentFeature.properties?.propriete);
                if (!groupeexistant || (groupeexistant && !territoireGroupe)) {
                    listgroupe.groupename.push({ value: currentFeature.properties!.valeur, territoire: currentFeature.properties!.propriete, source: source });
                }
            });
        }

        listgroupe.groupename.sort((a: FinalRecherche, b: FinalRecherche) => (a.value && b.value) ? (a.value >= b.value) ? 1 : -1 : 0);
        this.liste = listgroupe;
        this.showAucunResult = !(listgroupe.groupename.length > 0);
    }

    private initForm() {
        this.rechercheForm = new FormGroup({
            layerSelect: new FormControl(null, [Validators.required]),
            queryString: new FormControl('', [Validators.required]),
            // includeSig: new FormControl(false),
            includeSig: new FormControl({ value: false, disabled: false }),
        });
    }

    private messageErreur() {
        this.messageService.add({
            severity: Severite.erreur,
            closable: true,
            summary: `Erreur`,
            detail: `Un projet doit être téléchargé pour faire une recherche`
        });
    }

    private rechercheLocalLclclEquipement(projet: Projet | undefined): Feature[] {
        const liste: Feature[] = [];
        const listeLclcl: string[] = [];

        projet.pointInspections?.forEach(pi => {
            if (pi.poteau.equipements && pi.poteau.equipements.length > 0) {
                pi.poteau.equipements.forEach((equipment: EquipementDto) => {
                    if (equipment.lclcl?.toUpperCase() === this.rechercheForm.controls.queryString.value.toUpperCase()) {
                        if (pi.poteau && pi.poteau.geometrie) {
                            const pointInspection: Feature = {
                                type: 'Feature',
                                geometry: JSON.parse(pi.poteau.geometrie),
                                properties: {
                                    'valeur': equipment.lclcl?.toUpperCase(),
                                    'propriete': pi.poteau.territoire ? pi.poteau.territoire : '',
                                    'pointInspectionId': pi.id,
                                    'projet': projet.nom,
                                },
                            };
                            liste.push(pointInspection);
                            listeLclcl.push(equipment.lclcl?.toUpperCase());
                        }
                    }
                });
            }
            // Si un lclcl a été entré manuellement, mais qu'il n'est pas déjà inclus dans la listeLclcl, on l'ajoute
            // à la liste des résultats
            if (pi.poteau.lclcl?.toUpperCase() === this.rechercheForm.controls.queryString.value.toUpperCase()
                && !listeLclcl.includes(pi.poteau.lclcl)) {
                const pointInspection: Feature = {
                    type: 'Feature',
                    geometry: JSON.parse(pi.poteau.geometrie),
                    properties: {
                        'valeur': pi.poteau.lclcl?.toUpperCase() + ' (distant)',
                        'propriete': pi.poteau.territoire ? pi.poteau.territoire : '',
                        'pointInspectionId': pi.id,
                        'projet': projet.nom,
                    },
                };
                liste.push(pointInspection);
            }
        });

        return liste;
    }

    private getLocalDbValuesLclcl() {
        this.subscriptions.push(
            this.dbService.getAll<Projet>(StoreName.PROJETS).pipe(
                map((projetsDownloaded: Projet[]) => {
                    if (!projetsDownloaded?.length) {
                        this.messageErreur();
                        this.liste = [];
                    } else {
                        const liste: FeatureCollection = { features: [], type: 'FeatureCollection' };
                        projetsDownloaded.forEach(projet => {
                            if (projet.pointInspections) {
                                liste.features.push(...this.rechercheLocalLclclEquipement(projet));
                            }
                        });
                        this.liste = this.buildRechercheList(liste, 'Local');
                        this.showAucunResult = !(this.liste.length > 0);
                    }
                })
            ).subscribe()
        );
    }

    private getLocalDbValuesCAB() {
        this.subscriptions.push(
            this.dbService.getAll<Projet>(StoreName.PROJETS).pipe(
                map((projetsDownloaded: Projet[]) => {
                    if (!projetsDownloaded?.length) {
                        this.messageErreur();
                        this.liste = [];
                    } else {
                        const liste: FeatureCollection = { features: [], type: 'FeatureCollection' };
                        projetsDownloaded.forEach(projet => {
                            const pis = projet.pointInspections?.filter(pi => pi.poteau.codeABarres?.toUpperCase()
                                .includes(this.rechercheForm.controls.queryString.value.toUpperCase()));

                            pis?.forEach((feature: PointInspection) => {
                                if (feature.poteau && feature.poteau.geometrie) {
                                    const pointInspection: Feature = {
                                        type: 'Feature',
                                        geometry: JSON.parse(feature.poteau.geometrie),
                                        properties: {
                                            'valeur': feature.poteau.codeABarres?.toUpperCase(),
                                            'propriete': feature.poteau.territoire,
                                            'pointInspectionId': feature.id,
                                            'projet': projet.nom,
                                        },
                                    };
                                    liste.features.push(pointInspection);
                                }
                            });


                        });
                        this.liste = this.buildRechercheList(liste, 'Local');
                        this.showAucunResult = !(this.liste.length > 0);
                    }
                })
            ).subscribe()
        );
    }

    private rechercheByContext() {
        if (this.isUserMobile) {
            if (this.rechercheForm.controls.layerSelect.value === RechercheConfig.RECHERCHE_LCLCL) {
                this.getLocalDbValuesLclcl();
            } else if (this.rechercheForm.controls.layerSelect.value === RechercheConfig.RECHERCHE_CODE_BARRES) {
                this.getLocalDbValuesCAB();
            }
        } else {
            this.dispatchRecherche();
        }
    }

    private zoomCabLclcl(event: Feature) {
        this.mapService.zoomByFeature(event, (event?.properties?.pointInspectionId || null));
    }

    private setUpGui(isLigne: boolean = false, valueHeaderName: string, layer: string, inputLength: number) {
        this.colonnes = [
            { field: 'value', header: valueHeaderName },
        ];
        if (!this.isUserMobile) {
            this.colonnes.push(
                { field: 'territoire', header: 'Territoire' },
                { field: 'source', header: 'Source' },
            );
        } else {
            this.colonnes.push(
                { field: 'projetName', header: 'Projet' },
            );
        }
        this.resetValue(isLigne);
        this.rechercheForm.controls.queryString.clearValidators();
        this.totalCaracteres = inputLength;
        this.addQueryStringValidation(inputLength, layer);
        this.rechercheForm.updateValueAndValidity();

    }

    private resetValue(resetLine: boolean = false) {
        this.rechercheForm.controls.queryString.setValue('');
        this.rechercheForm.controls.includeSig.setValue(resetLine);
        // this.displaySig = false;
        this.displayCount = !resetLine;
        this.showLine = resetLine;
        this.showAucunResult = false;
        this.liste = resetLine ? { groupename: [] } : [];
        this.evaluateDisplaySig(false);
    }

    private dispatchRecherche() {
        this.store.dispatch(recherche({
            name: rechercheBackendUrlValue.get(this.rechercheForm.controls.layerSelect.value),
            value: this.rechercheForm.controls.queryString.value,
            includeSig: this.rechercheForm.controls.includeSig.value,
        }));
    }

    private gererValeursRetour(liste: RechercheListe | null) {
        if (liste) {
            switch (this.rechercheForm.controls.layerSelect.value) {
                case RechercheConfig.RECHERCHE_LIGNE: this.getgroupes(liste.sig, RechercheSource.SIG); break;
                default: this.getRechercheListResultat(liste); break;
            }
        }
    }

    private getRechercheListResultat(liste: RechercheListe) {
        this.liste = this.concatAndSortResult(liste);
        this.showAucunResult = !(this.liste.length > 0);
    }

    private buildRechercheList(features: FeatureCollection | undefined, source: string): Feature[] {
        const buildedFeature: Feature[] = [];
        if (features) {
            features.features.forEach((currentFeature: any) => {
                const properties = this.buildProperties(currentFeature, source);
                const copy = cloneDeep(currentFeature);
                copy.properties = properties;
                buildedFeature.push(copy);
            });
        }

        return buildedFeature;
    }

    private concatAndSortResult(results: RechercheListe): Feature[] {
        const capture = this.buildRechercheList(results.capture, RechercheSource.CAPTURE)
            .sort((a: Feature, b: Feature) => (a.properties?.value && b.properties?.value)
                ? (a.properties.value >= b.properties.value) ? 1 : -1 : 0);
        const sig = this.buildRechercheList(results.sig, RechercheSource.SIG)
            .sort((a: Feature, b: Feature) => (a.properties?.value && b.properties?.value)
                ? (a.properties.value >= b.properties.value) ? 1 : -1 : 0);
        return capture.concat(sig);
    }

    private buildProperties(features: Feature, source: string): FinalRecherche {
        return {
            territoire: features.properties?.propriete,
            value: features.properties?.valeur,
            pointInspectionId: features.properties?.pointInspectionId,
            source: source,
            projetName: features.properties?.projet ?? '',
        } as FinalRecherche;
    }

    private zoomToSelectedLine(rechercheList: RechercheListe | null) {
        if (rechercheList && rechercheList.sig) {
            const bounds: BBox = bbox(rechercheList.sig);
            const rechercheFeatures = rechercheList.sig;
            if (rechercheFeatures.features.length > 0) {
                (this.mapService.map.getSource(MapLayersSources.RECHERCHE_LIGNE) as GeoJSONSource).setData(rechercheFeatures);
                this.mapService.map.fitBounds(bounds as LngLatBoundsLike, { padding: 3 });
            }
        }
    }

    private setUpForMobile() {
        if (!this.isUserMobile) {
            this.dropdownRechercheList.push(RechercheConfig.RECHERCHE_LIGNE);
        }
    }

    private subscribeToIsUserMobile() {
        this.store.select(getIsUserMobile).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isUserMobile => {
            this.isUserMobile = _isUserMobile;
            this.setUpForMobile();
        });
    }
}
