export enum ProjetTooltips {
    filtreGlobal = 'Recherche toujours dans toutes les colonnes; sauf les dates',
    resetFilter = 'Réinitialiser les filtres de colonnes et le filtre global',
    rowsOnly = 'Extraire seulement les projets filtrés',
    columnsOnly = 'Extraire seulement les colonnes sélectionnées',
    csv = 'Exporter les données en format CSV',
    xls = 'Exporter les données en format Excel',
    dropdown = 'Sélectionner une option',
    multiSelect = 'Sélectionner une ou plusieurs options',
    multiSelectColumns = 'Sélectionner les colonnes à afficher',
    resetInterface = `Réinitialise tout :
        Effaces les filtres et les colonnes sélectionnées`,
    text = `"texte" valeur rechercher,
        ! valeurs vides
        * valeurs non vides`,
    number = `"texte" valeur rechercher,
        ! valeurs vides ou 0
        * valeurs non vides
        < X plus petit que X
        > X plus grand que X
        = X valeur exacte`
}
