import { Injectable } from '@angular/core';
import { BookType, JSON2SheetOpts, utils, WorkBook, write } from 'xlsx';
import { dateTimeChange, saveFile } from '../../../shared/utils';

interface NomWise {
    nom?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ExcelExtractionService {
    public exportCsv<T>(exportedProjets: Partial<T[]>, options?: JSON2SheetOpts) {
        this.exportInFormat<T>('Liste des projets', 'csv', 'liste_projets', exportedProjets, options);
    }

    public exportExcel<T>(exportedProjets: Partial<T[]>, options?: JSON2SheetOpts) {
        this.exportInFormat<T>('Liste des projets', 'xlsx', 'liste_projets', exportedProjets, options);
    }

    public exportExcelProjetHistory<T extends NomWise>(exportedHistory: Partial<T[]>) {
        const name = (exportedHistory?.length > 0 && exportedHistory[0].nom) ? exportedHistory[0].nom : 'historique';
        const fileName = name + '_' + dateTimeChange(Date.now().toString(), 'YYYYMMdd');
        this.exportInFormat<T>(fileName, 'xlsx', 'historique_projet', exportedHistory);
    }

    ///////////////////////////////////////////
    ///////////  Private Functions  ///////////
    ///////////////////////////////////////////

    private exportInFormat<T>(fileTitle: string, bookType: BookType, sheetName: string, exportedData: Partial<T>[], options: JSON2SheetOpts | undefined = undefined) {
        const worksheet = utils.json_to_sheet(exportedData, options);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
        const excelBuffer: any = write(workbook, { bookType: bookType, type: 'array' });
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        saveFile(excelBuffer, fileTitle, FILE_TYPE, `.${bookType}`);
    }
}
