<div class="p-d-flex p-flex-column p-jc-center" [formGroup]="form">
    <div class="p-mb-2 p-ai-end">Choisissez l'auditeur qui réalisera le projet :
        <p-dropdown [options]="auditeursList$ | async" placeholder="Choisir un élément"
            formControlName="auditeurSelected" appendTo="body"></p-dropdown>
    </div>
</div>
<div class="custom-dialog-footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="close()"></button>
    <button [disabled]="form.invalid" class="p-button-text" pButton pRipple icon="pi pi-check" label="Enregistrer"
        (click)="onSave()"></button>
</div>
