import { LayerGroupe } from '../../enums/layer-groupe';
import { StatutPointInspection, StatutPointInspectionValue } from '../../features/inspection/models/statut-point-inspection.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const POINTS_INSPECTION_PRIVE_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle('nonInspecte'),
    generateStyle('urgence'),
    generateStyle('anomaliePrioriteC'),
    generateStyle('anomaliePrioriteE'),
    generateStyle('anomaliePrioriteG'),
    generateStyle('anomaliePrioriteK'),
    generateStyle('anomaliePrioriteM'),
    generateStyle('anomaliePrioriteN'),
    generateStyle('sansAnomalie'),
    generateStyle('ignore'),
    generateStyle('exclu'),
];

function generateStyle(identifier: string): StyleLayer {
    return {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION_PRIVE,
        'value': {
            'id': `${StatutPointInspectionValue[identifier as StatutPointInspection]} (Privé)`,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['all',
                ['has', 'Statut'],
                ['has', 'Propriétaire'],
                ['==', 'Propriétaire', 'prive'],
                ['==', 'Statut', StatutPointInspection[identifier as StatutPointInspection]],
            ],
            'layout': { 'visibility': 'visible' },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'circle-color': mapStyleConfig.colors[identifier],
                'circle-radius': mapStyleConfig.poteau.privateCircleWidth,
                'circle-stroke-color': mapStyleConfig.colors.private,
                'circle-stroke-width': mapStyleConfig.poteau.privateCircleStroke,
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        },
    };
}
