/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProjetBaseDtoSearchResult } from '../models/projet-base-dto-search-result';
import { CreatedProjetDto } from '../models/created-projet-dto';
import { CreateProjetDto } from '../models/create-projet-dto';
import { PhotoEntitySearchResult } from '../models/photo-entity-search-result';
import { ProjetCompletDto } from '../models/projet-complet-dto';
import { ProjetBaseDto } from '../models/projet-base-dto';
import { PatchProjetInspectionDto } from '../models/patch-projet-inspection-dto';
import { ProjetAvecHistoriqueBaseDto } from '../models/projet-avec-historique-base-dto';
import { PointInspectionDto } from '../models/point-inspection-dto';
import { AnomalieBaseDto } from '../models/anomalie-base-dto';
import { CreatedProjetAuditDto } from '../models/created-projet-audit-dto';
import { CreateProjetAuditDto } from '../models/create-projet-audit-dto';
import { ProjetStatutExtractionPhotosDto } from '../models/projet-statut-extraction-photos-dto';
@Injectable({
    providedIn: 'root',
})
class ProjetService extends __BaseService {
    static readonly getApiV1ProjetsIdStatutPath = '/api/v1/projets/{id}/statut';
    static readonly getApiV1ProjetsPath = '/api/v1/projets';
    static readonly postApiV1ProjetsPath = '/api/v1/projets';
    static readonly getApiV1ProjetsIdPhotosPath = '/api/v1/projets/{id}/photos';
    static readonly getApiV1ProjetsIdPath = '/api/v1/projets/{id}';
    static readonly deleteApiV1ProjetsIdPath = '/api/v1/projets/{id}';
    static readonly putApiV1ProjetsIdPath = '/api/v1/projets/{id}';
    static readonly patchApiV1ProjetsIdPath = '/api/v1/projets/{id}';
    static readonly getApiV1ProjetsIdHistoriquePath = '/api/v1/projets/{id}/historique';
    static readonly postApiV1ProjetsIdInspectionsPath = '/api/v1/projets/{id}/inspections';
    static readonly putApiV1ProjetsIdInspectionInspectionIdAnomaliesPath = '/api/v1/projets/{id}/inspection/{inspectionId}/anomalies';
    static readonly postApiV1ProjetsIdAvisPath = '/api/v1/projets/{id}/avis';
    static readonly getApiV1ProjetsIdAvisPath = '/api/v1/projets/{id}/avis';
    static readonly postApiV1ProjetsIdAuditsPath = '/api/v1/projets/{id}/audits';
    static readonly getApiV1ProjetsIdExtractionPhotosPath = '/api/v1/projets/{id}/extractionPhotos';
    static readonly postApiV1ProjetsIdExtractionPhotosPath = '/api/v1/projets/{id}/extractionPhotos';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdStatutResponse(id: string): __Observable<__StrictHttpResponse<string>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}/statut`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'text'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<string>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdStatut(id: string): __Observable<string> {
        return this.getApiV1ProjetsIdStatutResponse(id).pipe(
            __map(_r => _r.body as string)
        );
    }

    /**
     * @param params The `ProjetService.GetApiV1ProjetsParams` containing the following parameters:
     *
     * - `type`:
     *
     * - `statut`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `inspectionDateDeFinde`:
     *
     * - `inspectionDateDeFinA`:
     *
     * - `inspectionAnneeDe`:
     *
     * - `inspectionAnneeA`:
     *
     * - `inspectionAnnee`:
     *
     * - `inclureProjetAudit`:
     *
     * - `firme`:
     *
     * - `fields`:
     *
     * - `dateDeFinPrevisionnelleDe`:
     *
     * - `dateDeFinPrevisionnelleA`:
     *
     * - `assigneA`:
     *
     * @return Success
     */
    getApiV1ProjetsResponse(params: ProjetService.GetApiV1ProjetsParams): __Observable<__StrictHttpResponse<ProjetBaseDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.type != null) __params = __params.set('type', params.type.toString());
        if (params.statut != null) __params = __params.set('statut', params.statut.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        if (params.inspectionDateDeFinde != null) __params = __params.set('inspectionDateDeFinde', params.inspectionDateDeFinde.toString());
        if (params.inspectionDateDeFinA != null) __params = __params.set('inspectionDateDeFinA', params.inspectionDateDeFinA.toString());
        if (params.inspectionAnneeDe != null) __params = __params.set('inspectionAnneeDe', params.inspectionAnneeDe.toString());
        if (params.inspectionAnneeA != null) __params = __params.set('inspectionAnneeA', params.inspectionAnneeA.toString());
        if (params.inspectionAnnee != null) __params = __params.set('inspectionAnnee', params.inspectionAnnee.toString());
        if (params.inclureProjetAudit != null) __params = __params.set('inclureProjetAudit', params.inclureProjetAudit.toString());
        if (params.firme != null) __params = __params.set('firme', params.firme.toString());
        (params.fields || []).forEach(val => { if (val != null) __params = __params.append('fields', val.toString()) });
        if (params.dateDeFinPrevisionnelleDe != null) __params = __params.set('dateDeFinPrevisionnelleDe', params.dateDeFinPrevisionnelleDe.toString());
        if (params.dateDeFinPrevisionnelleA != null) __params = __params.set('dateDeFinPrevisionnelleA', params.dateDeFinPrevisionnelleA.toString());
        if (params.assigneA != null) __params = __params.set('assigneA', params.assigneA.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetBaseDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `ProjetService.GetApiV1ProjetsParams` containing the following parameters:
     *
     * - `type`:
     *
     * - `statut`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `inspectionDateDeFinde`:
     *
     * - `inspectionDateDeFinA`:
     *
     * - `inspectionAnneeDe`:
     *
     * - `inspectionAnneeA`:
     *
     * - `inspectionAnnee`:
     *
     * - `inclureProjetAudit`:
     *
     * - `firme`:
     *
     * - `fields`:
     *
     * - `dateDeFinPrevisionnelleDe`:
     *
     * - `dateDeFinPrevisionnelleA`:
     *
     * - `assigneA`:
     *
     * @return Success
     */
    getApiV1Projets(params: ProjetService.GetApiV1ProjetsParams): __Observable<ProjetBaseDtoSearchResult> {
        return this.getApiV1ProjetsResponse(params).pipe(
            __map(_r => _r.body as ProjetBaseDtoSearchResult)
        );
    }

    /**
     * @param body undefined
     * @return Created
     */
    postApiV1ProjetsResponse(body?: CreateProjetDto): __Observable<__StrictHttpResponse<CreatedProjetDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/projets`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<CreatedProjetDto>;
            })
        );
    }
    /**
     * @param body undefined
     * @return Created
     */
    postApiV1Projets(body?: CreateProjetDto): __Observable<CreatedProjetDto> {
        return this.postApiV1ProjetsResponse(body).pipe(
            __map(_r => _r.body as CreatedProjetDto)
        );
    }

    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdPhotosParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `type`:
  *
         * - `taille`:
     *
     * - `PageSize`:
     *
     * - `PageNumber`:
     *
     * @return Success
     */
    getApiV1ProjetsIdPhotosResponse(params: ProjetService.GetApiV1ProjetsIdPhotosParams): __Observable<__StrictHttpResponse<PhotoEntitySearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.type != null) __params = __params.set('type', params.type.toString());
        if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
        if (params.PageNumber != null) __params = __params.set('PageNumber', params.PageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PhotoEntitySearchResult>;
            })
        );
    }
    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdPhotosParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `type`:
  *
         * - `taille`:
     *
     * - `PageSize`:
     *
     * - `PageNumber`:
     *
     * @return Success
     */
    getApiV1ProjetsIdPhotos(params: ProjetService.GetApiV1ProjetsIdPhotosParams): __Observable<PhotoEntitySearchResult> {
        return this.getApiV1ProjetsIdPhotosResponse(params).pipe(
            __map(_r => _r.body as PhotoEntitySearchResult)
        );
    }

    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `expandEquipement`:
     *
     * @return Success
     */
    getApiV1ProjetsIdResponse(params: ProjetService.GetApiV1ProjetsIdParams): __Observable<__StrictHttpResponse<ProjetCompletDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        if (params.expandEquipement != null) __params = __params.set('expandEquipement', params.expandEquipement.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetCompletDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `expandEquipement`:
     *
     * @return Success
     */
    getApiV1ProjetsId(params: ProjetService.GetApiV1ProjetsIdParams): __Observable<ProjetCompletDto> {
        return this.getApiV1ProjetsIdResponse(params).pipe(
            __map(_r => _r.body as ProjetCompletDto)
        );
    }

    /**
     * @param id undefined
     */
    deleteApiV1ProjetsIdResponse(id: string): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param id undefined
     */
    deleteApiV1ProjetsId(id: string): __Observable<null> {
        return this.deleteApiV1ProjetsIdResponse(id).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `ProjetService.PutApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1ProjetsIdResponse(params: ProjetService.PutApiV1ProjetsIdParams): __Observable<__StrictHttpResponse<ProjetBaseDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetBaseDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.PutApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1ProjetsId(params: ProjetService.PutApiV1ProjetsIdParams): __Observable<ProjetBaseDto> {
        return this.putApiV1ProjetsIdResponse(params).pipe(
            __map(_r => _r.body as ProjetBaseDto)
        );
    }

    /**
     * @param params The `ProjetService.PatchApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    patchApiV1ProjetsIdResponse(params: ProjetService.PatchApiV1ProjetsIdParams): __Observable<__StrictHttpResponse<ProjetBaseDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetBaseDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.PatchApiV1ProjetsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    patchApiV1ProjetsId(params: ProjetService.PatchApiV1ProjetsIdParams): __Observable<ProjetBaseDto> {
        return this.patchApiV1ProjetsIdResponse(params).pipe(
            __map(_r => _r.body as ProjetBaseDto)
        );
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdHistoriqueResponse(id: string): __Observable<__StrictHttpResponse<ProjetAvecHistoriqueBaseDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}/historique`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAvecHistoriqueBaseDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdHistorique(id: string): __Observable<ProjetAvecHistoriqueBaseDto> {
        return this.getApiV1ProjetsIdHistoriqueResponse(id).pipe(
            __map(_r => _r.body as ProjetAvecHistoriqueBaseDto)
        );
    }

    /**
     * @param params The `ProjetService.PostApiV1ProjetsIdInspectionsParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Created
     */
    postApiV1ProjetsIdInspectionsResponse(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): __Observable<__StrictHttpResponse<PointInspectionDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}/inspections`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointInspectionDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.PostApiV1ProjetsIdInspectionsParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Created
     */
    postApiV1ProjetsIdInspections(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): __Observable<PointInspectionDto> {
        return this.postApiV1ProjetsIdInspectionsResponse(params).pipe(
            __map(_r => _r.body as PointInspectionDto)
        );
    }

    /**
     * @param params The `ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1ProjetsIdInspectionInspectionIdAnomaliesResponse(params: ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams): __Observable<__StrictHttpResponse<AnomalieBaseDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        __body = params.body;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}/inspection/${encodeURIComponent(String(params.inspectionId))}/anomalies`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<AnomalieBaseDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1ProjetsIdInspectionInspectionIdAnomalies(params: ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams): __Observable<AnomalieBaseDto> {
        return this.putApiV1ProjetsIdInspectionInspectionIdAnomaliesResponse(params).pipe(
            __map(_r => _r.body as AnomalieBaseDto)
        );
    }

    /**
     * @param id undefined
     */
    postApiV1ProjetsIdAvisResponse(id: string): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}/avis`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param id undefined
     */
    postApiV1ProjetsIdAvis(id: string): __Observable<null> {
        return this.postApiV1ProjetsIdAvisResponse(id).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdAvisParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     */
    getApiV1ProjetsIdAvisResponse(params: ProjetService.GetApiV1ProjetsIdAvisParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}/avis`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `ProjetService.GetApiV1ProjetsIdAvisParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     */
    getApiV1ProjetsIdAvis(params: ProjetService.GetApiV1ProjetsIdAvisParams): __Observable<null> {
        return this.getApiV1ProjetsIdAvisResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `ProjetService.PostApiV1ProjetsIdAuditsParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Created
     */
    postApiV1ProjetsIdAuditsResponse(params: ProjetService.PostApiV1ProjetsIdAuditsParams): __Observable<__StrictHttpResponse<CreatedProjetAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(params.id))}/audits`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<CreatedProjetAuditDto>;
            })
        );
    }
    /**
     * @param params The `ProjetService.PostApiV1ProjetsIdAuditsParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Created
     */
    postApiV1ProjetsIdAudits(params: ProjetService.PostApiV1ProjetsIdAuditsParams): __Observable<CreatedProjetAuditDto> {
        return this.postApiV1ProjetsIdAuditsResponse(params).pipe(
            __map(_r => _r.body as CreatedProjetAuditDto)
        );
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdExtractionPhotosResponse(id: string): __Observable<__StrictHttpResponse<ProjetStatutExtractionPhotosDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}/extractionPhotos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetStatutExtractionPhotosDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1ProjetsIdExtractionPhotos(id: string): __Observable<ProjetStatutExtractionPhotosDto> {
        return this.getApiV1ProjetsIdExtractionPhotosResponse(id).pipe(
            __map(_r => _r.body as ProjetStatutExtractionPhotosDto)
        );
    }

    /**
     * @param id undefined
     * @return Created
     */
    postApiV1ProjetsIdExtractionPhotosResponse(id: string): __Observable<__StrictHttpResponse<ProjetStatutExtractionPhotosDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/projets/${encodeURIComponent(String(id))}/extractionPhotos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetStatutExtractionPhotosDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Created
     */
    postApiV1ProjetsIdExtractionPhotos(id: string): __Observable<ProjetStatutExtractionPhotosDto> {
        return this.postApiV1ProjetsIdExtractionPhotosResponse(id).pipe(
            __map(_r => _r.body as ProjetStatutExtractionPhotosDto)
        );
    }
}

module ProjetService {

    /**
     * Parameters for getApiV1Projets
     */
    export interface GetApiV1ProjetsParams {
        type?: string;
        statut?: string;
        pageSize?: number;
        pageNumber?: number;
        inspectionDateDeFinde?: number;
        inspectionDateDeFinA?: number;
        inspectionAnneeDe?: number;
        inspectionAnneeA?: number;
        inspectionAnnee?: number;
        inclureProjetAudit?: boolean;
        firme?: string;
        fields?: Array<string>;
        dateDeFinPrevisionnelleDe?: number;
        dateDeFinPrevisionnelleA?: number;
        assigneA?: string;
    }

    /**
     * Parameters for getApiV1ProjetsIdPhotos
     */
    export interface GetApiV1ProjetsIdPhotosParams {
        id: string;
        type?: any;
        PageSize?: number;
        PageNumber?: number;
    }

    /**
     * Parameters for getApiV1ProjetsId
     */
    export interface GetApiV1ProjetsIdParams {
        id: string;
        pageSize?: number;
        pageNumber?: number;
        expandEquipement?: boolean;
    }

    /**
     * Parameters for putApiV1ProjetsId
     */
    export interface PutApiV1ProjetsIdParams {
        id: string;
        body?: ProjetBaseDto;
    }

    /**
     * Parameters for patchApiV1ProjetsId
     */
    export interface PatchApiV1ProjetsIdParams {
        id: string;
        body?: PatchProjetInspectionDto;
    }

    /**
     * Parameters for postApiV1ProjetsIdInspections
     */
    export interface PostApiV1ProjetsIdInspectionsParams {
        id: string;
        body?: PointInspectionDto;
    }

    /**
     * Parameters for putApiV1ProjetsIdInspectionInspectionIdAnomalies
     */
    export interface PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams {
        inspectionId: string;
        id: string;
        body?: AnomalieBaseDto;
    }

    /**
     * Parameters for getApiV1ProjetsIdAvis
     */
    export interface GetApiV1ProjetsIdAvisParams {
        id: string;
        pageSize?: number;
        pageNumber?: number;
    }

    /**
     * Parameters for postApiV1ProjetsIdAudits
     */
    export interface PostApiV1ProjetsIdAuditsParams {
        id: string;
        body?: CreateProjetAuditDto;
    }
}

export { ProjetService }
