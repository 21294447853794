import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { State } from '../../../../../state/app.state';
import * as ProjetsActions from '../../../state/projets.actions';
import { getCreateProjetAuditLoading, getCreateProjetAuditSuccess } from '../../../state/projets.selectors';
import { CreateProjetAuditDto, ProjetCompletDto } from '../../../../../core/api/client/models';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjetsActionBaseDialog } from '../projets-action-base-dialog';

@Component({
    selector: 'app-create-projet-audit-dialog',
    templateUrl: './create-projet-audit-dialog.component.html',
    styleUrls: ['./create-projet-audit-dialog.component.scss']
})
export class CreateProjetAuditDialogComponent extends ProjetsActionBaseDialog implements OnInit {

    public form: FormGroup;
    public saveLoading = false;

    private _projet: ProjetCompletDto;

    private set projet(value: ProjetCompletDto) {
        this._projet = value;
        if (this._projet) {
            this.initForm();
        }
    }
    private get projet(): ProjetCompletDto {
        return this._projet;
    }

    constructor(
        private messageService: MessageService,
        private store: Store<State>,
        private config: DynamicDialogConfig,
        protected ref: DynamicDialogRef,
        protected confirmationService: ConfirmationService,
    ) {
        super(ref, confirmationService);
        this.projet = this.config.data.selectedProjet;
    }

    ngOnInit() {
        this.subscribeToCreateProjetAuditLoading();
        this.subscribeToProjetAuditCreateSuccess();
    }

    private initForm() {
        this.form = new FormGroup({
            seuilPourcentage: new FormControl(null, [Validators.pattern('[0-9]+'), Validators.min(1), Validators.max(100)]),
            seuilEntier: new FormControl(null, [Validators.pattern('[0-9]+'), Validators.min(1), Validators.max(this._projet.nombreTotalPoteaux!)]),
            nom: new FormControl(this._projet.nom + '_AUDIT'),
        });
    }

    private subscribeToCreateProjetAuditLoading() {
        this.store.select(getCreateProjetAuditLoading)
            .pipe(takeUntil(this.destroyed))
            .subscribe(loading => this.saveLoading = loading);
    }

    private subscribeToProjetAuditCreateSuccess() {
        this.store.select(getCreateProjetAuditSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(createResult => {
                if (createResult.success) {
                    this.messageService.add(
                        {
                            severity: 'success',
                            closable: true,
                            summary: `Création de projet d'audit`,
                            detail: `Le projet ${createResult.projetAudit?.nom} a bien été créé.`,
                            life: 15000
                        });

                    this.closeDialog();
                }
            });
    }

    public onSave() {
        if (this.form.valid) {
            const projetAudit: CreateProjetAuditDto = {
                seuilPourcentage: this.form.controls.seuilPourcentage.value,
                seuilEntier: this.form.controls.seuilEntier.value,
            };

            this.store.dispatch(ProjetsActions.startCreateProjetAudit({ projetId: this.projet?.id, projetAudit }));
        }
    }
}
