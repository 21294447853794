import { Component, Input } from '@angular/core';
import { combineLatest, Observable, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

import { State } from '../../../../state/app.state';
import { ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { TableColumn } from '../../../../shared/models/table-column.model';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';

import { actionColumn } from '../../models/action.column';
import { fullAuditsColumn } from '../../models/full-audits.column';
import { fullInspectionsColumn } from '../../models/full-inspections.column';
import { getGroupeFullTaxonomie } from '../../../../core/store/selectors/taxonomie.selectors';

import { Taxonomie } from '../../../pilotage/models/taxonomie.model';
import { TaxonomieGroupeValue } from '../../../pilotage/models/taxonomie-groupe.enum';
import { ProjetsListDialog } from '../../models/projets-list-dialog.model';
import { STATUT_PROJETS_INSPECTION_NAME, StatutProjetsInspection } from '../../models/statut-projets-inspection.enum';
import { StatutProjetAudit, statutProjetAuditName } from '../../../audit/models/statut-projet-audit.enum';
import { ProjetDataType } from '../../models/projet-data.type';
import { ProjetType } from '../../models/projet-type.enum';

@Component({
    selector: 'app-projets-list',
    templateUrl: './projets-list.component.html',
    styleUrls: ['./projets-list.component.scss']
})
export class ProjetsListComponent extends BaseComponent {
    public firmeList: Taxonomie[] = [];
    public firmeAuditList: Taxonomie[] = [];
    public columns: TableColumn[] = [];

    public projetsList: ProjetCompletDto[];

    public firme$: Observable<Taxonomie[]> = this.store.select(getGroupeFullTaxonomie(TaxonomieGroupeValue.FIRME));
    public firmeAudit$: Observable<Taxonomie[]> = this.store.select(getGroupeFullTaxonomie(TaxonomieGroupeValue.FIRME_AUDIT));

    @Input() projetDataType: ProjetDataType;

    @Input() set projets(value: ProjetCompletDto[] | ProjetAuditDto[]) {
        combineLatest([this.firme$, this.firmeAudit$]).pipe(
            takeUntil(this.destroyed)
        ).subscribe(([firmes, firmesAudit]) => {
            this.getAccessRight();
            this.firmeList = firmes;
            this.firmeAuditList = firmesAudit;
            this.projetsList = this.mapData(value);
        });
    }

    get projets(): ProjetsListDialog[] {
        return this.projetsList;
    }

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    private getAccessRight(): void {
        switch (this.projetDataType) {
            case ProjetType.AUDIT: {
                this.columns = [...fullAuditsColumn];
            } break;
            case ProjetType.INSPECTION: {
                this.columns = [...fullInspectionsColumn];
            } break;
            case ProjetType.AUDIT_INSPECTION: {
                this.columns = [...fullInspectionsColumn, ...fullAuditsColumn];
            } break;
            default: {
                this.columns = [];
            } break;
        }
        this.columns = this.columns.length > 0 ? [...this.columns, ...actionColumn] : [];
    }

    private getFirme(firmeId: string | undefined, isAudit: boolean = false): string {
        const firmes = isAudit ? this.firmeAuditList : this.firmeList;
        const taxonomie = firmes.filter((firme: Taxonomie) => firme.id === firmeId);
        if (taxonomie.length === 1) {
            return taxonomie[0].code;
        }
        return 'À déterminer';
    }

    private mapSpecificField(projet: ProjetsListDialog, field: string): string {
        switch (field) {
            case 'firme': return projet.firme ? this.getFirme(projet.firme) : 'À déterminer';
            case 'statut': return STATUT_PROJETS_INSPECTION_NAME.get(projet.statut as StatutProjetsInspection);
            case 'firmeAudit': return projet.audit?.firme ? this.getFirme(projet.audit.firme, true) : 'À déterminer';
            case 'statutAudit': {
                if (!projet.audit) { return ''; }
                const val = projet.audit.statut as StatutProjetAudit;
                const statut = statutProjetAuditName.get(val);
                return statut;
            };
            default: return '';
        }
    }

    private mapData(projets: ProjetCompletDto[] | ProjetAuditDto[]): ProjetsListDialog[] {
        return this.projetDataType === ProjetType.AUDIT ? this.mapProjetAuditDataOnly(projets as ProjetAuditDto[]) : this.mapAllData(projets as ProjetCompletDto[]);
    }

    private mapProjetAuditDataOnly(projetAudits: ProjetAuditDto[]): ProjetsListDialog[] {
        return projetAudits.map((projetAudit: ProjetAuditDto) => this.mapProjetAuditData(projetAudit));
    }

    private mapAllData(projets: ProjetCompletDto[]): ProjetsListDialog[] {
        return projets.map((projet: ProjetCompletDto) => {
            const projetAudit = projet.audits.length > 0 ? this.mapProjetAuditData(projet.audits[0]) : undefined;
            const projetFormatted: ProjetsListDialog = {
                ...projet,
                ...projetAudit,
            };

            return {
                ...projetFormatted,
                firmeName: this.mapSpecificField(projetFormatted, 'firme'),
                statutProjetName: this.mapSpecificField(projetFormatted, 'statut'),
            };
        });
    }

    private mapProjetAuditData(projetAudit: ProjetAuditDto): ProjetsListDialog {
        const projetAuditData = {
            audit: projetAudit,
        } as ProjetsListDialog;

        return {
            ...projetAuditData,
            firmeAuditName: this.mapSpecificField(projetAuditData, 'firmeAudit'),
            statutProjetAuditName: this.mapSpecificField(projetAuditData, 'statutAudit'),
        };
    }


}
