/* eslint-disable @typescript-eslint/naming-convention */

import { StyleLayer } from '../map/models/style-layer.model';
import { LayerType } from '../map/models/layer-type.enum';
import { ANOMALIES_STYLE_LEGENDE } from './styles/anomalies.style.legend';
import { ENVIRONNEMENT_STYLE_LEGENDE } from './styles/environnement.style.legend';
import { PARC_POTEAUX_STYLE_LEGENDE } from './styles/parc-poteaux.style.legend';
import { POINTS_AUDIT_STYLE_LEGENDE } from './styles/points-audit.style.legend';
import { POINTS_INSPECTION_STYLE_LEGENDE } from './styles/points-inspection.style.legend';
import { POINTS_INSPECTION_PRIVE_STYLE_LEGENDE } from './styles/points-inspection-prive.style.legend';
import { PROJETS_AUDIT_STYLE_LEGENDE } from './styles/projets-audit.style.legend';
import { PROJETS_INSPECTION_STYLE_LEGENDE } from './styles/projets-inspection.style.legend';

export const styles: StyleLayer[] = [
    {
        'urlimage': ['assets/images/general/Poteau.png'],
        'value': {
            'id': 'Poteau',
            'type': LayerType.SYMBOL,
            'source': 'Poteau',
            'source-layer': 'Poteau',
            'minzoom': 16,
            'layout': { 'icon-image': 'Poteau', 'icon-allow-overlap': true },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Biphasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 0], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#BEE8FF', 'line-dasharray': [3, 2], 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Monophasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 1], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#73DFFF', 'line-dasharray': [3, 2], 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Triphasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 2], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#73DFFF', 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Monophasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 0],
            'minzoom': 10,
            'layout': {
                'line-join': 'round'
            },
            'paint': {
                'line-color':
                    '#DC7300', 'line-dasharray': [3, 2],
                'line-width': 1.66667
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Biphasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 1],
            'minzoom': 10,
            'layout': { 'line-join': 'round' },
            'paint': {
                'line-color': '#DC7300',
                'line-dasharray': [3, 2],
                'line-width': 2.33333
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Triphasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 2],
            'minzoom': 10,
            'layout': { 'line-join': 'round' },
            'paint': {
                'line-color': '#DC7300',
                'line-width': 2.33333
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/label/Classe d’étiquettes 1',
            'type': LayerType.SYMBOL,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien/label',
            'minzoom': 16,
            'layout': {
                'symbol-placement': LayerType.LINE,
                'symbol-spacing': 1000,
                'text-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'],
                'text-size': 12,
                'text-anchor': 'bottom',
                'text-offset': [0, -0.111111],
                'text-field': '{_name}',
                'text-optional': true
            },
            'paint': {
                'text-color': '#DC7300',
                'text-halo-color': '#FFFFFF',
                'text-halo-width': 1
            }
        }
    },
    {
        'urlimage': ['assets/images/general/Poste.png'],
        'value': {
            'id': 'Poste',
            'type': LayerType.SYMBOL,
            'source': 'Poste',
            'source-layer': 'Poste',
            'minzoom': 9,
            'layout': {
                'icon-image': 'Poste', 'icon-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    /*{
        'urlimage': ['assets/images/general/LCLCL.png'],
        'value': {
            'id': 'LCLCL',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL',
            'source-layer': 'LCLCL',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [0.470711, -0.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },*/
    {
        'urlimage': ['assets/images/general/LCLCL-SO.png'],
        'value': {
            'id': 'LCLCL aérien/SO',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/SO',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/SO', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DL',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DL',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DL', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DF',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DF',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DF', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DJ',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DJ',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DJ', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DK',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DK',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DK', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-CC.png'],
        'value': {
            'id': 'LCLCL aérien/CC',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/CC',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/CC',
                'icon-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'],
                'text-size': 13.3333,
                'text-anchor': 'bottom-left',
                'text-justify': 'left',
                'text-offset': [10.470711, -10.170711],
                'text-field': '{_name}',
                'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-autre.png'],
        'value': {
            'id': 'LCLCL aérien/Autres',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/Autres',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/Autres', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/Vegetation.png'],
        'value': {
            'id': 'Vegetation',
            'type': LayerType.SYMBOL,
            'source': 'Vegetation',
            'source-layer': 'Vegetation',
            'minzoom': 9,
            'layout': {
                'icon-image': 'Vegetation', 'icon-allow-overlap': true, 'visibility': 'none',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/selected.png'],
        'value': {
            'id': 'selected',
            'type': LayerType.SYMBOL,
            'source': 'selected',
            'source-layer': 'selected',
            'minzoom': 9,
            'layout': {
                'icon-image': 'a-auditer', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/a-auditer.png'],
        'value': {
            'id': 'a-auditer',
            'type': LayerType.SYMBOL,
            'source': 'a-auditer',
            'source-layer': 'a-auditer',
            'minzoom': 9,
            'layout': {
                'icon-image': 'a-auditer', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/ignore.png'],
        'value': {
            'id': 'ignore',
            'type': LayerType.SYMBOL,
            'source': 'ignore',
            'source-layer': 'ignore',
            'minzoom': 9,
            'layout': {
                'icon-image': 'ignore', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/conforme.png'],
        'value': {
            'id': 'conforme',
            'type': LayerType.SYMBOL,
            'source': 'conforme',
            'source-layer': 'conforme',
            'minzoom': 9,
            'layout': {
                'icon-image': 'conforme', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/non-conforme.png'],
        'value': {
            'id': 'non-conforme',
            'type': LayerType.SYMBOL,
            'source': 'non-conforme',
            'source-layer': 'non-conforme',
            'minzoom': 9,
            'layout': {
                'icon-image': 'non-conforme', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
];

export const styleLegende: StyleLayer[] = [
    ...PARC_POTEAUX_STYLE_LEGENDE,
    ...PROJETS_AUDIT_STYLE_LEGENDE,
    ...PROJETS_INSPECTION_STYLE_LEGENDE,
    ...POINTS_AUDIT_STYLE_LEGENDE,
    ...POINTS_INSPECTION_STYLE_LEGENDE,
    ...POINTS_INSPECTION_PRIVE_STYLE_LEGENDE,
    ...ANOMALIES_STYLE_LEGENDE,
    ...ENVIRONNEMENT_STYLE_LEGENDE
];
