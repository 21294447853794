import { TableColumn } from '../../../shared/models/table-column.model';

export const fullAuditsColumn: TableColumn[] = [
    {
        field: 'audit.nom', fieldObj: 'audit', fieldProp: 'nom', header: 'Nom du projet audit', group: 'audit',
        toggleable: true, sortable: true, filterable: true, showByDefault: true
    },
    {
        field: 'audit.creeLe', fieldObj: 'audit', fieldProp: 'creeLe', header: 'Audit créé le', group: 'audit',
        toggleable: true, sortable: true, filterable: true, isDate: true, showByDefault: true
    },
    {
        field: 'statutProjetAuditName', fieldObj: 'audit', fieldProp: 'statut', header: 'Audit statut', group: 'audit',
        toggleable: true, sortable: true, filterable: true, filterType: 'multiSelect', showByDefault: true
    },
    {
        field: 'audit.dateStatut', fieldObj: 'audit', fieldProp: 'dateStatut', header: 'Audit date statut', group: 'audit',
        toggleable: true, sortable: true, filterable: true, isDate: true, showByDefault: true
    },
    {
        field: 'audit.assigneA', fieldObj: 'audit', fieldProp: 'assigneA', header: 'Audit assigné à', group: 'audit',
        toggleable: true, sortable: true, filterable: true, showByDefault: true
    },
    {
        field: 'audit.assigneLe', fieldObj: 'audit', fieldProp: 'assigneLe', header: 'Audit assigné le', group: 'audit',
        toggleable: true, sortable: true, filterable: true, isDate: true, showByDefault: true
    },
    {
        field: 'audit.assignePar', fieldObj: 'audit', fieldProp: 'assignePar', header: 'Audit assigné par', group: 'audit',
        toggleable: true, sortable: true, filterable: true, showByDefault: true
    },
    {
        field: 'audit.nombreTotalDePoteaux', fieldObj: 'audit', fieldProp: 'nombreTotalDePoteaux', header: `Nb de points d'audit`, group: 'audit',
        toggleable: true, sortable: true, filterable: true, inputFilterDataType: 'number', showByDefault: true
    },
    {
        field: 'audit.statutGlobal', fieldObj: 'audit', fieldProp: 'statutGlobal', header: `Résultat d'audit`, group: 'audit', toggleable: true,
        sortable: true, filterable: true, filterType: 'multiSelect', showByDefault: true
    },
    {
        field: 'firmeAuditName', fieldObj: 'audit', fieldProp: 'firme', header: `Audit firme`, group: 'audit',
        toggleable: true, sortable: true, filterable: true, filterType: 'multiSelect'
    },
    {
        field: 'audit.remarques', fieldObj: 'audit', fieldProp: 'remarques', header: `Audit remarque`, group: 'audit',
        toggleable: true, sortable: true, filterable: true
    },
    {
        field: 'audit.seuilAudit', fieldObj: 'audit', fieldProp: 'seuilAudit', header: `Audit seuil`, group: 'audit',
        toggleable: true, sortable: true, filterable: true, inputFilterDataType: 'number'
    },
    {
        field: 'audit.typeDeSeuil', fieldObj: 'audit', fieldProp: 'typeDeSeuil', header: `Audit type de seuil`, group: 'audit',
        toggleable: true, sortable: true, filterable: true, filterType: 'dropdown'
    }
];

