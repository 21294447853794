export enum MapLayersSources {
    POTEAU_HIGHLIGHTED = 'poteau-highlighted',
    POTEAU_CREATION_EXCLUS = 'poteau-creation-exclus',
    POTEAU_SELECTED = 'poteau-selected',
    POLYGONE_PROJET_HIGHLIGHTED = 'polygone-projet-highlighted',
    POLYGONE_PROJET_HIGHLIGHTED_OUTLINE = 'polygone-projet-outline',
    POLYGONE_PROJET_ZOOM_HIGHLIGHTED = 'polygone-projet-zoom-highlighted',
    POLYGONE_PROJET_ZOOM_HIGHLIGHTED_OUTLINE = 'polygone-projet-zoom-highlighted-outline',
    POLYGONE_PROJET = 'polygone-projet',
    RECHERCHE_LIGNE = 'recherche-ligne',
    RECHERCHE_LCLCL = 'recherche-lclcl',
    RECHERCHE_CODE_BARRES = 'recherche-codebarres',
    POINT_INSPECTION = 'point-inspection',
    POINT_INSPECTION_PRIVE = 'point-inspection-prive',
    POINT_AUDIT = 'point-audit',
    POINT_AUDIT_SELECTED = 'point-audit-selected',
    POINT_INSPECTION_CURSOR = 'point-inspection-cursor',
    LIGNE_PROJET = 'ligne-projet',
    BUFFER_LIGNE_PROJET = 'buffer-ligne-projet',
    ANOMALIE = 'anomalie',
    CODEABARRES = 'codeABarres'
}
