<div class="modification-dialog">
    <form [formGroup]="form">
        <div class="modification">Numéro d'ordre de travail:
            <input type="text" pInputText formControlName="numeroOrdreDeTravail" class="modification-field"
                [minlength]="8" [maxlength]="8" onKeyPress="if(this.value.length==8) return false; else return true;" />
        </div>
    </form>
</div>
<ng-container *ngIf="form.controls?.numeroOrdreDeTravail?.errors?.minlength">
    <br><span class="text-erreur">Le numéro d'ordre de travail doit avoir exactement 8 chiffres</span>
</ng-container>
<div class="custom-dialog-footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="close()"></button>
    <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text"
        [disabled]="form.invalid || form.pristine" (click)="onSave()"></button>
</div>

<p-confirmDialog class="custom-confirm-footer" defaultFocus="none" key="modifyInspectionConfirm"></p-confirmDialog>