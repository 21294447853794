<div class="p-d-flex p-flex-column p-jc-center" [formGroup]="form">
    <div class="p-mb-2 p-ai-end">
        <app-form-field class="app-form-field app-form-field--column" [control]="form.controls.justificatif"
            label="Justificatif">
            <input pInputText type="text" [required]="true" formControlName="justificatif">
        </app-form-field>
    </div>
</div>
<div class="custom-dialog-footer">
    <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text" (click)="close()"></button>
    <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-text"
        [disabled]="form.invalid || form.pristine" (click)="onSave()"></button>
</div>