import { TableColumn } from '../../../shared/models/table-column.model';

export const actionColumn: TableColumn[] = [
    {
        field: 'actions',
        header: 'Actions',
        sortable: false,
        filterable: false,
        width: '95px',
        showByDefault: true,
        toggleable: false
    }
];

