import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { ConfirmationService } from 'primeng/api';
import { FormGroup } from '@angular/forms';

export abstract class ProjetsActionBaseDialog extends BaseComponent {
    public abstract form: FormGroup;

    constructor(protected ref: DynamicDialogRef, protected confirmationService: ConfirmationService) {
        super();
    }

    /**
     * Affiche un fenêtre de confirmation
     */
    private annulerModifications() {
        this.confirmationService.confirm({
            header: `Annuler les modifications`,
            message: 'Voulez-vous vraiment quitter sans enregistrer les modifications ?',
            icon: 'pi pi-exclamation-triangle',
            rejectLabel: 'Non',
            acceptLabel: 'Oui',
            accept: () => {
                this.closeDialog();
            },
            key: 'global',
        });
    }

    /**
     * Ferme directement le dialog sans confirmation
     */
    protected closeDialog() {
        this.ref.close();
    }

    /**
     * Vérifie si le formulaire à été modifier et si oui, demande une confirmation avant de fermer le dialog
     */
    public close() {
        if (this.form.dirty) {
            this.annulerModifications();
        } else {
            this.closeDialog();
        }
    }
}
