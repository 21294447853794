import { LayerGroupe } from '../../enums/layer-groupe';
import { ServiceFeature } from '../../enums/serviceFeature';
import { LayerType } from '../../map/models/layer-type.enum';
import { StyleLayer } from '../../map/models/style-layer.model';

export const PARC_POTEAUX_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle('AREQ', 'rgba(245, 189, 252, 0.5)'),
    generateStyle('Bell', 'rgba(190, 232, 255,0.5)'),
    generateStyle('Bell Aliant', 'rgba(115, 178, 255,0.5)'),
    generateStyle('Privé', 'rgba(255, 235, 175,0.5)'),
    generateStyle('Telus', 'rgba(205, 245, 122,0.5)'),
    generateStyle('Télébec', 'rgba(255, 211, 127,0.5)')
];

function generateStyle(identifier: string, color: string): StyleLayer {
    return {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': identifier,
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], identifier],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': color,
                'fill-outline-color': '#000000'
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        },
    };
}
